/**
 * Camp flow Steps Card Component for camp overview
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import StepWithCountsComponent from "./stepWithNumber";
import { useDispatch, useSelector } from "react-redux";
import { SelectActiveCampStep, SelectActiveCurrentCamp } from "../selector";
import {
  getCounterAndBeneficiaryOfStepOfCurrentCamp,
  setActiveCampCounter,
  setActiveCampStep,
  setActiveCurrentCamp,
  setLoaderBeneficiaryCampStep,
} from "../slice";

const CampFlowStepComponent = (props) => {
  const dispatch = useDispatch();
  const { isViewCounter, setIsViewCounter, stepData, stepIndex, activeCampId } =
    props;

  const activeCampStep = useSelector(SelectActiveCampStep);
  const activeCurrentCamp = useSelector(SelectActiveCurrentCamp);

  const allGetCounterOfStepsAndStepBeneficiariesData = (
    scheduledCampId,
    campStepId,
    search
  ) => {
    dispatch(setLoaderBeneficiaryCampStep(true));
    dispatch(
      getCounterAndBeneficiaryOfStepOfCurrentCamp({
        scheduledCampId,
        campStepId,
        search,
        onSuccess: (props) => {
          dispatch(setLoaderBeneficiaryCampStep(false));
        },
        onFailure: (props) => {
          dispatch(setLoaderBeneficiaryCampStep(false));
        },
      })
    );
  };

  const handleStepClick = (stepData, stepIndex) => {
    setIsViewCounter(!isViewCounter);
    dispatch(
      setActiveCurrentCamp({
        ...activeCurrentCamp,
        isActiveCurrentCamp: false,
        searchBeneficiaryCamp: "",
      })
    );

    dispatch(
      setActiveCampCounter({
        campCounterIndex: null,
        campCounterName: "",
        campCounterId: "",
        isActiveCampCounter: false,
        searchBeneficiaryCounter: "",
      })
    );

    dispatch(
      setActiveCampStep({
        campStepIndex: stepIndex,
        campStepName: ` > ${stepData?.stepCount?.stepDetails?.camp_step_display_name}`,
        campStepId: stepData?.stepCount?.stepDetails?._id,
        isActiveCampStep: true,
        searchBeneficiaryStep: "",
      })
    );
    if (activeCurrentCamp?.campId) {
      allGetCounterOfStepsAndStepBeneficiariesData(
        activeCurrentCamp?.campId,
        stepData?.stepCount?.stepDetails?._id,
        ""
      );
    }
  };

  return (
    <>
      <div
        // key={stepData?._id}
        className={`p-2 space-y-3 ${
          activeCampStep?.campStepId ===
            stepData?.stepCount?.stepDetails?._id &&
          activeCurrentCamp?.campId === activeCampId
            ? "bg-[#DDF1FE]"
            : "bg-[white]"
        }`}
        onClick={() => handleStepClick(stepData, stepIndex)}
      >
        <div className="flex justify-between gap-1 items-center">
          <div className={`text-base font-bold `}>
            {stepData?.stepCount?.stepDetails?.camp_step_display_name}
          </div>
          <div className="flex justify-between gap-1 items-center">
            <div
              className={`h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center min-w-[24px] w-[24px] min-h-[24px] ${
                isViewCounter ? "rotate-180" : "rotate-90"
              }`}
              // onClick={() => {
              //   setIsViewCounter(!isViewCounter);
              // }}
            >
              <ArrowIcon
                width={6}
                height={10}
                isRotated={isViewCounter}
                fillColor={"#ffffff"}
              />
            </div>
          </div>
        </div>
        {/* progress */}
        <div className="flex flex-wrap justify-between  items-center rounded-lg w-full">
          <StepWithCountsComponent
            data={{
              name: "Completed",
              CountNumber: stepData?.stepCount?.completed || 0,
            }}
            colors={{ text: "#256CD0", background: "#DDF1FE" }}
          />
          <StepWithCountsComponent
            data={{
              name: "Waiting",
              CountNumber: stepData?.stepCount?.waiting || 0,
            }}
            colors={{ text: "#36D964", background: "#E0F9E7" }}
          />
        </div>
      </div>
    </>
  );
};

export default CampFlowStepComponent;
