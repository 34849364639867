import React from "react";

export const InfoIconFilled = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2080_1490)">
        <path
          d="M8.0944 14.9251C11.7763 14.9251 14.7611 11.9404 14.7611 8.25846C14.7611 4.57657 11.7763 1.5918 8.0944 1.5918C4.4125 1.5918 1.42773 4.57657 1.42773 8.25846C1.42773 11.9404 4.4125 14.9251 8.0944 14.9251Z"
          stroke="#256CD0"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.09375 5.5918V8.25846"
          stroke="#256CD0"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.09375 10.9238H8.10042"
          stroke="#256CD0"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2080_1490">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.09375 0.257812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
