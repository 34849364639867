import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCampUserCounterData } from "../pages/common";
import { updateToggleForAvailableUnavailable } from "../pages/camp_management/slice";

const RouteChangeHandler = () => {
  const userCountersData = useSelector(selectCampUserCounterData) || {};
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (Boolean(userCountersData?.counter_id)) {
      if (
        Boolean(userCountersData?.is_active) &&
        Boolean(userCountersData?.counter_id) &&
        Boolean(!location.pathname.startsWith("/myCamp/"))
      ) {
        dispatch(
          updateToggleForAvailableUnavailable({
            id: userCountersData?.counter_id,
            status: false,
            onSuccess: (props) => {},
          })
        );
      }
    }
  }, [userCountersData?.is_active, location.pathname]);

  return null;
};

export default RouteChangeHandler;
