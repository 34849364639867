/**
 * Downloard pdf Component
 * @flow
 * @format
 */

import React from "react";
import html2pdf from "html2pdf.js";
import CustomButton from "./customButtion";

const AssessmentPdf = (props) => {
  const { htmlContent, cancel } = props;

  // const downloadPDF = () => {
  //   const element = document.getElementById("hidden-html-container");

  //   const options = {
  //     margin: [0, 5, 5, 5],
  //     filename: "AssessmentForm.pdf",
  //     html2canvas: {
  //       scale: 2,
  //       scrollX: 0,
  //       scrollY: 0,
  //     },
  //     jsPDF: {
  //       unit: "mm",
  //       format: "a4",
  //       orientation: "portrait",
  //     },
  //   };

  //   html2pdf().set(options).from(element).save();
  // };

  const openPrintScreen = () => {
    const element = document.getElementById("hidden-html-container");
    const printWindow = window.open("", "_blank", "width=800,height=600");

    if (printWindow) {
      printWindow.document.open();
      printWindow.document.write(`${element.innerHTML}`);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();

      // Optionally close the print window after printing
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    } else {
      console.error("Failed to open print window. Please allow popups.");
    }
  };
  return (
    <>
      <div className="fixed h-full inset-0 z-50 flex justify-end">
        <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
        <div
          data-aos-easing="linear"
          data-aos="fade-left"
          className="flex flex-col relative w-fit h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
        >
          {/* Modal content */}
          <div className="flex flex-auto flex-col h-fit justify-between item-center px-4 py-4 w-full ">
            {/* buttons */}
            <div className="w-full flex justify-end space-x-4 h-[80px] pr-4 pb-4">
              <div className="flex flex-row items-center space-x-4">
                <CustomButton
                  name={"Print"}
                  type="button"
                  loading={false}
                  disabled={false}
                  onClick={() => {
                    openPrintScreen();
                  }}
                  className={
                    "rounded-[10px] py-0 px-8 text-lg h-[40px] bg-[#4AB8FF]"
                  }
                />
                <CustomButton
                  name={"Cancel"}
                  type="button"
                  loading={false}
                  disabled={false}
                  onClick={() => {
                    cancel(false);
                  }}
                  className={
                    "rounded-[10px] py-0 px-8 text-[16px] h-[40px] bg-[#C1C1C1]"
                  }
                />
              </div>
            </div>
            <div
              id="hidden-html-container"
              dangerouslySetInnerHTML={{
                __html: htmlContent,
              }}
            ></div>

            {/* buttons */}
            <div className="w-full flex justify-end space-x-4 h-[48px] pr-4">
              <div className="flex flex-row items-center space-x-4">
                <CustomButton
                  name={"Print"}
                  type="button"
                  loading={false}
                  disabled={false}
                  onClick={() => {
                    openPrintScreen();
                  }}
                  className={
                    "rounded-[10px] py-0 px-8 text-lg h-[40px] bg-[#4AB8FF]"
                  }
                />
                <CustomButton
                  name={"Cancel"}
                  type="button"
                  loading={false}
                  disabled={false}
                  onClick={() => {
                    cancel(false);
                  }}
                  className={
                    "rounded-[10px] py-0 px-8 text-[16px] h-[40px] bg-[#C1C1C1]"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentPdf;
