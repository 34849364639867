/**
 * All Camp Center list Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AddCampCenterModel } from "./addCampCenterScreen";
import { Delete } from "../../../assets/icons/Delete";
import { Edit } from "../../../assets/icons/Edit";
import { Switch } from "@material-tailwind/react";
import { HeaderScreen, Sidebar } from "../../../components";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { AddCircle } from "../../../assets/icons/AddCircle";
import {
  allCampCenterList,
  deleteCampCenter,
  getAllStateWithCampCenterCount,
  setCampCenterSearchQuery,
  setCampCenterCurrentPage,
  setCampCenterRespPerPage,
  setCampCenterStateId,
  updateCampCenterStatusById,
} from "../slice";
import { useSelector } from "react-redux";
import {
  selectAllCampCenterList,
  selectAllStateWithCampCenterCount,
  selectCampCenterSearchQuery,
  selectCampCenterStateId,
  selectCampCenterCurrentPage,
  selectCampCenterRespPerPage,
} from "../selector";
import { toast } from "react-toastify";
import { EditCampCenterModel } from "./editCampCenterScreen";
import { selectUserAllPermissions } from "../../common";
import DeleteConfirm from "../../../components/deleteConfirm";
import { useLocation } from "react-router-dom";
import DateTableComponent from "../../../components/dataTableComponent";
import ViewMapLink from "../../../components/viewMapLink";

const ListCampCenterModel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { confirm, ModalContent } = DeleteConfirm();
  const userPermissions = useSelector(selectUserAllPermissions) || {};

  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [isAddCampCenterModalOpen, setIsAddCampCenterModalOpen] =
    useState(false);
  const [isEditCampCenterModalOpen, setIsEditCampCenterModalOpen] =
    useState(false);
  const [rowData, setRowData] = useState("");
  const [activeState, setActiveState] = useState("");
  // Selector Hooks
  const { data, totalCamps } = useSelector(selectAllCampCenterList);
  const stateId = useSelector(selectCampCenterStateId) || "";
  const campCenterSearchQuery = useSelector(selectCampCenterSearchQuery) || "";
  const { data: stateWithCount = [] } =
    useSelector(selectAllStateWithCampCenterCount) || [];

  const currentPage = useSelector(selectCampCenterCurrentPage);
  const perPage = useSelector(selectCampCenterRespPerPage);

  const toggleEditCampCenterModal = (row) => {
    setIsEditCampCenterModalOpen(!isEditCampCenterModalOpen);
    setRowData(row);
  };

  const toggleAddCampCenterModal = () => {
    setIsAddCampCenterModalOpen(!isAddCampCenterModalOpen);
  };

  const SortIcon = ({ isSorted, isSortedDesc }) => {
    if (!isSorted) {
      return <span className="ml-4 sort-icon">&uarr;&darr;</span>; // Default unsorted icon
    } else if (isSortedDesc) {
      return <span className="ml-4 sort-icon">&darr;</span>; // Descending sort icon
    } else {
      return <span className="ml-4 sort-icon">&uarr;</span>; // Ascending sort icon
    }
  };

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
    // border: "2px solid black",
  };
  const columnStyleStatus = {
    justifyContent: "end",
  };

  const columns = [
    {
      name: "Camp Centre Name",
      selector: (row) => row?.camp_location_name,
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Code",
      selector: (row) => row?.camp_location_code,
      sortable: true,
      style: columnStyle,
    },
    {
      name: "Address",
      selector: (row) => row?.address_1,
      sortable: true,
      style: columnStyle,
      cell: (row) => (
        <>
          <div>
            {`${row?.address_1}, ${row?.address_2} Dist- ${row?.district?.district_name}, ${row?.state?.state_name} (${row?.pin_code})`}
          </div>
        </>
      ),
    },
    {
      name: "Contact",
      selector: (row) => row?.contact_person_phone,
      sortable: true,
      style: columnStyle,
    },
    {
      name: "View Map",
      selector: (row) => row?.geo_coordinates,
      sortable: true,
      style: columnStyle,
      cell: (row) => (
        <>
          <div>
            <ViewMapLink geo_coordinates={row?.geo_coordinates} />
          </div>
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      sortable: true,
      style: columnStyle,
      cell: (row) => (
        <div className="flex justify-between items-center gap-4">
          <span className="flex justify-end items-center flex-auto text-gray-tone-900">
            {row.is_active === true ? "Active" : "Inactive"}
          </span>
          <div className="flex cursor-pointer justify-center items-center">
            <Switch
              id={row.id}
              ripple={false}
              checked={row.is_active === true}
              onChange={() => {
                const updatedValues = {
                  id: row?._id,
                };
                dispatch(
                  updateCampCenterStatusById({
                    values: updatedValues,
                    onSuccess: (props) => {
                      toast.success(props?.message);
                      getData(
                        campCenterSearchQuery,
                        stateId,
                        currentPage,
                        perPage
                      );
                    },
                  })
                );
              }}
              className="h-full w-full checked:bg-[#B0E0FF]"
              containerProps={{
                className: "w-8 h-4",
              }}
              circleProps={{
                className:
                  "before:hidden left-1 border-none w-[12px] h-[12px] drop-shadow-toggle",
              }}
            />
          </div>
          <div className="flex space-x-2">
            {userPermissions?.Edit_Camp_Centres === true && (
              <div
                className="cursor-pointer flex justify-center"
                onClick={() => toggleEditCampCenterModal(row)}
              >
                <Edit width={12} height={12} />
              </div>
            )}
            {/* {userPermissions?.Delete_Camp_Centres === true && ( */}
            <div
              className="cursor-pointer"
              onClick={async () => {
                if (userPermissions?.Delete_User === true) {
                  const isConfirmed = await confirm({
                    title: "Delete",
                    message: `Are you sure you want to delete '${row?.camp_location_code}' Camp Center?`,
                    confirmText: "Yes, I'm sure",
                    cancelText: "No, cancel",
                    icon: <Delete height={20} width={20} />,
                  });
                  if (isConfirmed) {
                    dispatch(
                      deleteCampCenter({
                        id: row?._id,
                        onSuccess: (props) => {
                          toast.success(props?.message);
                          getData(
                            campCenterSearchQuery,
                            stateId,
                            currentPage,
                            perPage
                          );
                          getStateWithCenterCount();
                        },
                      })
                    );
                  }
                }
              }}
            >
              <Delete width={12} height={12} />
            </div>
            {/* )} */}
          </div>
        </div>
      ),
    },
  ];

  // functions
  const getData = (searchData, stateId, page, perPage) => {
    setDataTableLoading(true);
    dispatch(
      allCampCenterList({
        search: searchData,
        stateId,
        page: page,
        perPage: perPage,
        onSuccess: (props) => {
          setDataTableLoading(false);
        },
        onFailure: (props) => {
          setDataTableLoading(false);
        },
      })
    );
  };

  const getStateWithCenterCount = () => {
    dispatch(
      getAllStateWithCampCenterCount({
        onSuccess: (props) => {},
      })
    );
  };

  useEffect(() => {
    getData(campCenterSearchQuery, stateId, currentPage, perPage);
    getStateWithCenterCount();
  }, [campCenterSearchQuery, currentPage, perPage, stateId]);

  useEffect(() => {
    if (location.pathname === "/scheduleCampsList") {
      return () => {
        dispatch(setCampCenterSearchQuery(""));
        dispatch(setCampCenterRespPerPage(10));
        dispatch(setCampCenterCurrentPage(1));
        dispatch(setCampCenterStateId(""));
      };
    } else {
      return;
    }
  }, [location]);

  return (
    <>
      <div className="h-screen min-w-screen relative">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-80px)]">
          <Sidebar />
          {/* max-w-[calc(100%-250px)] */}
          <div className="flex flex-col h-full bg-customBlue-mainBackground  w-[100%] overflow-auto">
            <div className="container min-h-[80px] min-w-full px-6 flex items-center justify-between">
              <div className="font-bold font-raleway text-2xl">
                Camps Centers
              </div>
              <div className="container flex flex-row justify-between items-center space-x-4 h-[55%] max-w-[45%] md:max-w-[70%] ">
                {/* Search and Filter */}
                <div className="container p-0 flex justify-between items-center h-[100%]  ">
                  {/* Search Bar */}
                  <div className="relative flex-1 mr-4 rounded-lg border border-[#E8E8E8] h-full">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none h-full">
                      <SearchIcon width={20} height={20} />
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full h-[100%] p-4 ps-10 text-sm text-gray-tone-100 rounded-lg "
                      placeholder="Search"
                      onChange={(event) => {
                        dispatch(setCampCenterSearchQuery(event.target.value));
                        // setSearchQuery(event.target.value);
                      }}
                    />
                  </div>
                </div>
                {userPermissions?.Create_Camp_Centres === true && (
                  <div className="h-[100%]">
                    <div
                      className="container cursor-pointer flex flex-row px-2 justify-around rounded-lg items-center gap-2 h-[100%] w-[100%] bg-[#3699FF] text-white text-sm font-semibold"
                      onClick={toggleAddCampCenterModal}
                    >
                      <span className="flex-shrink-0">
                        <AddCircle width={18} height={18} />
                      </span>
                      <span className="text-sm font-semibold truncate">
                        Create Camp Center
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row justify-between space-x-2 px-4">
              <div>
                <div className="flex flex-row text-sm text-[#666666] font-bold min-h-[52px] min-w-[150px] bg-[#DDF1FE] items-center p-4">
                  State
                </div>
                <div className="text-sm font-medium min-h-[52px] min-w-[220px] max-h-[calc(100vh-215px)] overflow-auto">
                  {stateWithCount?.map((item, index) => (
                    <div
                      key={index}
                      className={`flex flex-row text-sm min-h-[52px] min-w-[150px] border-b-2 items-center p-4 cursor-pointer ${
                        activeState === item?.state_id
                          ? "font-bold bg-gray-50"
                          : "font-medium bg-[#ffffff]"
                      }`}
                      onClick={() => {
                        setActiveState(item?.state_id);
                        dispatch(setCampCenterStateId(item?.state_id));
                      }}
                    >
                      {item?.state_name}
                      <div className="w-full text-right">{item?.count}</div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Data Table */}
              <div className="pr-2 overflow-y-auto scrollbar-hide w-full">
                <DateTableComponent
                  apiColumn={columns}
                  data={data}
                  totalRow={totalCamps}
                  loading={dataTableLoading}
                  sortIcon={<SortIcon />}
                  backgroundColor={"#DDF1FE"}
                  selectableRows={false}
                  pagination={true}
                  currentPages={currentPage}
                  limit={perPage}
                  setLimit={(updateLimit) => {
                    dispatch(setCampCenterRespPerPage(updateLimit));
                  }}
                  setCurrentPage={(updateCurrenPage) => {
                    dispatch(setCampCenterCurrentPage(updateCurrenPage));
                  }}
                  rowsPerPageOptions={[2, 10, 30, 50, 100, 200]}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        {isAddCampCenterModalOpen && (
          <AddCampCenterModel
            toggleModal={toggleAddCampCenterModal}
            getData={getData}
            getStateWithCenterCount={getStateWithCenterCount}
          />
        )}

        {isEditCampCenterModalOpen && (
          <EditCampCenterModel
            toggleModal={toggleEditCampCenterModal}
            getData={getData}
            campCenterId={rowData?._id}
          />
        )}
        {ModalContent}
      </div>
    </>
  );
};

export { ListCampCenterModel };
