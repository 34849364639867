/**
 * Camp OverView Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeaderScreen, Loader, Sidebar } from "../../../components";
import CurrentCampCardComponent from "../components/currentCampCard";
import { SearchIcon } from "../../../assets/icons/SearchIcon";
import { RefreshIcon } from "../../../assets/icons/refresh";
import HeadingComponent from "../components/headingComponent";
import BeneficiaryShortDetailCardComponent from "../components/beneficiaryShortDetailCard";
import { NoRecord } from "../../../components/noRecords";
import {
  allBeneficiaryDetailOfCampByCampId,
  allCurrentCamps,
  countStepDataOfCurrentCamp,
  endStartCamp,
  getBeneficiaryOfCounterOfStepOfCurrentCamp,
  getCounterAndBeneficiaryOfStepOfCurrentCamp,
  resetAllStateOfCampOverView,
  setActiveCampCounter,
  setActiveCampStep,
  setActiveCurrentCamp,
  setAllCurrentCampsList,
  setLoaderBeneficiaryCamp,
  setLoaderBeneficiaryCampCounter,
  setLoaderBeneficiaryCampStep,
  setViewSingleBeneficiaryDetail,
} from "../slice";
import {
  SelectActiveCampCounter,
  SelectActiveCampStep,
  SelectActiveCurrentCamp,
  SelectAllBeneficiaryListOfCamp,
  SelectAllBeneficiaryListOfCounterOfStep,
  SelectAllBeneficiaryListOfStepOfCamp,
  SelectAllCountStepDataList,
  SelectAllCurrentCampsList,
  SelectCampOverViewNavStatus,
  SelectLoaderBeneficiaryCamp,
  SelectLoaderBeneficiaryCampCounter,
  SelectLoaderBeneficiaryCampStep,
  SelectViewSingleBeneficiaryDetail,
} from "../selector";
import { BeneficiaryDetailAndJourneyDataModels } from "../../../components/beneficiayDetailAndJourneyData";
import CampFlowStepComponent from "../components/campFlowStepCard";
import CounterCardComponent from "../components/counterCard";
import CustomButton from "../../../components/customButtion";
import { selectFullScreenLoading, setFullScreenLoading } from "../../common";
import NavigationBar from "../../beneficiary_management/components/navigationBar";
import { useLocation } from "react-router-dom";
import DeleteConfirm from "../../../components/deleteConfirm";

const CampOverviewScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { confirm, ModalContent } = DeleteConfirm();
  const [recordData, setRecordData] = useState(true);
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [campFlows, setCampFlows] = useState({});

  const viewBeneficiaryModal = useSelector(SelectViewSingleBeneficiaryDetail);
  const activeCurrentCamp = useSelector(SelectActiveCurrentCamp);
  const activeCampStep = useSelector(SelectActiveCampStep);
  const activeCampCounter = useSelector(SelectActiveCampCounter);
  const navStatus = useSelector(SelectCampOverViewNavStatus);
  const {
    campData: campOverviewList = [],
    onGoingCampCount,
    pastCampCount,
  } = useSelector(SelectAllCurrentCampsList);
  const campBeneficiarDetails = useSelector(SelectAllBeneficiaryListOfCamp);
  const beneficiarDetailsOfStep = useSelector(
    SelectAllBeneficiaryListOfStepOfCamp
  );
  const campSteps = useSelector(SelectAllCountStepDataList);
  const beneficiarDetailsOfCounter = useSelector(
    SelectAllBeneficiaryListOfCounterOfStep
  );
  const campLoader = useSelector(SelectLoaderBeneficiaryCamp);
  const campStepLoader = useSelector(SelectLoaderBeneficiaryCampStep);
  const campCounterLoader = useSelector(SelectLoaderBeneficiaryCampCounter);
  const fullScreenLoading = useSelector(selectFullScreenLoading);

  const toggleBeneficaryDetailModal = () => {
    dispatch(setViewSingleBeneficiaryDetail(!viewBeneficiaryModal));
  };

  const getAllData = (campStausFilter) => {
    dispatch(setAllCurrentCampsList({}));
    dispatch(setFullScreenLoading(true));
    dispatch(
      allCurrentCamps({
        search: "",
        filter: campStausFilter,
        // page: 1,
        // responsePerPage: 10,
        onSuccess: (props) => {
          dispatch(setFullScreenLoading(false));
        },
        onFailure: () => {
          dispatch(setFullScreenLoading(false));
        },
      })
    );
  };

  const allBeneficiaryDetailOfCamp = (id, search) => {
    dispatch(setLoaderBeneficiaryCamp(true));
    dispatch(
      allBeneficiaryDetailOfCampByCampId({
        id,
        search,
        onSuccess: (props) => {
          dispatch(setLoaderBeneficiaryCamp(false));
        },
        onFailure: () => {
          dispatch(setLoaderBeneficiaryCamp(false));
        },
      })
    );
  };

  // State to keep track of the open step ID
  const [openStepId, setOpenStepId] = useState(null);

  const allStepsData = (id) => {
    dispatch(
      countStepDataOfCurrentCamp({
        id,
        onSuccess: () => {},
      })
    );
  };

  const getBeneficiayOfCounter = (id, search) => {
    dispatch(setLoaderBeneficiaryCampCounter(true));
    dispatch(
      getBeneficiaryOfCounterOfStepOfCurrentCamp({
        counterId: id,
        search,
        onSuccess: (props) => {
          dispatch(setLoaderBeneficiaryCampCounter(false));
        },
      })
    );
  };

  const allGetCounterOfStepsAndStepBeneficiariesData = (
    scheduledCampId,
    campStepId,
    search
  ) => {
    dispatch(setLoaderBeneficiaryCampStep(true));
    dispatch(
      getCounterAndBeneficiaryOfStepOfCurrentCamp({
        scheduledCampId,
        campStepId,
        search,
        onSuccess: (props) => {
          dispatch(setLoaderBeneficiaryCampStep(false));
        },
      })
    );
  };

  // Handle toggle of step
  const handleToggleStep = (stepId) => {
    setOpenStepId((prevId) => (prevId === stepId ? null : stepId)); // Toggle open/close by ID
  };

  const handleCampClick = (campData, campIndex) => {
    dispatch(
      setActiveCampCounter({
        campCounterIndex: null,
        campCounterName: "",
        campCounterId: "",
        isActiveCampCounter: false,
        searchBeneficiaryCounter: "",
      })
    );
    dispatch(
      setActiveCampStep({
        campStepIndex: null,
        campStepName: "",
        campStepId: "",
        isActiveCampStep: false,
        searchBeneficiaryStep: "",
      })
    );
    dispatch(
      setActiveCurrentCamp({
        campIndex: campIndex,
        campName: `${campData?.camp_type_id?.camp_type_display_name} Camp Code ${campData?.camp_code}`,
        campId: campData?._id,
        navStatus: " > Beneficiaries Overview",
        isActiveCurrentCamp: true,
        searchBeneficiaryCamp: "",
      })
    );

    if (campData?._id) {
      allBeneficiaryDetailOfCamp(campData?._id, "");
      allStepsData(campData?._id);
    }
  };

  // Helper function to render content based on active status
  const renderContent = () => {
    if (activeCurrentCamp.isActiveCurrentCamp) {
      return (
        <>
          {campLoader ? (
            <>
              <div className="h-[80%] w-full flex justify-center items-center bg-customBlue-mainBackground">
                <Loader />
              </div>
            </>
          ) : (
            <>
              {campBeneficiarDetails ? (
                <>
                  {" "}
                  <div className="flex gap-3 w-full overflow-y-auto scrollbar-hide h-[calc(100%-80px)]">
                    <div className="space-y-3 w-full">
                      {/* heading */}
                      <HeadingComponent
                        name={"Not yet checked-in"}
                        total={
                          campBeneficiarDetails?.counts?.notCheckedIn?.count ||
                          0
                        }
                        colors={{ text: "#FF5976", background: "#FFEBEE" }}
                      />
                      {/* options */}
                      {campBeneficiarDetails?.counts?.notCheckedIn?.beneficiaries?.map(
                        (beneficiary, beneficiaryIndex) => {
                          return (
                            <div
                              key={beneficiaryIndex}
                              onClick={() => {
                                setBeneficiaryId(beneficiary?.beneficiary_id);
                                toggleBeneficaryDetailModal();
                              }}
                            >
                              <BeneficiaryShortDetailCardComponent
                                beneficiaryData={beneficiary}
                                campDetails={campBeneficiarDetails?.camp}
                                viewCampName={false}
                                counterNumber={false}
                                time={false}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="space-y-3 w-full">
                      {/* heading */}
                      <HeadingComponent
                        name={"In Progress"}
                        total={
                          campBeneficiarDetails?.counts?.inProgress?.count || 0
                        }
                        colors={{ text: "#FAAE00", background: "#FEF5E0" }}
                      />
                      {/* options */}
                      {campBeneficiarDetails?.counts?.inProgress?.beneficiaries?.map(
                        (beneficiary, beneficiaryIndex) => {
                          return (
                            <div
                              key={beneficiaryIndex}
                              onClick={() => {
                                setBeneficiaryId(beneficiary?.beneficiary_id);
                                toggleBeneficaryDetailModal();
                              }}
                            >
                              <BeneficiaryShortDetailCardComponent
                                beneficiaryData={beneficiary}
                                campDetails={campBeneficiarDetails?.camp}
                                viewCampName={true}
                                counterNumber={true}
                                time={true}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="space-y-3 w-full">
                      {/* heading */}
                      <HeadingComponent
                        name={"Checked-out"}
                        total={
                          campBeneficiarDetails?.counts?.checkedOut?.count || 0
                        }
                        colors={{ text: "#04CF3D", background: "#E0F9E7" }}
                      />
                      {/* options */}
                      {campBeneficiarDetails?.counts?.checkedOut?.beneficiaries?.map(
                        (beneficiary, beneficiaryIndex) => {
                          return (
                            <div
                              key={beneficiaryIndex}
                              onClick={() => {
                                setBeneficiaryId(beneficiary?.beneficiary_id);
                                toggleBeneficaryDetailModal();
                              }}
                            >
                              <BeneficiaryShortDetailCardComponent
                                beneficiaryData={beneficiary}
                                campDetails={campBeneficiarDetails?.camp}
                                viewCampName={false}
                                counterNumber={false}
                                time={true}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="h-full py-16">
                    <NoRecord
                      title_1={"There are no records to display"}
                      title_2={""}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    } else if (activeCampStep.isActiveCampStep) {
      return (
        <>
          {campStepLoader ? (
            <>
              <div className="h-[80%] w-full flex justify-center items-center bg-customBlue-mainBackground">
                <Loader />
              </div>
            </>
          ) : (
            <>
              {beneficiarDetailsOfStep ? (
                <div className="flex gap-3 w-full overflow-y-auto scrollbar-hide h-[calc(100%-80px)]">
                  <div className="space-y-3 w-full">
                    {/* heading */}
                    <HeadingComponent
                      name={"Completed"}
                      total={beneficiarDetailsOfStep?.completed}
                      colors={{ text: "#0078CE", background: "#DDF1FE" }}
                    />
                    {/* options */}
                    {beneficiarDetailsOfStep?.completedBeneficiary?.map(
                      (beneficiary, beneficiaryIndex) => {
                        return (
                          <div
                            key={beneficiaryIndex}
                            onClick={() => {
                              setBeneficiaryId(beneficiary?.beneficiary_id);
                              toggleBeneficaryDetailModal();
                            }}
                          >
                            <BeneficiaryShortDetailCardComponent
                              beneficiaryData={beneficiary}
                              campDetails={campBeneficiarDetails?.camp}
                              viewCampName={false}
                              counterNumber={true}
                              time={true}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="space-y-3 w-full">
                    {/* heading */}
                    <HeadingComponent
                      name={"Waiting"}
                      total={beneficiarDetailsOfStep?.waiting}
                      colors={{ text: "#04CF3D", background: "#E0F9E7" }}
                    />
                    {/* options */}
                    {beneficiarDetailsOfStep?.waitingBeneficiary?.map(
                      (beneficiary, beneficiaryIndex) => {
                        return (
                          <div
                            key={beneficiaryIndex}
                            onClick={() => {
                              setBeneficiaryId(beneficiary?.beneficiary_id);
                              toggleBeneficaryDetailModal();
                            }}
                          >
                            <BeneficiaryShortDetailCardComponent
                              beneficiaryData={beneficiary}
                              campDetails={campBeneficiarDetails?.camp}
                              viewCampName={false}
                              counterNumber={true}
                              time={false}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="h-full py-16">
                    <NoRecord
                      title_1={"There are no records to display"}
                      title_2={""}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    } else if (activeCampCounter.isActiveCampCounter) {
      return (
        <>
          {campCounterLoader ? (
            <>
              <div className="h-[80%] w-full flex justify-center items-center bg-customBlue-mainBackground">
                <Loader />
              </div>
            </>
          ) : (
            <>
              {beneficiarDetailsOfCounter ? (
                <div className="flex gap-3 w-full overflow-y-auto scrollbar-hide h-[calc(100%-80px)]">
                  <div className="space-y-3 w-full">
                    {/* heading */}
                    <HeadingComponent
                      name={"Completed"}
                      total={beneficiarDetailsOfCounter?.completed}
                      colors={{ text: "#0078CE", background: "#DDF1FE" }}
                    />
                    {/* options */}
                    {beneficiarDetailsOfCounter?.completedBeneficiary?.map(
                      (beneficiary, beneficiaryIndex) => {
                        return (
                          <div
                            key={beneficiaryIndex}
                            onClick={() => {
                              setBeneficiaryId(beneficiary?.beneficiary_id);
                              toggleBeneficaryDetailModal();
                            }}
                          >
                            <BeneficiaryShortDetailCardComponent
                              beneficiaryData={beneficiary}
                              campDetails={campBeneficiarDetails?.camp}
                              viewCampName={false}
                              counterNumber={false}
                              time={true}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="space-y-3 w-full">
                    {/* heading */}
                    <HeadingComponent
                      name={"Waiting"}
                      total={beneficiarDetailsOfCounter?.waiting}
                      colors={{ text: "#04CF3D", background: "#E0F9E7" }}
                    />
                    {/* options */}
                    {beneficiarDetailsOfCounter?.waitingBeneficiary?.map(
                      (beneficiary, beneficiaryIndex) => {
                        return (
                          <div
                            key={beneficiaryIndex}
                            onClick={() => {
                              setBeneficiaryId(beneficiary?.beneficiary_id);
                              toggleBeneficaryDetailModal();
                            }}
                          >
                            <BeneficiaryShortDetailCardComponent
                              beneficiaryData={beneficiary}
                              campDetails={campBeneficiarDetails?.camp}
                              viewCampName={false}
                              counterNumber={false}
                              time={false}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="h-full py-16">
                    <NoRecord
                      title_1={"There are no records to display"}
                      title_2={""}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </>
      );
    } else {
      return (
        <div className="flex h-[calc(100%-100px)] items-center justify-around px-4 space-x-2">
          <NoRecord
            title_1="Select a Camp to view"
            title_2="Click on the Camp to view details"
          />
        </div>
      );
    }
  };

  useEffect(() => {
    getAllData(navStatus);
  }, [navStatus]);

  useEffect(() => {
    if (location.pathname === "/campOverview") {
      return () => {
        setSearchValue("");
        setCampFlows({});
        dispatch(resetAllStateOfCampOverView());
      };
    } else {
      return;
    }
  }, [location]);

  return (
    <>
      <div className="h-screen min-w-screen relative">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-80px)]">
          <Sidebar />
          <div className="flex flex-col h-full bg-customBlue-mainBackground w-[100%] overflow-auto">
            <div className="container min-h-[80px] min-w-full px-6 flex items-center justify-between">
              <div className="font-bold font-raleway text-2xl">
                Ongoing Camps / Past Camps
              </div>
            </div>
            {/* Main Contants */}
            <div className="px-8 py-2">
              <NavigationBar
                statuses={[
                  `Ongoing Camps(${onGoingCampCount || 0})`,
                  `Past camps(${pastCampCount || 0})`,
                ]}
                getData={getAllData}
                barName="ongoingCamps"
              />
            </div>
            <div class="flex flex-row justify-between space-x-2 px-4 max-h-[calc(100vh-200px)]">
              <div className="max-h-[calc(100vh-160px)] overflow-auto px-2 pb-3 min-w-[330px] ">
                <div class=" flex flex-col gap-4 ">
                  {campOverviewList?.map((campData, campIndex) => (
                    <div
                      className={`text-sm shadow-md p-4 rounded-xl cursor-pointer bg-white`}
                    >
                      <div key={campIndex}>
                        <div
                          onClick={() => {
                            // setCampFlows((prevCampId) =>
                            //   prevCampId === campData?.camp?._id
                            //     ? null
                            //     : campData?.camp?._id
                            // );
                            handleCampClick(campData?.camp, campIndex);
                          }}
                        >
                          <CurrentCampCardComponent
                            campData={campData}
                            campFlows={campFlows}
                            setCampFlows={setCampFlows}
                          />
                        </div>

                        {/* camp flow*/}
                        {campFlows === campData?.camp?._id && (
                          <div className="p-3 border-[#DBDBDB] border-t-2 flex flex-col gap-2 bg-[#F4FBFF]">
                            {/* heading */}
                            <div className="text-base font-bold">Camp Flow</div>

                            {/* card 1 with toggle active*/}

                            {campSteps?.map((step, stepIndex) => (
                              <div className="bg-[white] border-2 border-[#61B1E5] overflow-hidden rounded-xl space-y-3 w-full">
                                {/* heading with toggle btn */}
                                <div
                                  key={step?._id}
                                  // onClick={() => handleStepClick(step, stepIndex)}
                                >
                                  <CampFlowStepComponent
                                    stepData={step}
                                    stepIndex={stepIndex}
                                    activeCampId={campData?.camp?._id}
                                    isViewCounter={
                                      openStepId ===
                                      step?.stepCount?.stepDetails?._id
                                    }
                                    setIsViewCounter={() =>
                                      handleToggleStep(
                                        step?.stepCount?.stepDetails?._id
                                      )
                                    }
                                  />
                                </div>
                                {/* toggle-body */}
                                {openStepId ===
                                  step?.stepCount?.stepDetails?._id && (
                                  <div className="space-y-2">
                                    {step?.beneficiaryCountDetails?.counterDetails?.map(
                                      (counter, counterIndex) => {
                                        return (
                                          <>
                                            <div key={counterIndex}>
                                              <CounterCardComponent
                                                counterData={counter}
                                                counterIndex={counterIndex}
                                                activeCampId={
                                                  campData?.camp?._id
                                                }
                                                activeCampStepId={
                                                  step?.stepCount?.stepDetails
                                                    ?._id
                                                }
                                              />
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}

                            {/* card 2 with toggle*/}
                          </div>
                        )}
                        <div className="flex justify-end w-full mt-4">
                          {" "}
                          <CustomButton
                            name={`${
                              campData?.camp?.camp_status === true
                                ? "End Camp"
                                : campData?.camp?.camp_status === false
                                ? "Camp Ended"
                                : ""
                            }`}
                            type="button"
                            loading={false}
                            disabled={!campData?.camp?.camp_status}
                            onClick={async () => {
                              const isConfirmed = await confirm({
                                title: "End Camp",
                                message: `Camp will we be marked as ended and the Beneficiaries who have not yet checked in will be marked as 'No Show'`,
                                confirmText: "Yes, I'm sure",
                                cancelText: "No, cancel",
                              });
                              if (isConfirmed) {
                                dispatch(
                                  endStartCamp({
                                    campId: campData?.camp?._id,
                                    onSuccess: () => {
                                      getAllData("onGoing");
                                    },
                                  })
                                );
                              }
                            }}
                            className={`text-white mt-0 ${
                              campData?.camp?.camp_status === true
                                ? "bg-WildWatermelon-button"
                                : campData?.camp?.camp_status === false
                                ? "bg-gray-400"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="p-3 pt-0 w-[calc(100%-330px)] space-y-4">
                <div className="breadcrumb font-bold">{`${activeCurrentCamp?.campName}${activeCurrentCamp?.navStatus}${activeCampStep?.campStepName}${activeCampCounter?.campCounterName}`}</div>

                <div className="flex gap-3">
                  <div className="relative h-[40px] rounded-xl w-full">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-5 pointer-events-none h-full ">
                      <SearchIcon width={24} height={24} />
                    </div>
                    <input
                      type="search"
                      id="default-search"
                      className="block w-full h-full p-4 ps-12 text-base text-gray-tone-100 rounded-xl search-bar"
                      placeholder="Search By Mobile Number/Name"
                      value={searchValue}
                      onChange={(e) => {
                        const searchValue = e.target.value;
                        setSearchValue(searchValue);

                        if (
                          activeCurrentCamp?.campName &&
                          activeCampStep?.campStepName &&
                          activeCampCounter?.campCounterName
                        ) {
                          getBeneficiayOfCounter(
                            activeCampCounter?.campCounterId,
                            searchValue
                          );
                        } else if (
                          activeCurrentCamp?.campName &&
                          activeCampStep?.campStepName
                        ) {
                          allGetCounterOfStepsAndStepBeneficiariesData(
                            activeCurrentCamp?.campId,
                            activeCampStep?.campStepId,
                            searchValue
                          );
                        } else if (activeCurrentCamp?.campName) {
                          allBeneficiaryDetailOfCamp(
                            activeCurrentCamp?.campId,
                            searchValue
                          );
                        }
                      }}
                    />
                  </div>
                  <button
                    className="inline-flex flex-row px-4 justify-around rounded-lg items-center gap-2 bg-[#4AB8FF] text-white font-semibold"
                    onClick={() => {
                      setSearchValue("");
                      getAllData(navStatus);

                      if (
                        activeCurrentCamp?.campId &&
                        activeCampStep?.campStepId &&
                        activeCampCounter?.campCounterId
                      ) {
                        getBeneficiayOfCounter(
                          activeCampCounter?.campCounterId,
                          ""
                        );
                      } else if (
                        activeCurrentCamp?.campId &&
                        activeCampStep?.campStepId
                      ) {
                        allGetCounterOfStepsAndStepBeneficiariesData(
                          activeCurrentCamp?.campId,
                          activeCampStep?.campStepId,
                          ""
                        );
                      } else if (activeCurrentCamp?.campId) {
                        allStepsData(activeCurrentCamp?.campId);
                        allBeneficiaryDetailOfCamp(
                          activeCurrentCamp?.campId,
                          ""
                        );
                      }
                      // setCampFlows({});
                      // dispatch(resetAllStateOfCampOverView());
                    }}
                  >
                    <span className="flex-shrink-0">
                      <RefreshIcon width={24} height={24} />
                    </span>
                    <span className="font-semibold truncate">Refresh</span>
                  </button>
                </div>
                {/* Records */}
                {recordData ? (
                  renderContent()
                ) : (
                  <div className="flex h-[calc(100%-100px)] items-center justify-around px-4 space-x-2">
                    <NoRecord
                      title_1={"Select a Camp to view"}
                      title_2={"Click on the Beneficiaries to view details"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {fullScreenLoading && (
        <div className="fixed h-full inset-0 z-50 flex justify-end">
          <div className="flex items-center justify-center fixed inset-0 bg-[#fafbfc] opacity-80">
            <Loader />
          </div>
        </div>
      )}
      {viewBeneficiaryModal && (
        <BeneficiaryDetailAndJourneyDataModels
          toggleModal={toggleBeneficaryDetailModal}
          beneficiaryId={beneficiaryId}
        />
      )}
      {ModalContent}
    </>
  );
};

export { CampOverviewScreen };
