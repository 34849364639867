/**
 *Assign User for Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Cross } from "../../../assets/icons/Cross";
import DataTable from "react-data-table-component";
import { CampFields } from "../../../constants";
import { Formik } from "formik";
import ScheduleCampDetailCardComponent from "../component/scheduleCampDetailCard";
import {
  assignUsertoCampCounters,
  deleteCampCounterByCounterId,
  getScheduleCampCountersByScheduleCampId,
} from "../slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
  selectScheduleCampSearchQuery,
} from "../selector";
import { getallUsersOptions, selectAllUserOptions } from "../../common";
import SelectDropDown from "../../../components/selectDropDown";
import { Loader } from "../../../components";
import CustomButton from "../../../components/customButtion";
import { Delete } from "../../../assets/icons/Delete";
import DeleteConfirm from "../../../components/deleteConfirm";
import { NoRecord } from "../../../components/noRecords";
import { isPastCamp } from "../../../utils";

const AssignUserForCampModel = ({ toggleModal, rowData, getData }) => {
  const dispatch = useDispatch();
  const { confirm, ModalContent } = DeleteConfirm();
  const [data, setData] = useState();
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const initialValues = {
    [CampFields.COUNTER_ID]: "",
    [CampFields.USER_ID]: "",
  };

  const validationSchema = (items) => {
    const validationFields = {};
    items?.forEach((i, index) => {
      validationFields[`user_id_${index}`] = Yup.string().required(
        `User selection is required for counter no. ${i?.counter_number}`
      );
    });

    return Yup.object().shape(validationFields);
  };

  const scheduleCampSearchQuery = useSelector(selectScheduleCampSearchQuery);
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampFilter = useSelector(selectScheduleCampFilter);

  const { data: usersOptionData = [] } =
    useSelector(selectAllUserOptions) || {};

  const usersOptions = usersOptionData?.map((item) => ({
    label: (
      <div className="w-full flex flex-col justify-center items-start space-y-2 ">
        <div className="!font-bold text-sm text-black">{item?.name}</div>
        <div className="font-medium text-sm">
          {item?.political_district_name}, {item?.political_state_name}
        </div>
        <div className="font-medium text-sm">
          Contact: +91 {item?.mobile_number}
        </div>
      </div>
    ),
    value: item?._id,
  }));

  const [initialObject, setInitialObject] = useState(initialValues);

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
  };

  const getScheduleCampCounters = (scheduleCampId) => {
    setDataTableLoading(true);
    dispatch(
      getScheduleCampCountersByScheduleCampId({
        scheduled_camp_id: scheduleCampId,
        onSuccess: (response) => {
          const { data: list = [] } = response;
          const data2 = list.map((item, index) => ({
            ...item,
            [`user_id_${index}`]: item?.user_id || null,
          }));
          setData(data2);
          setInitialObject(data2);
          setDataTableLoading(false);
        },
        onFailure: (props) => {
          setDataTableLoading(false);
        },
      })
    );
  };

  const getUserDopdownOption = () => {
    dispatch(
      getallUsersOptions({
        scheduled_camp_id: rowData?._id,
      })
    );
  };

  useEffect(() => {
    if (rowData?._id) {
      getScheduleCampCounters(rowData?._id);
    }
    getUserDopdownOption();
  }, [rowData]);

  return (
    <>
      <div>
        <Formik
          initialValues={initialObject}
          enableReinitialize={true}
          validationSchema={validationSchema(data)}
          onSubmit={(values) => {
            const updatedData = data.map((item, index) => {
              return {
                scheduled_camp_id: rowData?._id,
                counter_id: item?._id,
                counter_number: item?.counter_number,
                user_id: values[index]?.[`user_id_${index}`] || item?.user_id,
              };
            });

            // Call your API with updatedData
            dispatch(
              assignUsertoCampCounters({
                counter_data: updatedData,
                onSuccess: (props) => {
                  const { message, data } = props;
                  toast.success(message);
                  getData(
                    scheduleCampSearchQuery,
                    scheduleCampFilter,
                    scheduleCampCurrentPage,
                    scheduleCampPerPage
                  );
                  getScheduleCampCounters(rowData?._id);
                  toggleModal();
                },
              })
            );
          }}
        >
          {({
            values,
            errors,
            handleChange,
            touched,
            handleSubmit,
            setFieldValue,
            resetForm,
            submitCount,
          }) => {
            const selectedCampSteps = data?.map(
              (step, index) => step[`user_id_${index}`]
            );

            // Function to filter out options based on selected users
            const filteredUserOptions = (index) => {
              return usersOptions.filter(
                (option) =>
                  !selectedCampSteps.includes(option.value) || // Exclude users already selected
                  option.value === values[index]?.[`user_id_${index}`]
              );
            };

            // Update selected users whenever a new user is selected
            const handleUserChange = (selectedOption, index) => {
              const updatedRow = {
                ...data[index],
                [`user_id_${index}`]: selectedOption?.value || "",
              };
              const updatedData = data.map((item, i) =>
                i === index ? updatedRow : item
              );
              setData(updatedData);
              setInitialObject(updatedData);
            };
            const columns = [
              {
                name: "#",
                selector: (row) => row?.has,
                style: columnStyle,
                maxWidth: "50px",
                width: "50px",
              },
              {
                name: "Step",
                selector: (row) => row?.camp_step_id?.camp_step_display_name,
                style: columnStyle,
                maxWidth: "150px",
                maxWidth: "130px",
                minWidth: "120px",
              },
              {
                name: "Counter No.",
                selector: (row) => row?.counter_number,
                center: true,
                style: columnStyle,
                maxWidth: "130px",
                minWidth: "120px",
              },
              {
                name: "User",
                selector: (row, index) => row?.user_id,
                center: true,
                style: columnStyle,
                maxWidth: "400px",
                cell: (row, index) => (
                  <>
                    <div className="w-full">
                      <SelectDropDown
                        options={
                          selectedCampSteps ? filteredUserOptions(index) : []
                        }
                        name={`user_id_${index}`}
                        placeholder="Select"
                        onChange={(selectedOption) => {
                          handleUserChange(selectedOption, index);
                        }}
                        value={
                          usersOptions.find(
                            (item) =>
                              item.value === values[index]?.[`user_id_${index}`]
                          ) || null
                        }
                        touched={touched}
                        errors={errors}
                        isSearchable={true}
                        isClearable={true}
                        disabled={isPastCamp(rowData)}
                      />
                      {errors[`user_id_${index}`] &&
                        (touched[`user_id_${index}`] || submitCount > 0) && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[`user_id_${index}`]}
                          </div>
                        )}
                    </div>
                  </>
                ),
              },
              {
                name: "",
                selector: (row, index) => row?._id,
                center: true,
                style: columnStyle,
                width: "50px",
                cell: (row, index) => (
                  <>
                    <div
                      className={`w-full flex items-center justify-center mr-4 `}
                    >
                      <span
                        className={`${
                          isPastCamp(rowData)
                            ? "opacity-50 cursor-not-allowed"
                            : "cursor-pointer"
                        }`}
                        onClick={async () => {
                          if (!isPastCamp(rowData)) {
                            const isConfirmed = await confirm({
                              title: "Delete",
                              message: ` Counter No. ${row?.counter_number} for ${row?.camp_step_id?.camp_step_display_name} will be removed from this Camp`,
                              confirmText: "Yes, I'm sure",
                              cancelText: "No, cancel",
                              icon: <Delete height={20} width={20} />,
                            });
                            if (isConfirmed) {
                              dispatch(
                                deleteCampCounterByCounterId({
                                  id: row?._id,
                                  onSuccess: (props) => {
                                    toast.success(props?.message);
                                    getScheduleCampCounters(rowData?._id);
                                  },
                                })
                              );
                            }
                          }
                        }}
                      >
                        <Delete width={18} height={20} />
                      </span>
                    </div>
                  </>
                ),
              },
            ];

            return (
              <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <div className="fixed h-full inset-0 z-50 flex justify-end bg-[#07284b80]">
                  <div className="flex flex-col relative w-[45%] h-full bg-white shadow-lg overflow-y-auto">
                    <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
                      <h2 className="text-xl font-bold">Assign User</h2>
                      <button
                        onClick={toggleModal}
                        className="text-xl font-semibold"
                      >
                        <Cross width={32} height={32} />
                      </button>
                    </div>
                    <div className="w-full flex flex-col flex-auto space-y-4 py-6 px-6">
                      <div>
                        <ScheduleCampDetailCardComponent data={rowData} />
                      </div>
                      {data?.length > 0 ? (
                        <div className="h-full">
                          <DataTable
                            columns={columns}
                            data={data}
                            progressPending={dataTableLoading}
                            progressComponent={
                              <div className="h-[400px] w-full flex justify-center items-center bg-customBlue-mainBackground">
                                <Loader />
                              </div>
                            }
                            customStyles={{
                              headCells: {
                                style: {
                                  backgroundColor: "#DDF1FE",
                                  color: "Black",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                },
                              },
                            }}
                          />
                          <div className="w-full flex justify-end space-x-4 mt-4">
                            <CustomButton
                              name="Reset"
                              type="button"
                              loading={false}
                              disabled={isPastCamp(rowData)}
                              className={"min-w-[32%] bg-[#C1C1C1] h-full"}
                              onClick={(e) => {
                                e.preventDefault();
                                resetForm();
                              }}
                            />
                            <CustomButton
                              name="Submit"
                              type="submit"
                              loading={false}
                              disabled={isPastCamp(rowData)}
                              className={"min-w-[32%]"}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex h-[70%] w-full justify-center items-center">
                          <NoRecord
                            title_1={
                              " Please set up the camp flow first in order to assign users"
                            }
                            title_2={""}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
        {ModalContent}
      </div>
    </>
  );
};

export { AssignUserForCampModel };
