/**
 * Running Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderScreen, Loader, Sidebar } from "../../../components";
import NavigationBar from "../components/navigationBar";
import BeneficiaryList from "../components/beneficiaryList";
import {
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  setActiveUser,
  setCurrCampNavStatus,
  setCurrCampSearch,
} from "../slice";
import {
  selectActiveUser,
  selectBeneficiaryDetails,
  selectBeneficiaryProcessDetail,
  selectCurrCampCurrentPage,
  selectCurrCampDataPerPage,
  selectCurrCampDistrictId,
  selectCurrCampFilter,
  selectCurrCampNavStatus,
  selectCurrCampSearch,
  selectCurrCampStateId,
  selectFilter,
  selectStateId,
} from "../selector";
import BeneficiaryJourney from "../components/beneficiaryJourney";
import PersonalDetails from "../components/personalDetails";
import BeneficiaryDetail from "../components/beneficiaryDetail";
import Pagination from "../../../components/pagination";
import { InfoIcon } from "../../../assets/icons/info";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { Switch } from "@material-tailwind/react";
import {
  getAllBeneficiariesOfCampByScheduleCampId,
  getAllBeneficiariesOfCampByScheduleCampIdForCheckIn,
  getScheduleCampStepById,
  getStepAndCounterOfUser,
  setAllBeneficiariesListForCamp,
  updateToggleForAvailableUnavailable,
} from "../../camp_management/slice";
import { selectAllBeneficiariesListForCamp } from "../../camp_management/selector";
import { formatDate, manipulateDateAndTime } from "../../../utils";
import { FabricationReadOnlyComponent } from "../components/fabricationCard";
import BeneficiaryVerificationPart from "../components/beneficiaryVerificationForm";
import { AssessmentComponent } from "../components/assessmentCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllStages,
  getAllStatus,
  selectCampUserCounterData,
  selectFullScreenLoading,
  selectStages,
  selectStatus,
  setFullScreenLoading,
} from "../../common";
import { NoRecord } from "../../../components/noRecords";
import { socket } from "../../../socket/socket";
import { SocketName } from "../../../socket/socket_constant";
import { RefreshIcon } from "../../../assets/icons/refresh";

function RunningCampScreen({ selectedCamp }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const { id } = useParams();
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [allData, setAllData] = useState({});
  const [dateTime, setDateTime] = useState({});
  const [loading, setLoading] = useState(false);
  const [singleDetailLoading, setSingleDetailLoading] = useState(false);
  const scrollableRef = useRef(null);

  const {
    data: beneficiaries = [],
    totalCounts,
    responseCount,
    totalCampBeneficiary,
    screenedByMeCount,
    yetToCheckInCount,
    screenedByMe,
    waitingForOtherDesk,
    waitingForMe,
    currentPage: currentPageApi,
  } = useSelector(selectAllBeneficiariesListForCamp) || {};
  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};
  const userCountersData = useSelector(selectCampUserCounterData) || {};
  const activeUserId = useSelector(selectActiveUser);
  const singleBeneficiaryData = useSelector(selectBeneficiaryDetails) || {};
  const singleBeneficiary = beneficiaryDetails || {};
  const search = useSelector(selectCurrCampSearch);
  const currentPage = useSelector(selectCurrCampCurrentPage);
  const limit = useSelector(selectCurrCampDataPerPage);
  const districtId = useSelector(selectCurrCampDistrictId);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);
  const navStatus = useSelector(selectCurrCampNavStatus);
  const screenLoading = useSelector(selectFullScreenLoading);
  const stageIds =
    useSelector(selectStages).reduce((acc, stage) => {
      acc[stage.beneficiary_stage_name] = stage._id;
      return acc;
    }, {}) || {};

  // handel journey stpes
  const [expandedSteps, setExpandedSteps] = useState({
    registration: false,
    verification: false,
    assessment: false,
    fabrication: false,
    distribution: false,
  });
  const toggleStep = (step) => {
    setExpandedSteps({
      ...expandedSteps,
      [step]: !expandedSteps[step],
    });
  };

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0]
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };

  // assessment data status managae
  const assessment_last_Status =
    beneficiaryJourney?.stages?.assessments?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.assessments?.data[0] // Correct reference here
    );

  // Distribution data status manage
  const distribution_last_Status =
    beneficiaryJourney?.stages?.distribution?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.distribution?.data[0] // Set the first item as the initial value
    );

  // Fabrication data status manage
  const fabrication_data =
    beneficiaryJourney?.stages?.fabrication?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.fabrication?.data[0] // Correct reference here
    );

  const fabrication_Started_data =
    beneficiaryJourney?.stages?.fabrication?.data?.find(
      (item) => item.status_id.status_name === "fabrication_in_progress"
    );

  const activeCampFlow = allData?.camp_flow?.find(
    (item) => item.is_active === true
  );

  const allStatus =
    useSelector(selectStatus).reduce((acc, status) => {
      acc[status.status_name] = status._id;
      return acc;
    }, {}) || {};

  // Api calling functions
  const getBeneficiaryDataList = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter,
    stepName
  ) => {
    setLoading(true);
    if (stepName) {
      dispatch(setAllBeneficiariesListForCamp({}));
      if (stepName === "check_in") {
        dispatch(
          getAllBeneficiariesOfCampByScheduleCampIdForCheckIn({
            scheduled_camp_id: id,
            search: searchData,
            stateId,
            districtId,
            status,
            page: page,
            perPage: perPage,
            filter,
            onSuccess: (props) => {
              setLoading(false);
              setBeneficiaryDetailToggle(false);
            },
            onFailure: (props) => {
              setLoading(false);
              // setBeneficiaryDetailToggle(false);
            },
          })
        );
      } else if (stepName != "check_in") {
        dispatch(
          getAllBeneficiariesOfCampByScheduleCampId({
            scheduled_camp_id: id,
            search: searchData,
            stateId,
            districtId,
            status,
            page: page,
            perPage: perPage,
            filter,
            onSuccess: (props) => {
              setBeneficiaryDetailToggle(false);
              setLoading(false);
            },
            onFailure: (props) => {
              // setBeneficiaryDetailToggle(false);
              setLoading(false);
            },
          })
        );
      }
    }
  };

  const getBeneficiaryDetailByUserId = (userId) => {
    setSingleDetailLoading(true);
    dispatch(setFullScreenLoading(true));
    dispatch(
      getBeneficiaryDetailById({
        id: userId,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(true);
          setSingleDetailLoading(false);
          dispatch(setFullScreenLoading(false));
        },
        onFailure: (props) => {
          // setBeneficiaryDetailToggle(true);
          setSingleDetailLoading(false);
          dispatch(setFullScreenLoading(false));
        },
      })
    );
  };

  const getScheduleCampCampFlowData = (id) => {
    dispatch(
      getScheduleCampStepById({
        id,
        onSuccess: (props) => {
          const { data } = props;
          toggleStep(
            data?.scheduled_camp_id?.camp_type_id?.camp_type_name.toLowerCase()
          );
          if (data?.scheduled_camp_id?.camp_status === false) {
            navigate(`/myCampList`, {
              replace: true,
            });
          }
          setAllData(data);
        },
      })
    );
  };

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        id,
        onSuccess: (props) => {},
      })
    );
  };

  const getStatusData = () => {
    dispatch(
      getAllStatus({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  const getUserCounterData = (id) => {
    dispatch(
      getStepAndCounterOfUser({
        scheduled_camp_id: id,
        onSuccess: (props) => {},
      })
    );
  };

  const getStagesData = () => {
    dispatch(
      getAllStages({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      campScheduled: "",
      campComplete: `Completed on ${dateTime.date}; ${dateTime.time}`,
      campNotSchedled: "",
      lastStatus: "complete",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={singleBeneficiaryData}
          userVerificationData={latest_data}
        />
      ),
      campScheduled: "",
      campNotSchedled: "",
      campComplete: `Completed on ${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
      lastStatus:
        latest_data?.status === "verified"
          ? "complete"
          : latest_data?.status === "rejected"
          ? "in_progress"
          : "",
    },
    {
      name: "Assessment",
      component: (
        <AssessmentComponent
          campSteps={activeCampFlow?.camp_flow_steps}
          campData={campData?.assessment}
          scheduled_camp_id={id}
          beneficiary_id={singleBeneficiary?._id}
          status={allStatus}
          userCountersData={userCountersData}
          componentName={"assessment"}
          getJourneyData={getBeneficiaryProcessDetailByBeneficiaryId}
          getBeneficiaryListCallback={getBeneficiaryDataList}
          campComplete={
            assessment_last_Status?.status_id?.status_name ===
            "assessement_completed"
              ? true
              : false
          }
        />
      ),
      campScheduled:
        assessment_last_Status?.status_id?.status_name ===
          "assessment_in_progress" ||
        assessment_last_Status?.status_id?.status_name ===
          "assessement_scheduled"
          ? campData?.assessment?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.assessment[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.assessment[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } (${
                campData?.assessment?.[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp Code : ${
                campData?.assessment?.[0]?.scheduled_camp_id?.camp_code
              })`
            : ""
          : "",
      ReSchedledButton: false,
      schedledButton: false,
      campComplete:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? `Completed on ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.time
            } at ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } (${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp Code : ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_code
            })`
          : "",
      campNotSchedled: !campData?.assessment ? `Not yet scheduled` : "",
      disabled:
        allData?.scheduled_camp_id?.camp_type_id?.camp_type_name ===
        "assessment"
          ? false
          : true,
      lastStatus:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? "complete"
          : assessment_last_Status?.status_id?.status_name ===
            "assessment_in_progress"
          ? "in_progress"
          : "",
    },
    {
      name: "Fabrication",
      component: (
        <FabricationReadOnlyComponent
          beneficiary_id={singleBeneficiary?._id}
          status={allStatus}
          stageIds={stageIds}
          fabrication_data={fabrication_data || {}}
          fabricationDisabled={true}
        />
      ),
      campScheduled:
        fabrication_data?.status_id?.status_name ===
          "fabrication_in_progress" ||
        fabrication_data?.status_id?.status_name ===
          "fabrication_not_yet_started"
          ? `Started on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            } `
          : "",
      campComplete:
        fabrication_data?.status_id?.status_name === "fabrication_completed" ? (
          <div>
            {`Started on ${
              manipulateDateAndTime(fabrication_Started_data?.createdAt)?.date
            }; ${
              manipulateDateAndTime(fabrication_Started_data?.createdAt)?.time
            } By ${fabrication_data?.created_by?.name}`}
            <br />
            {`Completed on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            }`}
          </div>
        ) : (
          ""
        ),
      campNotSchedled: fabrication_data ? "" : "Not yet scheduled",
      disabled: true,
      lastStatus:
        fabrication_data?.status_id?.status_name === "fabrication_completed"
          ? "complete"
          : fabrication_data?.status_id?.status_name ===
            "fabrication_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM | Completed on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Distribution",
      component: (
        <AssessmentComponent
          campSteps={activeCampFlow?.camp_flow_steps}
          campData={campData?.distribution}
          scheduled_camp_id={id}
          beneficiary_id={singleBeneficiary?._id}
          status={allStatus}
          userCountersData={userCountersData}
          componentName={"distribution"}
          getJourneyData={getBeneficiaryProcessDetailByBeneficiaryId}
          getBeneficiaryListCallback={getBeneficiaryDataList}
          campComplete={
            distribution_last_Status?.status_id?.status_name ===
            "distribution_completed"
              ? true
              : false
          }
        />
      ),
      ReSchedledButton: false,
      schedledButton: false,
      campScheduled:
        distribution_last_Status?.status_id?.status_name ===
          "distribution_in_progress" ||
        distribution_last_Status?.status_id?.status_name ===
          "distribution_scheduled"
          ? campData?.distribution?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.distribution[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.distribution[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } (${
                campData?.distribution?.[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp Code : ${
                campData?.distribution?.[0]?.scheduled_camp_id?.camp_code
              })`
            : ""
          : "",
      campComplete:
        distribution_last_Status?.status_id?.status_name ===
        "distribution_completed"
          ? `Completed on ${
              manipulateDateAndTime(distribution_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(distribution_last_Status?.createdAt)?.time
            } at ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } (${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp Code : ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_code
            })`
          : "",
      campNotSchedled: !campData?.distribution ? `Not yet scheduled` : "",
      disabled:
        allData?.scheduled_camp_id?.camp_type_id?.camp_type_name ===
          "distribution" ||
        fabrication_data?.status_id?.status_name === "fabrication_completed"
          ? false
          : true,
      lastStatus:
        distribution_last_Status?.status_id?.status_name ===
        "distribution_completed"
          ? "complete"
          : distribution_last_Status?.status_id?.status_name ===
            "distribution_in_progress"
          ? "in_progress"
          : "",
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    } else if (singleBeneficiaryData?._id) {
      const result = manipulateDateAndTime(singleBeneficiaryData?.createdAt);
      setDateTime(result);
      getBeneficiaryProcessDetailByBeneficiaryId(singleBeneficiaryData?._id);
      getStatusData();
      getStagesData();
    }
  }, [singleBeneficiaryData]);

  useEffect(() => {
    // Only proceed if userCountersData is defined
    // if (!userCountersData) return;

    getBeneficiaryDataList(
      search,
      stateId,
      districtId,
      navStatus,
      currentPage,
      limit,
      filter,
      userCountersData?.step
    );
  }, [
    search,
    currentPage,
    stateId,
    districtId,
    limit,
    filter,
    navStatus,
    userCountersData?.step,
  ]);

  useEffect(() => {
    // Fetch schedule and counter data when id is available
    if (id) {
      getScheduleCampCampFlowData(id);
      getUserCounterData(id);
    }
  }, [id]);

  const dataForBeneficaryAssignToMe = {
    scheduled_camp_id: allData?.scheduled_camp_id?._id,
    camp_counter_id: userCountersData?.counter_id,
    camp_step_id: userCountersData?.stepId,
  };

  useEffect(() => {
    // Function to handle the beforeunload event
    const handleBeforeUnload = (event) => {
      if (userCountersData?.is_active) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [userCountersData?.is_active]);

  useEffect(() => {
    socket.on(SocketName.COUNTER_REFRESH, (data) => {
      dispatch(
        getStepAndCounterOfUser({
          scheduled_camp_id: id,
          onSuccess: (props) => {},
        })
      );
      if (userCountersData?.step === "check_in") {
        dispatch(
          getAllBeneficiariesOfCampByScheduleCampIdForCheckIn({
            scheduled_camp_id: id,
            search,
            stateId,
            districtId,
            status: navStatus,
            page: currentPage,
            perPage: limit,
            filter,
            onSuccess: (props) => {},
            onFailure: (props) => {},
          })
        );
      } else if (userCountersData?.step != "check_in") {
        dispatch(
          getAllBeneficiariesOfCampByScheduleCampId({
            scheduled_camp_id: id,
            search,
            stateId,
            districtId,
            status: navStatus,
            page: currentPage,
            perPage: limit,
            filter,
            onSuccess: (props) => {},
            onFailure: (props) => {},
          })
        );
      }
      if (activeUserId) {
        dispatch(
          getBeneficiaryDetailById({
            id: activeUserId,
            onSuccess: (props) => {},
            onFailure: (props) => {},
          })
        );
        dispatch(
          getBeneficiaryProcessDetails({
            id: activeUserId,
            onSuccess: (props) => {},
          })
        );
      }
    });
  }, [userCountersData?.step, activeUserId]);

  return (
    <>
      <div className="h-full w-screen" onBeforeunload={() => {}}>
        <HeaderScreen />
        <div className="flex h-[calc(100%-80px)] w-[100%]">
          <Sidebar />

          <div className="container p-0 flex flex-col justify-between h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            {/* Camp Details  */}
            <div className="w-full p-4 pb-0">
              <div className="flex gap-3 justify-between items-center flex-wrap p-4 bg-[#E5F4FE] rounded-xl border-[#54BBFF] border">
                <div className="flex gap-3 items-center">
                  <div className="font-bold text-2xl title">
                    {
                      allData?.scheduled_camp_id?.camp_type_id
                        ?.camp_type_display_name
                    }{" "}
                    Camp
                  </div>
                  <span className="text-gray-tone-divider">|</span>
                  <div className="font-bold text-xl title">
                    Camp Code:{" "}
                    <span className="text-[#FF5976]">
                      {allData?.scheduled_camp_id?.camp_code}
                    </span>
                  </div>
                  <span className="text-gray-tone-divider">|</span>
                  <div className="font-semibold  flex items-start gap-[1rem]">
                    <div className="font-semibold  flex items-start flex-col gap-[0.3rem]">
                      <div className="font-semibold  flex items-center gap-1.5">
                        <CalendarNew />
                        <div className=" ">
                          {formatDate(allData?.scheduled_camp_id?.from_date)}
                        </div>
                      </div>
                      <div className="font-semibold  flex items-center gap-1.5">
                        <Clock width={16} height={16} />
                        <div className="">
                          {allData?.scheduled_camp_id?.from_time}-
                          {allData?.scheduled_camp_id?.to_time}
                        </div>
                      </div>
                    </div>
                    <InfoIcon />
                  </div>
                </div>
                <div className="flex gap-3 items-center p-2 bg-[white] rounded-xl border-[#C0E6FF] border">
                  <div className="font-semibold text-lg title">
                    Step:{" "}
                    <span className="font-bold text-[#FF5976]">
                      {userCountersData?.camp_step_display_name}
                    </span>
                  </div>
                  <div className="font-semibold text-lg title">
                    Counter No:
                    <span className="font-bold text-[#FF5976]">
                      {userCountersData?.counter_number}
                    </span>
                  </div>
                  <div className="font-bold text-lg title bg-[#D6FFE4] text-center py-1 px-3 rounded-xl text-[#007C2A]">
                    {userCountersData?.completed}
                    <div className="text-xs uppercase">Completed</div>
                  </div>
                  <div className="font-bold text-base title bg-[#DDF1FE] text-center py-1 px-3 rounded-xl text-[#256CD0]">
                    {userCountersData?.step === "check_in"
                      ? userCountersData?.yetToCheckInCount
                      : userCountersData?.waiting}
                    <div className="text-xs uppercase">waiting</div>
                  </div>
                </div>
              </div>
            </div>
            {/* Beneficiary Data  */}
            <div className="container  w-full max-w-full px-4 py-0 mt-0 flex flex-row justify-between items-center ">
              <div className="flex flex-row items-center justify-between font-bold text-2xl title max-w-[42%] min-w-[42%]">
                <div>Beneficiaries</div>
              </div>
              <div className="flex flex-row space-x-2">
                <span>Unavailable</span>
                <Switch
                  id={userCountersData?.counter_id}
                  ripple={false}
                  checked={userCountersData?.is_active}
                  onChange={() => {}}
                  onClick={() => {
                    dispatch(
                      updateToggleForAvailableUnavailable({
                        id: userCountersData?.counter_id,
                        status: !userCountersData?.is_active,
                        onSuccess: (props) => {
                          getUserCounterData(id);
                        },
                      })
                    );
                  }}
                  className="h-full w-full checked:bg-[#FE5976]"
                  containerProps={{
                    className: "w-8 h-4",
                  }}
                  circleProps={{
                    className:
                      "before:hidden left-1 border-none w-[12px] h-[12px]",
                  }}
                />
                <span>Available</span>
              </div>
            </div>

            <div className=" p-0 flex flex-row w-full items-center justify-between">
              <div className="container p-0 flex w-full px-4  max-w-[42%] min-w-[42%]  ">
                {userCountersData?.step === "check_in" ? (
                  <NavigationBar
                    statuses={[
                      `Yet to Check in(${yetToCheckInCount || 0})`,
                      `Completed by me(${screenedByMeCount || 0})`,
                    ]}
                    getData={getBeneficiaryDataList}
                    beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                    barName="campBeneficiaryScreen"
                  />
                ) : (
                  <NavigationBar
                    statuses={[
                      `Waiting for me(${waitingForMe || 0})`,
                      `Completed by me(${screenedByMe || 0})`,
                      `Waiting at other desk(${waitingForOtherDesk || 0})`,
                    ]}
                    getData={getBeneficiaryDataList}
                    beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                    barName="campBeneficiaryScreen"
                  />
                )}

                {/* <div className="col-span-6"></div> */}
              </div>
              <div className="h-full pr-8">
                {" "}
                <button
                  className="inline-flex flex-row px-2 justify-around text-[18px] rounded-lg items-center gap-2 bg-[#4AB8FF] text-white font-semibold"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <span className="flex-shrink-0">
                    <RefreshIcon width={18} height={18} />
                  </span>
                  <span className="font-semibold truncate">Refresh</span>
                </button>
              </div>
            </div>

            <div className="container  min-h-[50%] flex-auto  flex flex-row justify-between gap-6 w-full p-4  pb-0 max-w-full">
              {/* User List with Search  and Filter */}
              <BeneficiaryList
                users={beneficiaries}
                setSearchQuery={(value) => {
                  dispatch(setCurrCampSearch(value));
                  dispatch(setActiveUser(null));
                }}
                getBeneficiaryDetailByUserId={getBeneficiaryDetailByUserId}
                beneficiaryStatus={false}
                completionTime={true}
                dateRange={false}
                currentStages={true}
                loading={loading}
                checkBox={true}
                data={dataForBeneficaryAssignToMe}
                callbackApiList={getBeneficiaryDataList}
              />
              {/* User Details*/}
              {beneficiaryDetailToggle &&
              Object.keys(singleBeneficiaryData).length > 0 ? (
                singleBeneficiaryData?.status === "Verified" ? (
                  singleDetailLoading ? (
                    <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                      <Loader />
                    </div>
                  ) : (
                    <div className="container p-0 h-full self-end w-[58%] pr-4">
                      <div
                        className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-6 py-4 max-w-full w-full rounded-xl"
                        ref={scrollableRef}
                      >
                        {/* Beneficiary Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
                          <BeneficiaryDetail userData={singleBeneficiary} />
                        </div>

                        {/* Personal Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                          <PersonalDetails userData={singleBeneficiary} />
                        </div>
                        {/* Beneficiary Journey */}
                        <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                          <BeneficiaryJourney
                            beneficiary={singleBeneficiary}
                            steps={steps}
                            beneficiaryJourney={beneficiaryJourney}
                            campData={campData}
                            allData={allData}
                            setExpandedSteps={setExpandedSteps}
                            expandedSteps={expandedSteps}
                            toggleStep={toggleStep}
                          />
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                    <NoRecord
                      title_1={"Select a Beneficiary to view"}
                      title_2={"Click on the Beneficiaries to view details"}
                    />
                  </div>
                )
              ) : singleDetailLoading ? (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <Loader />
                </div>
              ) : (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <NoRecord
                    title_1={"Select a Beneficiary to view"}
                    title_2={"Click on the Beneficiaries to view details"}
                  />
                </div>
              )}
            </div>

            <div className=" w-full flex justify-start items-center">
              <Pagination
                name={"runningCampScreen"}
                totalNumber={totalCounts}
                dataPerPage={limit}
                data={getBeneficiaryDataList}
                currentPageApi={currentPage}
                perPageOptions={[5, 10, 30, 50, 100]}
                showFirstButton={true}
                showLastButton={true}
              />
            </div>
          </div>
        </div>
        {screenLoading && (
          <div className="fixed h-full inset-0 z-50 flex justify-end">
            <div className="flex items-center justify-center fixed inset-0 bg-[#fafbfc] opacity-80">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export { RunningCampScreen };
