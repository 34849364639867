/**
 * Schedule Camp Management Selector
 * @format
 */

const scheduleCampManagement = (state) => state.scheduleCampManagementReducer;

export const selectAllScheduleCampList = (state) =>
  scheduleCampManagement(state).allScheduleCampsDataList;

export const selectScheduleCamp = (state) =>
  scheduleCampManagement(state).scheduleCamp;

export const selectScheduleCampCurrentPage = (state) =>
  scheduleCampManagement(state).currentPage;

export const selectScheduleCampRespPerPage = (state) =>
  scheduleCampManagement(state).respPerPage;

export const selectScheduleCampFilter = (state) =>
  scheduleCampManagement(state).filter;

export const selectScheduleCampSearchQuery = (state) =>
  scheduleCampManagement(state).searchQuery;

export const selectScheduleCampStepsDataList = (state) =>
  scheduleCampManagement(state).allScheduledCampStepsDataList;

export const selectAllAssinBeneficiayDataList = (state) =>
  scheduleCampManagement(state).allAssinedBeneficiary;

export const selectAllCampListForSelectBox = (state) =>
  scheduleCampManagement(state).allCampListForSelect;

export const selectAllBeneficiariesListForCamp = (state) =>
  scheduleCampManagement(state).allBeneficiariesListForCamp;

export const selectBeneficiariesQueryList = (state) =>
  scheduleCampManagement(state).beneficiariesQueryList;

export const selectBeneficiariesWithoutAssignFormQueryList = (state) =>
  scheduleCampManagement(state).beneficiariesWithoutAssignFormQueryList;

export const selectAllMyCamps = (state) =>
  scheduleCampManagement(state).allMyCamps;

export const selectBeneficiaryQueryCurrentPage = (state) =>
  scheduleCampManagement(state).beneficiaryQueryCurrentPage;

export const selectBeneficiaryQueryRespPerPage = (state) =>
  scheduleCampManagement(state).beneficiaryQueryRespPerPage;

export const selectBeneficiaryQuerySearch = (state) =>
  scheduleCampManagement(state).beneficiaryQuerySearch;

// export const selectCampUserCounterData = (state) =>
//   scheduleCampManagement(state).campUserCounterData;

export const selectCampCenterOptionsByState = (state) =>
  scheduleCampManagement(state).campCenterOptionsByState;

export const selectStateIdForScheduleCamp = (state) =>
  scheduleCampManagement(state).stateIdForScheduleCamp;

export const selectAllStateWithScheduleCampCount = (state) =>
  scheduleCampManagement(state).stateWithScheduleCampCount;
