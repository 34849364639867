/**
 * Beneficiary Short Detail Card Component
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CounterNumberComponent from "../../../components/counterNumber";
import ViewTimeComponent from "../../../components/viewTimeComponent";
import moment from "moment";

const BeneficiaryShortDetailCardComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { beneficiaryData, viewCampName, counterNumber, time, campDetails } =
    props;

  return (
    <>
      <div className="p-4 bg-[white] h-max shadow-sm  overflow-hidden rounded-xl w-full justify-between gap-1 items-center">
        <div className="flex gap-3 items-start justify-between w-full">
          <div className="flex gap-1 items-start w-full justify-between">
            <div className="font-semibold whitespace-nowrap space-y-2">
              {beneficiaryData?.name} <span>|</span>{" "}
              <span>{beneficiaryData?.age}</span> <span>|</span>{" "}
              <span>{beneficiaryData?.gender}</span>
              <div className="text-[#666] font-medium">
                {beneficiaryData?.district}, {beneficiaryData?.state}
              </div>
              {viewCampName && (
                <div className="text-[#04CF3D]">
                  {beneficiaryData?.currentStepName}
                </div>
              )}
            </div>
            <div className="text-right space-y-2">
              <div className="text-center font-semibold whitespace-nowrap">
                Reg. #
                <span className="font-bold">
                  {beneficiaryData?.registration_number}
                </span>
              </div>
              {counterNumber && (
                <CounterNumberComponent
                  counterNumber={beneficiaryData?.counterNumber}
                />
              )}
              {time && (
                <ViewTimeComponent
                  time={
                    moment(beneficiaryData?.time).format("hh:mm A") || "00:00"
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryShortDetailCardComponent;
