/**
 *  Current Camp Card Component
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LiveIcon } from "../../../assets/icons/live-icon";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { User } from "../../../assets/icons/User";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import StepWithCountsComponent from "./stepWithNumber";
import { SelectActiveCurrentCamp } from "../selector";
import { formatDate } from "../../../utils";

const CurrentCampCardComponent = (props) => {
  const { campData, campFlows, setCampFlows } = props;
  const { camp, counts, users } = campData;

  const activeCurrentCamp = useSelector(SelectActiveCurrentCamp);

  return (
    <>
      <div className="flex flex-col gap-3 items-start justify-between w-full ">
        {/* camp location */}
        <div className="flex flex-row gap-1 items-start  w-full">
          <div className="font-semibold whitespace-nowrap overflow-hidden truncate">
            {camp?.camp_location_id?.camp_location_name}
          </div>
          <div className="bg-[#00A638] rounded-md flex items-center justify-center px-[2px] py-[2px]">
            <LiveIcon width={24} height={14}></LiveIcon>
          </div>
        </div>
        {/* camp type and code */}
        <div className="flex gap-3 items-start justify-between w-full">
          <div className="flex flex-row justify-between items-center w-full">
            <div className="text-center whitespace-nowrap">
              <span
                className={`font-bold ${
                  camp?.camp_type_id?.camp_type_name === "assessment"
                    ? "bg-[#FFEBEE]"
                    : camp?.camp_type_id?.camp_type_name === "distribution"
                    ? "bg-[#FEF5E0]"
                    : "bg-white"
                }  px-2 py-2 rounded-lg`}
              >
                {camp?.camp_type_id?.camp_type_display_name} Camp
              </span>
            </div>
            <div className="text-center whitespace-nowrap">
              Camp Code <br></br>
              <span className="font-bold">{camp?.camp_code}</span>
            </div>
          </div>
        </div>
        {/* date n time */}
        <div className="flex justify-between gap-1 items-start w-full">
          <div className="flex justify-between gap-1 items-start">
            <CalendarNew />
            <div className="text-[#666]">{formatDate(camp?.from_date)}</div>
          </div>
          <div className="flex justify-between gap-1 items-start">
            <Clock width={18} height={18} />
            <div className="text-[#666]">
              {camp?.from_time}-{camp?.to_time}
            </div>
          </div>
        </div>
        {/* location n users */}
        <div className="flex justify-between gap-1 items-center">
          <div className="flex justify-between gap-1 items-start">
            <LocationMap width={14} height={16} fillColor={"#7B7B7B"} />

            <div className="text-[#666]">
              <p className="text-sm">
                {camp?.camp_location_id?.address_1},{" "}
                {camp?.camp_location_id?.address_2}, Dist-
                {camp?.camp_location_id?.district?.district_name}{" "}
                {camp?.camp_location_id?.state?.state_name}.
              </p>
            </div>
          </div>
          <div className="flex justify-between gap-1 items-center">
            <User width={16} height={16} />

            <div className="font-bold text-lg">{users}</div>
          </div>
        </div>
        {/* Beneficiaries */}
        <div
          className={`flex flex-col justify-between rounded-lg w-full ${
            activeCurrentCamp?.campId === camp?._id
              ? "bg-[#DDF1FE]"
              : "bg-[#F4FBFF]"
          }`}
        >
          {/* Beneficiaries card */}
          <div className="p-3">
            <div className="flex justify-between gap-1 items-center rounded-lg w-full mb-2">
              {/* card-heading */}
              <div className="text-base font-bold">Beneficiaries</div>
              <div className="flex justify-between gap-1 items-center">
                <div
                  className={`h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center min-w-[24px] w-[24px] min-h-[24px] ${
                    campFlows === camp?._id ? "rotate-180" : "rotate-90"
                  }`}
                  onClick={() => {
                    setCampFlows((prevCampId) =>
                      prevCampId === campData?.camp?._id
                        ? null
                        : campData?.camp?._id
                    );
                  }}
                >
                  <ArrowIcon
                    width={6}
                    height={10}
                    isRotated={campFlows === camp?._id}
                    fillColor={"#ffffff"}
                  />
                </div>
              </div>
            </div>

            {/* list beneficiary */}
            <div className="flex flex-col justify-between gap-4 items-center rounded-lg w-full">
              <div className="flex flex-row justify-between items-center w-full">
                <StepWithCountsComponent
                  data={{ name: "Scheduled", CountNumber: counts?.scheduled }}
                  colors={{ text: "#FF5976", background: "#F5E7EE" }}
                />
                <StepWithCountsComponent
                  data={{ name: "Checked-in", CountNumber: counts?.checkedIn }}
                  colors={{ text: "#256CD0", background: "#DDF1FE" }}
                />
              </div>
              <div className="flex flex-row justify-between items-center w-full">
                <StepWithCountsComponent
                  data={{
                    name: "In Progress",
                    CountNumber: counts?.inProgress,
                  }}
                  colors={{ text: "#FAAE00", background: "#F5EFD6" }}
                />
                <StepWithCountsComponent
                  data={{
                    name: "Checked-out",
                    CountNumber: counts?.checkedOut,
                  }}
                  colors={{ text: "#04CF3D", background: "#D6F5E7" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentCampCardComponent;
