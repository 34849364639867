/**
 * Beneficiary Detail Components
 * @format
 */

import React from "react";
import { Link } from "react-router-dom";
import { Email } from "../../../assets/icons/Email";
import { Telephone } from "../../../assets/icons/Call";
import { constants } from "../../../utils";

const BeneficiaryDetailWithCurrentStatus = (props) => {
  const beneficiaryData = props.userData;

  return (
    <>
      <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
        <div className="container bg-white p-0  mb-3 font-DM">
          <div className="flex flex-1 justify-between w-full items-center leading-normal text-xs mb-4 flex-wrap ">
            <div className="">
              <span className="text-gray-tone-600 font-bold text-lg font-raleway">
                Beneficiary details
              </span>{" "}
            </div>
            <div className="py-2 px-3 text-center bg-[var(--hot-pink)] rounded-2xl">
              <span className=" text-white font-bold text-sm font-raleway">
                Current Stage:{" "}
                {beneficiaryData?.current_status
                  ? beneficiaryData?.current_status?.status_display_name
                  : "Registered"}
              </span>
            </div>
          </div>
          <div className="container flex flex-row justify-between gap-6 ">
            {/* <div className=""> */}
            <img
              src={beneficiaryData?.profile_picture}
              alt=""
              className=" rounded-xl min-w-[80px] w-[80px] max-h-[80px] h-[80px]"
            />
            {/* </div> */}
            <div className="flex w-full flex-wrap justify-between gap-[13px]">
              <div className="flex flex-col justify-between items-start">
                <div className="font-semibold text-base capitalize font-raleway">
                  {beneficiaryData?.name}{" "}
                  <span className="text-gray-500 font-semibold">
                    {" "}
                    <span className="text-gray-tone-divider">|</span> Age:
                  </span>{" "}
                  {beneficiaryData?.age}; {beneficiaryData?.gender}
                </div>
                <div className="flex flex-col justify-between w-full gap-[0px] ">
                  <div className=" text-gray-600 font-semibold text-sm ">
                    <span>Registration No: </span>{" "}
                    <span className="text-black">
                      {beneficiaryData?.registration_number}
                    </span>
                  </div>
                  <div className=" text-gray-600 font-semibold text-sm">
                    <span>Disability Type: </span>{" "}
                    <span className="text-black">
                      {beneficiaryData?.disability_type_id?.name}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between gap-[10px]">
                <div className="flex flex-col justify-between text-xs gap-[7]">
                  <div className="text-left flex items-center text-base">
                    <span className="mr-2">
                      <Email width={20} height={20} />
                    </span>
                    <span className="overflow-scroll scrollbar-hide font-[600] font-DM">
                      {beneficiaryData?.email}
                    </span>
                  </div>
                  <div className="flex items-center text-base">
                    <span className="text-gray-500 mr-2">
                      <Telephone width={20} height={20} />
                    </span>
                    <span className="font-medium font-DM whitespace-nowrap text-activeLink">
                      +91 {beneficiaryData?.mobile_number}
                    </span>
                  </div>
                  <div className="flex  text-base">
                    <div className="text-green-600 ">
                      {/* <Link
                        to="/your-link-url"
                        className="text-activeLink underline ml-2 font-medium text-sm font-raleway"
                      >
                        Communication History
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryDetailWithCurrentStatus;
