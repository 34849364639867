/**
 * beneficiary Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { endPoints, httpRequest } from "../../services";
import {
  allBeneficiaryDetailList,
  allBeneficiaryDetailListByStatus,
  allBeneficiaryDetailListForAssessment,
  allBeneficiaryDetailListForDistribution,
  allBeneficiaryDetailListForFabrication,
  allUpcomingAssessmentCamps,
  beneficiariesAssignToMe,
  beneficiaryFormUpload,
  getAllUpcomingDistributionCamps,
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  getUploadedFormByBeneficiaryId,
  scheduleOrReScheduleBeneficiaryOfCamp,
  setAllBeneficiaryDetailDataList,
  setAllBeneficiaryDetailListForAssessments,
  setAllBeneficiaryDetailListForDistribution,
  setAllBeneficiaryDetailListForFabrication,
  setAllUpcomingAssessmentCampsDataList,
  setAllUpcomingDistributionCampsDataList,
  setBeneficiaryDetails,
  setBeneficiaryProcessDetail,
  setBeneficiaryUploadedForm,
  updateBeneficiaryJourneyByBeneficiaryId,
  updateVerifyBeneficiary,
  verifyBeneficiary,
} from "./slice";
import { setFullScreenLoading } from "../common";

/**
 * Get all Beneficiary Detail list
 * @param {*}
 */
function* allBeneficiaryDetailListSaga(action) {
  const {
    search,
    page,
    stateId,
    districtId,
    status,
    perPage,
    onSuccess,
    onFailure,
    filter,
  } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsList,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailDataList(response?.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Beneficiary list For Assessment
 * @param {*}
 */
function* allBeneficiaryDetailListForAssessmentSaga(action) {
  const {
    search,
    page,
    stateId,
    districtId,
    status,
    perPage,
    onSuccess,
    filter,
    onFailure,
  } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListAssessment,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailListForAssessments(response?.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list Fro Fabrication
 * @param {*}
 */
function* allBeneficiaryDetailListForFabricationSaga(action) {
  const {
    search,
    page,
    stateId,
    districtId,
    status,
    perPage,
    onSuccess,
    filter,
    onFailure,
  } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListFabrication,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailListForFabrication(response?.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list For Distribution
 * @param {*}
 */
function* allBeneficiaryDetailListForDistributionSaga(action) {
  const {
    search,
    page,
    stateId,
    districtId,
    status,
    perPage,
    onSuccess,
    filter,
    onFailure,
  } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListDistribution,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
        startDate: filter.startDate,
        endDate: filter.endDate,
        pinCode: filter.pinCode,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailListForDistribution(response?.data));
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Beneficiary Detail list By status
 * @param {*}
 */
function* allBeneficiaryDetailListByStatusSaga(action) {
  try {
    const { search, page, stateId, districtId, status, perPage, onSuccess } =
      action.payload;
    const response = yield httpRequest.post(
      endPoints.allBeneficiaryDetailsListStatus,
      {
        search,
        state: stateId,
        district: districtId,
        status,
        page,
        responsePerPage: perPage,
      }
    );
    yield onSuccess({ message: response?.message });
    yield put(setAllBeneficiaryDetailDataList(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get Beneficiary Detail By Id
 * @param {*} Id
 */
function* getBeneficiaryDetailByIdSaga(action) {
  const { id, onSuccess, onFailure } = action.payload;
  try {
    // yield put(setFullScreenLoading(true));
    const response = yield call(
      httpRequest.get,
      `${endPoints.getBeneficiaryDetailById}/${id}`
    );
    yield onSuccess({ message: response?.message });
    yield put(setBeneficiaryDetails(response?.data));
    // yield put(setFullScreenLoading(false));
    if (response?.data?.status === "Pending") {
      // yield put(setFullScreenLoading(false));
      yield put(setBeneficiaryProcessDetail({}));
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield put(setFullScreenLoading(false));
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get Beneficiary Detail By Id
 * @param {*} Id
 */
function* getBeneficiaryProcessDataByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.beneficiaryJourneyByBeneficiaryId}/${id}`
    );
    yield onSuccess({ message: response?.message, data: response?.data });
    yield put(setBeneficiaryProcessDetail(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Beneficiary Verification By User
 * @param {*} Id
 */
function* verifyBeneficiarySaga(action) {
  const { values, onSuccess, onFailure } = action.payload;
  try {
    const { beneficiary_id, beneficiary_stage_id, notes, call_status, status } =
      values;
    const verification = {
      beneficiary_stage_id,
      status_id: status,
      data: [{ call_status, notes }],
    };
    const response = yield httpRequest.post(endPoints.verifyBeneficiaryData, {
      beneficiary_id,
      beneficiary_status: verification,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Beneficiary Verification Update By User
 * @param {*} Id
 */
function* verifyUpdateBeneficiarySaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const { beneficiary_id, beneficiary_stage_id, notes, call_status, status } =
      values;
    const verification = {
      beneficiary_stage_id,
      status_id: status,
      data: [{ call_status, notes }],
    };
    const response = yield httpRequest.post(
      endPoints.updateVerifyBeneficiaryData,
      {
        beneficiary_id,
        beneficiary_status: verification,
      }
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Upcomming Assessments Camps List
 * @param {*} Id
 */
function* allUpcomingAssessmentCampsSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.upcomingAssessmentCampList
    );
    yield put(setAllUpcomingAssessmentCampsDataList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Upcomming Distribution Camps List
 * @param {*} Id
 */
function* allUpcomingDistributionCampsSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.post(
      endPoints.upcomingDistributionCampList
    );
    yield put(setAllUpcomingDistributionCampsDataList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * schedule Or ReSchedule Beneficiary Of Camp
 * @param {*}
 */
function* scheduleOrReScheduleBeneficiaryOfCampSaga(action) {
  try {
    const {
      old_scheduled_camp_id,
      scheduled_camp_id,
      beneficiary_id,
      onSuccess,
    } = action.payload;
    const response = yield httpRequest.post(endPoints.scheduleOrReSheduleCamp, {
      old_scheduled_camp_id,
      scheduled_camp_id,
      beneficiary_id,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * update Beneficiary Journey By Beneficiary Id Saga
 * @param {*}
 */
function* updateBeneficiaryJourneyByBeneficiaryIdSaga(action) {
  try {
    yield put(setFullScreenLoading(true));
    const { updatedValues, onSuccess } = action.payload;
    const { beneficiary_status, beneficiary_id } = updatedValues;

    const response = yield httpRequest.put(endPoints.updateBeneficiaryJourney, {
      beneficiary_id,
      beneficiary_status,
    });
    yield put(setFullScreenLoading(false));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    yield put(setFullScreenLoading(false));
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * update Beneficiary Journey By Beneficiary Id Saga
 * @param {*}
 */
function* beneficiaryFormUploadSaga(action) {
  try {
    const {
      beneficiary_form,
      beneficiary_id,
      scheduled_camp_id,
      camp_step_id,
      onSuccess,
    } = action.payload;
    let formData = new FormData();
    formData.append("beneficiary_form", beneficiary_form);
    formData.append("beneficiary_id", beneficiary_id);
    formData.append("scheduled_camp_id", scheduled_camp_id);
    formData.append("camp_step_id", camp_step_id);

    // Make the API request with custom headers
    const response = yield httpRequest.post(endPoints.formUpload, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield onSuccess({ message: response?.message, data: response?.data });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * update Beneficiary Journey By Beneficiary Id Saga
 * @param {*}
 */
function* getUploadedFormByBeneficiaryIdSaga(action) {
  try {
    const { beneficiary_id, scheduled_camp_id, camp_step_id, onSuccess } =
      action.payload;

    // const response = yield call(
    //   httpRequest.get,
    //   `${endPoints.getUploadedForm}/${beneficiary_id}`
    // );
    const response = yield httpRequest.post(endPoints.getUploadedForm, {
      beneficiary_id,
      scheduled_camp_id,
      camp_step_id,
    });
    yield put(setBeneficiaryUploadedForm(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Wating Beneficiary of other desk  Assign to self Saga
 * @param {*}
 */
function* beneficiariesAssignToMeSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const {
      scheduled_camp_id,
      camp_step_id,
      beneficiary_ids,
      camp_counter_id,
    } = values;

    const response = yield httpRequest.post(endPoints.beneficiayAssignToSelf, {
      scheduled_camp_id,
      camp_step_id,
      camp_counter_id,
      beneficiary_ids,
    });
    yield onSuccess({ message: response?.message });
  } catch (error) {
    // toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* beneficiaryDetailManagementSaga() {
  yield takeLatest(allBeneficiaryDetailList, allBeneficiaryDetailListSaga);
  yield takeLatest(
    allBeneficiaryDetailListForAssessment,
    allBeneficiaryDetailListForAssessmentSaga
  );
  yield takeLatest(
    allBeneficiaryDetailListForFabrication,
    allBeneficiaryDetailListForFabricationSaga
  );
  yield takeLatest(
    allBeneficiaryDetailListForDistribution,
    allBeneficiaryDetailListForDistributionSaga
  );
  yield takeLatest(
    allBeneficiaryDetailListByStatus,
    allBeneficiaryDetailListByStatusSaga
  );
  yield takeLatest(getBeneficiaryDetailById, getBeneficiaryDetailByIdSaga);
  yield takeLatest(verifyBeneficiary, verifyBeneficiarySaga);
  yield takeLatest(
    getBeneficiaryProcessDetails,
    getBeneficiaryProcessDataByIdSaga
  );
  yield takeLatest(updateVerifyBeneficiary, verifyUpdateBeneficiarySaga);
  yield takeLatest(allUpcomingAssessmentCamps, allUpcomingAssessmentCampsSaga);
  yield takeLatest(
    getAllUpcomingDistributionCamps,
    allUpcomingDistributionCampsSaga
  );
  yield takeLatest(
    scheduleOrReScheduleBeneficiaryOfCamp,
    scheduleOrReScheduleBeneficiaryOfCampSaga
  );
  yield takeLatest(
    updateBeneficiaryJourneyByBeneficiaryId,
    updateBeneficiaryJourneyByBeneficiaryIdSaga
  );
  yield takeLatest(beneficiaryFormUpload, beneficiaryFormUploadSaga);
  yield takeLatest(
    getUploadedFormByBeneficiaryId,
    getUploadedFormByBeneficiaryIdSaga
  );
  yield takeLatest(beneficiariesAssignToMe, beneficiariesAssignToMeSaga);
}
