/**
 * Auth Routers
 * @format
 */

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { AuthRoutes } from "../constants";
import { useSelector } from "react-redux";
import { selectLogOutLoading } from "../pages/common";
import { Loader } from "../components";

export const AuthChecker = (props) => {
  const { token } = props;
  console.log(props, "token");
  const location = useLocation();
  const loader = useSelector(selectLogOutLoading);

  return token ? (
    loader ? (
      <div className="flex items-center justify-center fixed inset-0 bg-[#fafbfc] opacity-80">
        <Loader />
      </div>
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={AuthRoutes.LoginScreen} state={{ from: location }} replace />
  );
};
