import { io } from "socket.io-client";

const URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:5000/"
    : process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SOCKET_URL
    : undefined;

export const socket = io(URL, {
  autoConnect: false,
});

export const connectSocket = () => {
  if (!socket.connected) {
    socket.connect();

    socket.on("connect", () => {
      console.log("Connected to server:", socket.id);
    });

    socket.on("reconnect", (attemptNumber) => {
      console.log(`Reconnected to server after ${attemptNumber} attempts`);
    });

    socket.on("reconnect_error", (error) => {
      console.error("Reconnection error:", error);
    });

    socket.on("reconnect_failed", () => {
      console.error("Failed to reconnect after maximum attempts");
    });
  }
};

export const disconnectSocket = () => {
  if (socket.connected) {
    socket.disconnect();

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });
  }
};
