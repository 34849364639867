/**
 * View Map link Component
 * @format
 */

import React from "react";

const ViewMapLink = ({ geo_coordinates }) => {
  // Construct the Google Maps URL with latitude and longitude
  const mapUrl = `https://www.google.com/maps?q=${geo_coordinates}`;

  return (
    <div
      className="text-[#256CD0] font-semibold underline"
      onClick={() => window.open(mapUrl, "_blank")}
    >
      View Map
    </div>
  );
};

export default ViewMapLink;
