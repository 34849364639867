/**
 * View All Next Count Details Model
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Cross } from "../../../assets/icons/Cross";
import { Formik } from "formik";
import BeneficiaryJourney from "../../beneficiary_management/components/beneficiaryJourney";
import PersonalDetails from "../../beneficiary_management/components/personalDetails";
import BeneficiaryAllDetail from "../../beneficiary_management/components/beneficiaryAllDetail";
import { searchBeneficiary } from "../slice";
import BeneficiaryDetailWithCurrentStatus from "../../beneficiary_management/components/beneficiaryDetailWithCurrentStatus";
import { selectSearchBeneficiaryDetails } from "../selector";
import { AssessmentReadOnlyComponent } from "../../beneficiary_management/components/assessmentCard";
import { FabricationReadOnlyComponent } from "../../beneficiary_management/components/fabricationCard";
import { formatDate, manipulateDateAndTime } from "../../../utils";
import BeneficiaryVerificationPart from "../../beneficiary_management/components/beneficiaryVerificationForm";
import { Loader } from "../../../components";

const LookUpBeneficaryModels = ({ toggleModal }) => {
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [dateTime, setDateTime] = useState({});
  const scrollableRef = useRef(null);
  const {
    beneficiaryDetails: beneficiaryData = {},
    beneficiaryJourney,
    campData,
  } = useSelector(selectSearchBeneficiaryDetails) || {};
  const dispatch = useDispatch();

  const initialValues = {
    search: "",
  };

  // handel journey stpes
  const [expandedSteps, setExpandedSteps] = useState({
    registration: false,
    verification: false,
    assessment: false,
    fabrication: false,
    distribution: false,
  });
  const toggleStep = (step) => {
    setExpandedSteps({
      ...expandedSteps,
      [step]: !expandedSteps[step],
    });
  };

  const activeCampFlow = campData?.assessment?.[0]?.step?.find(
    (item) => item.is_active === true
  );

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };

  // assessment data status managae
  const assessment_last_Status =
    beneficiaryJourney?.stages?.assessments?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.assessments?.data[0] // Correct reference here
    );

  // Distribution data status manage
  const distribution_last_Status =
    beneficiaryJourney?.stages?.distribution?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.distribution?.data[0]
    );

  // Fabrication data status manage
  const fabrication_data =
    beneficiaryJourney?.stages?.fabrication?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.fabrication?.data[0] // Correct reference here
    );

  const fabrication_Started_data =
    beneficiaryJourney?.stages?.fabrication?.data?.find(
      (item) => item.status_id.status_name === "fabrication_in_progress"
    );

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      campScheduled: "",
      campComplete: `Completed on ${dateTime.date}; ${dateTime.time}`,
      campNotSchedled: "",
      lastStatus: "complete",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={beneficiaryData}
          userVerificationData={latest_data}
        />
      ),
      campScheduled: "",
      campNotSchedled: "",
      campComplete: `Completed on ${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
      lastStatus: "complete",
    },
    {
      name: "Assessment",
      component: (
        <AssessmentReadOnlyComponent
          campSteps={activeCampFlow?.camp_flow_steps}
          campData={campData?.assessment}
          beneficiary_id={beneficiaryData?._id}
          componentName={"assessment"}
        />
      ),
      ReSchedledButton: false,
      schedledButton: false,
      campScheduled:
        assessment_last_Status?.status_id?.status_name ===
        "assessment_in_progress"
          ? campData?.assessment?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.assessment[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.assessment[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } (${
                campData?.assessment?.[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp Code : ${
                campData?.assessment?.[0]?.scheduled_camp_id?.camp_code
              })`
            : ""
          : "",
      campComplete:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? `Completed on ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.time
            } at ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } (${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp Code : ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_code
            })`
          : "",
      campNotSchedled: !campData?.assessment ? `Not yet scheduled` : "",
      disabled: false,
      lastStatus:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? "complete"
          : assessment_last_Status?.status_id?.status_name ===
            "assessment_in_progress"
          ? "in_progress"
          : "",
      descriptions: `Started on 23-01-2024; 11.25 AM`,
    },
    {
      name: "Fabrication",
      component: (
        <FabricationReadOnlyComponent
          beneficiary_id={beneficiaryData?._id}
          fabrication_data={fabrication_data || {}}
          fabricationDisabled={true}
        />
      ),
      campScheduled:
        fabrication_data?.status_id?.status_name ===
          "fabrication_in_progress" ||
        fabrication_data?.status_id?.status_name ===
          "fabrication_not_yet_started"
          ? `Started on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            } `
          : "",
      campComplete:
        fabrication_data?.status_id?.status_name === "fabrication_completed" ? (
          <div>
            {`Started on ${
              manipulateDateAndTime(fabrication_Started_data?.createdAt)?.date
            }; ${
              manipulateDateAndTime(fabrication_Started_data?.createdAt)?.time
            } By ${fabrication_data?.created_by?.name}`}
            <br />
            {`Completed on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            }`}
          </div>
        ) : (
          ""
        ),
      campNotSchedled: fabrication_data ? "" : "Not yet scheduled",
      disabled: false,
      lastStatus:
        fabrication_data?.status_id?.status_name === "fabrication_completed"
          ? "complete"
          : fabrication_data?.status_id?.status_name ===
            "fabrication_in_progress"
          ? "in_progress"
          : "",
    },
    {
      name: "Distribution",
      component: (
        <AssessmentReadOnlyComponent
          campSteps={
            campData?.distribution?.[0]?.step?.find(
              (item) => item.is_active === true
            )?.camp_flow_steps || []
          }
          campData={campData?.distribution}
          beneficiary_id={beneficiaryData?._id}
          componentName={"distribution"}
        />
      ),
      campScheduled:
        distribution_last_Status?.status_id?.status_name ===
          "distribution_in_progress" ||
        distribution_last_Status?.status_id?.status_name ===
          "distribution_scheduled"
          ? campData?.distribution?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.distribution[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.distribution[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } (${
                campData?.distribution?.[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp Code : ${
                campData?.distribution?.[0]?.scheduled_camp_id?.camp_code
              })`
            : ""
          : "",
      campComplete:
        distribution_last_Status?.status_id?.status_name ===
        "distribution_completed"
          ? `Completed on ${
              manipulateDateAndTime(distribution_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(distribution_last_Status?.createdAt)?.time
            } at ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } (${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp Code : ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_code
            })`
          : "",
      campNotSchedled: !campData?.distribution ? `Not yet scheduled` : "",
      ReSchedledButton: false,
      schedledButton: false,
      disabled: campData?.distribution ? false : true,
      lastStatus:
        distribution_last_Status?.status_id?.status_name ===
        "distribution_completed"
          ? "complete"
          : distribution_last_Status?.status_id?.status_name ===
            "distribution_in_progress"
          ? "in_progress"
          : "",
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    } else if (beneficiaryData?._id) {
      const result = manipulateDateAndTime(beneficiaryData?.createdAt);
      setDateTime(result);
    }
  }, [beneficiaryData]);

  return (
    <div className="fixed h-full inset-0 z-50 flex justify-end">
      <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
      <div
        data-aos-easing="linear"
        data-aos="fade-left"
        className="flex flex-col relative w-[55%] bg-white h-full shadow-lg overflow-y-auto scrollbar-hide"
      >
        <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
          <h2 className="text-xl font-bold">Lookup Beneficiary</h2>
          <button onClick={toggleModal} className="text-xl font-semibold">
            <Cross width={32} height={32} />
          </button>
        </div>
        {/* Modal content */}
        <div className="w-full flex flex-col flex-auto justify-start space-y-4 py-6 px-6">
          {/* Search Input fields */}
          <div className="container p-0 text-black">
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                search: Yup.string()
                  .required("Reg. Number Or Mobile Number are required")
                  .max(10, "cannot exceed 10 characters"),
              })}
              onSubmit={(values, actions) => {
                const { search } = values;
                setDataLoading(true);
                dispatch(
                  searchBeneficiary({
                    search,
                    onSuccess: (props) => {
                      setBeneficiaryDetailToggle(true);
                      setDataLoading(false);
                      actions.resetForm();
                    },
                    onFailure: (props) => {
                      setDataLoading(false);
                    },
                  })
                );
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit();
                    }
                  }}
                >
                  <div className="container p-0 flex justify-start items-center h-full">
                    <div className="flex flex-col gap-1.5 w-full">
                      <label className="text-sm font-bold">
                        Beneficiary Reg. no / Mobile no
                      </label>
                      <div className="relative h-[40px] rounded-xl w-full">
                        <button
                          type="submit"
                          className="absolute inset-y-0 end-6 flex text-sm items-center ps-5 h-full underline text-activeLink decoration-activeLink"
                        >
                          Lookup
                        </button>
                        <input
                          autoComplete="off"
                          type="text"
                          maxLength={10}
                          name="search"
                          id="search"
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value.toUpperCase(),
                              },
                            });
                          }}
                          value={values.search}
                          onBlur={handleBlur}
                          placeholder="Enter Here"
                          className="block w-full h-full p-4 text-sm font-DM rounded-xl search-bar border border-customBlue-border placeholder-black"
                        />
                        {touched.search && errors.search && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors.search}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>

          {/* Beneficiary Details */}
          <div className="flex justify-center items-center w-full overflow-y-auto scrollbar-hide px-0">
            {dataLoading ? (
              <>
                <div className="flex justify-center items-center container pr-4 pt-2 h-[700px] self-end w-full bg-white rounded-lg">
                  <Loader />
                </div>
              </>
            ) : (
              <>
                {beneficiaryDetailToggle &&
                Object.keys(beneficiaryData)?.length > 0 ? (
                  beneficiaryData?.status === "Verified" ? (
                    <div className="container p-0 h-full self-end w-full ">
                      <div
                        className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-4 py-4 max-w-full w-full rounded-xl"
                        // ref={scrollableRef}
                      >
                        {/* Beneficiary Detail */}
                        <BeneficiaryDetailWithCurrentStatus
                          userData={beneficiaryData}
                        />

                        {/* Personal Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                          <PersonalDetails userData={beneficiaryData} />
                        </div>
                        {/* Beneficiary Journey */}
                        <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                          <BeneficiaryJourney
                            beneficiary={beneficiaryData}
                            beneficiaryJourney={beneficiaryJourney}
                            steps={steps}
                            setExpandedSteps={setExpandedSteps}
                            expandedSteps={expandedSteps}
                            toggleStep={toggleStep}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <BeneficiaryAllDetail
                      singleBeneficiary={beneficiaryData}
                      data={beneficiaryData}
                      bySearch={true}
                    />
                  )
                ) : (
                  <div className="flex justify-center items-center container pr-4 pt-2 h-[700px] self-end w-full bg-white rounded-lg"></div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { LookUpBeneficaryModels };
