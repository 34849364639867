/**
 * Page Not found Component
 * @format
 */

import React from "react";
import { HeaderScreen } from "./header";
import { Sidebar } from "./sidebar/sidebar";
import { Link } from "react-router-dom";

export default function Custom404() {
  return (
    <>
      <div className="h-screen min-w-screen relative">
        <HeaderScreen />
        <div className="flex h-[calc(100vh-80px)]">
          <Sidebar />
          <div className="flex flex-col h-full bg-customBlue-mainBackground w-[100%] overflow-auto">
            <div className="flex flex-col items-center justify-center h-full bg-gray-100">
              <span className="text-[150px] h-fit m-0 p-0 font-bold text-blue-600">
                404
              </span>
              <h2 className="mt-0 text-6xl font-bold text-gray-800">
                Page Not Found
              </h2>
              <p className="mt-8 text-3xl text-gray-600">
                Sorry, we couldn&apos;t find the page you&apos;re looking for.
              </p>
              <Link to="/">
                <button className="mt-8 text-3xl text-activeLink hover:underline">
                  Go back home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
