/**
 * Common slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  fullScreenLoading: false,
  logOutLoading: false,
  user: null,
  activeSection: null,
  authToken: null,
  toast: null,
  district: {},
  state: {},
  userType: [],
  status: [],
  stages: [],
  campTypes: [],
  userProfileData: {},
  userAllPermissions: {},
  allCampStages: [],
  allCampStepTypes: [],
  districtWithBeneficiaryCounts: {},
  stateWithBeneficiaryCounts: {},
  districtWithBeneficiaryCountsForDistribution: {},
  stateWithBeneficiaryCountsForDistribution: {},
  allUserOptions: {},
  districtByState: [],
  campUserCounterData: {},
  socketConnected: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    changeAppSection(state, action) {
      state.activeSection = action.payload;
    },
    setAuthToken(state, action) {
      state.authToken = action.payload;
    },
    presentLoader(state) {
      state.loader = true;
    },
    dismissLoader(state) {
      state.loader = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setDistrict(state, action) {
      state.district = action.payload;
    },
    setState(state, action) {
      state.state = action.payload;
    },
    setUserType(state, action) {
      state.userType = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setStages(state, action) {
      state.stages = action.payload;
    },
    setCampTypes(state, action) {
      state.campTypes = action.payload;
    },
    setUserProfile(state, action) {
      state.userProfileData = action.payload;
    },
    setUserAllPermissions(state, action) {
      state.userAllPermissions = action.payload;
    },
    setCampAllStages(state, action) {
      state.allCampStages = action.payload;
    },
    setCampAllStepTypes(state, action) {
      state.allCampStepTypes = action.payload;
    },
    setDistrictWithBeneficiaryCounts(state, action) {
      state.districtWithBeneficiaryCounts = action.payload;
    },
    setStateWithBeneficiaryCounts(state, action) {
      state.stateWithBeneficiaryCounts = action.payload;
    },
    setDistrictWithBeneficiaryCountsForDistribution(state, action) {
      state.districtWithBeneficiaryCountsForDistribution = action.payload;
    },
    setStateWithBeneficiaryCountsForDistribution(state, action) {
      state.stateWithBeneficiaryCountsForDistribution = action.payload;
    },
    setAllUserOptions(state, action) {
      state.allUserOptions = action.payload;
    },
    setDistrictByState(state, action) {
      state.districtByState = action.payload;
    },
    setFullScreenLoading(state, action) {
      state.fullScreenLoading = action.payload;
    },
    setLogOutLoading(state, action) {
      state.logOutLoading = action.payload;
    },
    setCampUserCounterData(state, action) {
      state.campUserCounterData = action.payload;
    },
    setSocketConnected(state, action) {
      state.socketConnected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutApp, () => {
      return initialState;
    });
  },
});

// Reducer
export const commonReducer = commonSlice.reducer;

// Actions
export const {
  presentLoader,
  dismissLoader,
  setUser,
  setAuthToken,
  changeAppSection,
  setDistrict,
  setState,
  setUserType,
  setStatus,
  setStages,
  setCampTypes,
  setUserProfile,
  setUserAllPermissions,
  setCampAllStages,
  setCampAllStepTypes,
  setDistrictWithBeneficiaryCounts,
  setStateWithBeneficiaryCounts,
  setDistrictWithBeneficiaryCountsForDistribution,
  setStateWithBeneficiaryCountsForDistribution,
  setAllUserOptions,
  setDistrictByState,
  setFullScreenLoading,
  setLogOutLoading,
  setCampUserCounterData,
  setSocketConnected,
} = commonSlice.actions;

// Other Actions
export const setUserRole = createAction("COMMON/SETUSERROLE");
export const logoutApp = createAction("COMMON/LOGOUT");
export const getAllDistrict = createAction("COMMON/GETALLDISTRICT");
export const getAllState = createAction("COMMON/GETALLSTATE");
export const getAllUserType = createAction("COMMON/GETALLUSERTYPE");
export const getAllStatus = createAction("COMMON/GETALLSTATUS");
export const getAllStages = createAction("COMMON/GETALLSTAGES");
export const getAllCampTypes = createAction("COMMON/GETALLCAMPTYPES");
export const getUserProfile = createAction("COMMON/GETUSERPROFILE");
export const getCampAllStages = createAction("COMMON/GETCAMPSTAGES");
export const getCampAllStepTypes = createAction("COMMON/GETALLCAMPSTEPTYPE");
export const getDistrictByStateId = createAction("COMMON/GETDISTRICTBYSTATEID");
export const logOutApi = createAction("COMMON/LOGOUTAPI");
export const getDistrictsWithBeneficiariesCount = createAction(
  "COMMON/GETDISTRICTWITHBENEFICIARIES"
);
export const getStatesWithBeneficiariesCount = createAction(
  "COMMON/GETSTATEWITHBENEFICIARIES"
);
export const getDistrictsWithBeneficiariesCountForDistribution = createAction(
  "COMMON/GETDISTRICTWITHBENEFICIARIESFORDISTRIBUTION"
);
export const getStatesWithBeneficiariesCountForDistribution = createAction(
  "COMMON/GETSTATEWITHBENEFICIARIESFORDISTRIBUTION"
);
export const getallUsersOptions = createAction("COMMON/GETALLUSERSOPTIONS");
export const checkTokenForAuthorization = createAction(
  "COMMON/CHECKTOKENTFORAUTHORIZATION"
);

// Create loader
export const createLoader = () => {
  return {
    present: () => presentLoader(),
    dismiss: () => dismissLoader(),
  };
};
