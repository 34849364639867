/**
 * Schedule Camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { CampFields } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { Cross } from "../../../assets/icons/Cross";
import { Formik } from "formik";
import SelectDropDown from "../../../components/selectDropDown";
import TextAreaWithEditorComponent from "../../../components/textAreaWithEditor";
import {
  getAllCampTypes,
  getAllState,
  selectCampTypes,
  selectState,
} from "../../common";
import {
  getCampCenterByStateId,
  getScheduleCampById,
  updateScheduleCampById,
} from "../slice";
import {
  convertTo12HourFormat,
  convertTo24HourFormat,
  formatDateYearMonthDay,
  isPastCamp,
  parseFullDate,
  todayDate,
} from "../../../utils";
import { toast } from "react-toastify";
import {
  selectCampCenterOptionsByState,
  selectScheduleCamp,
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
} from "../selector";
import SingleDatePickerComponent from "../../../components/SingleDatePicker";
import CustomButton from "../../../components/customButtion";
import SingleTimePicker from "../../../components/singleTimePicker";

const EditScheduleCampModel = ({ toggleModal, ScheduleCampId, getData }) => {
  const dispatch = useDispatch();

  const initialValues = {
    [CampFields.STATE]: "",
    [CampFields.CAMP_CENTER]: "",
    [CampFields.CAMP_CODE]: "",
    [CampFields.CAMP_TYPE]: "",
    [CampFields.MAX_NUMBER]: "",
    [CampFields.INSTRUCTIONS]: "",
    [CampFields.START_TIME]: "",
    [CampFields.END_TIME]: "",
    [CampFields.START_DATE]: "",
    [CampFields.END_DATE]: "",
    [CampFields.NOTES]: "",
  };

  const [initialObject, setInitialObject] = useState(initialValues);

  // Apis Data
  const scheduleCamp = useSelector(selectScheduleCamp) || {};
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampFilter = useSelector(selectScheduleCampFilter);

  const today = todayDate();

  const validationSchema = Yup.object().shape({
    [CampFields.START_DATE]: Yup.string().required("Required"),
    [CampFields.STATE]: Yup.string().required("Required"),
    [CampFields.CAMP_CENTER]: Yup.string().required("Required"),
    [CampFields.CAMP_TYPE]: Yup.string().required("Required"),
    [CampFields.MAX_NUMBER]: Yup.string().required("Required"),
    [CampFields.START_DATE]: Yup.date().required("Required"),
    [CampFields.START_TIME]: Yup.string()
      .required("Required")
      .test(
        "is-after-current-time",
        "Start time must be after the current time.",
        function (value) {
          if (!value) return false;

          const selectedDate = this.parent[CampFields.START_DATE];

          const isSameDay =
            selectedDate?.getFullYear() === today?.getFullYear() &&
            selectedDate?.getMonth() === today?.getMonth() &&
            selectedDate?.getDate() === today?.getDate();

          if (isSameDay) {
            const [hours, minutes] = value?.split(":").map(Number);

            return (
              hours > today?.getHours() ||
              (hours === today?.getHours() && minutes > today?.getMinutes())
            );
          }
          return true;
        }
      ),
    [CampFields.END_TIME]: Yup.string()
      .required("Required")
      .test(
        "is-after-start-time",
        "End time must be after the start time.",
        function (value) {
          const { from_time } = this.parent;

          if (!from_time || !value) return false;

          const [startHours, startMinutes] = from_time.split(":").map(Number);
          const [endHours, endMinutes] = value.split(":").map(Number);

          const startTimeInMinutes = startHours * 60 + startMinutes;
          const endTimeInMinutes = endHours * 60 + endMinutes;

          return endTimeInMinutes > startTimeInMinutes;
        }
      ),
    [CampFields.NOTES]: Yup.string().optional(),
  });

  const { data: state = [] } = useSelector(selectState) || {};
  const stateOptions = state?.map((item) => ({
    label: item?.state_name,
    value: item?._id,
  }));

  const campTypes = useSelector(selectCampTypes) || {};
  const campTypeOptions = campTypes?.map((item) => ({
    label: item?.camp_type_display_name,
    value: item?._id,
  }));

  const { data: campCenters = [] } =
    useSelector(selectCampCenterOptionsByState) || {};
  const campCentersOptions = campCenters?.map((item) => ({
    label: item?.camp_location_name,
    value: item?._id,
  }));

  //   Functions
  const getState = () => {
    dispatch(
      getAllState({
        // state_name: "",
        // iso_code: "",
        onSuccess: (props) => {},
      })
    );
  };

  const getCampType = () => {
    dispatch(
      getAllCampTypes({
        search: "",
        onSuccess: (props) => {},
      })
    );
  };

  const getCampCenters = (id) => {
    dispatch(
      getCampCenterByStateId({
        stateId: id,
        onSuccess: (props) => {},
      })
    );
  };

  const getScheduleCamp = (id) => {
    dispatch(
      getScheduleCampById({
        id,
        onSuccess: (props) => {},
      })
    );
  };

  useEffect(() => {
    if (scheduleCamp) {
      setInitialObject({
        ...scheduleCamp,
        [CampFields.CAMP_CENTER]: scheduleCamp?.camp_location_id?._id || "",
        [CampFields.CAMP_TYPE]: scheduleCamp?.camp_type_id?._id || "",
        [CampFields.STATE]: scheduleCamp?.state?._id || "",
        [CampFields.START_TIME]:
          convertTo24HourFormat(scheduleCamp?.from_time) || "",
        [CampFields.END_TIME]:
          convertTo24HourFormat(scheduleCamp?.to_time) || "",
        [CampFields.START_DATE]:
          formatDateYearMonthDay(scheduleCamp?.from_date) || "",
        [CampFields.END_DATE]:
          formatDateYearMonthDay(scheduleCamp?.to_date) || "",
      });
      if (scheduleCamp?.state?._id) {
        getCampCenters(scheduleCamp?.state?._id);
      }
    }
  }, [scheduleCamp]);

  useEffect(() => {
    if (ScheduleCampId) {
      getScheduleCamp(ScheduleCampId);
    }
    getState();
    getCampType();
  }, [ScheduleCampId]);
  return (
    <>
      <Formik
        initialValues={initialObject}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const updatedValues = {
            ...values,
            id: ScheduleCampId,
            from_time: convertTo12HourFormat(values?.from_time),
            to_time: convertTo12HourFormat(values?.to_time),
          };

          dispatch(
            updateScheduleCampById({
              values: updatedValues,
              onSuccess: (props) => {
                toast.success(props?.message);
                toggleModal();
                getData(
                  "",
                  scheduleCampFilter,
                  scheduleCampCurrentPage,
                  scheduleCampPerPage
                );
              },
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className="fixed h-full inset-0 z-50 flex justify-end">
              <div className="fixed inset-0 bg-[#07284B] opacity-50"></div>
              <div
                data-aos-easing="linear"
                data-aos="fade-left"
                className="flex flex-col relative w-[50%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
              >
                <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-6">
                  <h2 className="text-xl font-bold">Schedule Camp</h2>
                  <button
                    onClick={toggleModal}
                    className="text-xl font-semibold"
                  >
                    <Cross width={32} height={32} />
                  </button>
                </div>
                {/* Modal content */}
                <div className="flex flex-auto flex-col h-[calc(100vh-80px)] justify-between px-4 py-4 w-full ">
                  <div
                    className={
                      "w-full flex flex-col space-y-3 mb-4 overflow-auto scrollbar-hide px-4 h-full"
                    }
                  >
                    <div className="w-full">
                      <label className="text-sm font-bold">State</label>
                      <SelectDropDown
                        name={CampFields.STATE}
                        options={stateOptions}
                        value={
                          stateOptions?.find(
                            (item) => item?.value === values[CampFields.STATE]
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(CampFields.STATE, selectedAccess);
                          getCampCenters(selectedAccess);
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={false}
                        disabled={isPastCamp(scheduleCamp)}
                      />
                      {touched[CampFields.STATE] &&
                        errors[CampFields.STATE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.STATE]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Camp Center</label>
                      <SelectDropDown
                        name={CampFields.CAMP_CENTER}
                        options={campCentersOptions}
                        value={
                          campCentersOptions?.find(
                            (item) =>
                              item?.value === values[CampFields.CAMP_CENTER]
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(CampFields.CAMP_CENTER, selectedAccess);
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={true}
                        disabled={isPastCamp(scheduleCamp)}
                      />
                      {touched[CampFields.CAMP_CENTER] &&
                        errors[CampFields.CAMP_CENTER] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.CAMP_CENTER]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Camp Code</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampFields.CAMP_CODE}
                        id={CampFields.CAMP_CODE}
                        onChange={handleChange}
                        value={values[CampFields.CAMP_CODE]}
                        onBlur={handleBlur}
                        disabled={true}
                        className="w-full h-[44px] mt-1 p-2 font-DM border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />

                      {touched[CampFields.CAMP_CODE] &&
                        errors[CampFields.CAMP_CODE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.CAMP_CODE]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Camp Type</label>
                      <SelectDropDown
                        name={CampFields.CAMP_TYPE}
                        options={campTypeOptions}
                        value={
                          campTypeOptions?.find(
                            (item) =>
                              item?.value === values[CampFields.CAMP_TYPE]
                          ) || null
                        }
                        onChange={(selectedOption) => {
                          const selectedAccess = selectedOption?.value || "";
                          setFieldValue(CampFields.CAMP_TYPE, selectedAccess);
                        }}
                        touched={touched}
                        errors={errors}
                        placeholder={"Select"}
                        isSearchable={true}
                        isClearable={true}
                        disabled={isPastCamp(scheduleCamp)}
                      />
                      {touched[CampFields.CAMP_TYPE] &&
                        errors[CampFields.CAMP_TYPE] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.CAMP_TYPE]}
                          </div>
                        )}
                    </div>

                    <div className="w-full">
                      <label className="text-sm font-bold">Max Number</label>
                      <input
                        autoComplete="off"
                        type="text"
                        name={CampFields.MAX_NUMBER}
                        id={CampFields.MAX_NUMBER}
                        onChange={handleChange}
                        value={values[CampFields.MAX_NUMBER]}
                        onBlur={handleBlur}
                        disabled={isPastCamp(scheduleCamp)}
                        className="w-full h-[44px] mt-1 p-2 border-2 border-customBlue-border rounded-[12px] focus:outline-none focus:ring-2 focus:ring-customBlue-hover"
                      />
                      {touched[CampFields.MAX_NUMBER] &&
                        errors[CampFields.MAX_NUMBER] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.MAX_NUMBER]}
                          </div>
                        )}
                    </div>

                    <div className="w-full grid grid-flow-col grid-cols-12 gap-4">
                      <div className="col-span-4">
                        <label className="text-sm font-bold block">Date</label>
                        <SingleDatePickerComponent
                          name={`date`}
                          value={values[CampFields.START_DATE]}
                          onChange={(date) => {
                            setFieldValue(
                              CampFields.START_DATE,
                              parseFullDate(date)
                            );
                            setFieldValue(
                              CampFields.END_DATE,
                              parseFullDate(date)
                            );
                          }}
                          placeholderText="DD/MM/YYYY"
                          isClearable={true}
                          dateFormat="dd MMM yyyy"
                          minDate={today}
                          disabled={isPastCamp(scheduleCamp)}
                        />
                        {touched[CampFields.START_DATE] &&
                          errors[CampFields.START_DATE] && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors[CampFields.START_DATE]}
                            </div>
                          )}
                      </div>
                      <div className=" col-span-4 ">
                        <label className="text-sm font-bold block">
                          Start Time
                        </label>
                        <SingleTimePicker
                          name={[CampFields.START_TIME]}
                          views={["hours", "minutes"]}
                          setFieldValue={setFieldValue}
                          values={values}
                          disabled={isPastCamp(scheduleCamp)}
                        />
                        {touched[CampFields.START_TIME] &&
                          errors[CampFields.START_TIME] && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors[CampFields.START_TIME]}
                            </div>
                          )}
                      </div>
                      <div className=" col-span-4 ">
                        <label className="text-sm font-bold block">
                          End Time
                        </label>
                        <SingleTimePicker
                          name={[CampFields.END_TIME]}
                          views={["hours", "minutes"]}
                          setFieldValue={setFieldValue}
                          values={values}
                          disabled={isPastCamp(scheduleCamp)}
                        />

                        {touched[CampFields.END_TIME] &&
                          errors[CampFields.END_TIME] && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors[CampFields.END_TIME]}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="w-full min-h-[160px]">
                      <label className="text-sm font-bold">Notes</label>

                      <TextAreaWithEditorComponent
                        name={CampFields.NOTES}
                        values={values}
                        setFieldValue={setFieldValue}
                        disabled={isPastCamp(scheduleCamp)}
                      />

                      {touched[CampFields.NOTES] &&
                        errors[CampFields.NOTES] && (
                          <div className="mt-1 text-xs text-red-500">
                            {errors[CampFields.NOTES]}
                          </div>
                        )}
                    </div>
                  </div>

                  {/* buttons */}
                  <div className="w-full flex justify-end space-x-4 h-[48px]">
                    <button
                      className="bg-black text-white px-4 text-lg py-2 font-bold rounded-lg min-w-[32%] h-full"
                      onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                      }}
                    >
                      Reset
                    </button>
                    <CustomButton
                      name="Submit"
                      type="submit"
                      loading={false}
                      disabled={isPastCamp(scheduleCamp)}
                      className={"min-w-[32%]"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export { EditScheduleCampModel };
