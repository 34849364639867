import React from "react";

export const EmptyCircle = ({ width, height, strokeColor }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2084_455)">
        <circle
          cx="16.5"
          cy="16.0475"
          r="15.5"
          fill="white"
          stroke={strokeColor}
        />
      </g>
    </svg>
  );
};
