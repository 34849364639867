/**
 * Assign Beneficary for camp Screen Page
 * @flow
 * @format
 */

import React, { useEffect, useState } from "react";
import { Cross } from "../../../assets/icons/Cross";
import { Clock } from "../../../assets/icons/Clock";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Location } from "../../../assets/icons/Location";
import { ArrowIcon } from "../../../assets/icons/Arrow";
import DataTable from "react-data-table-component";
import { Edit } from "../../../assets/icons/Edit";
import { Delete } from "../../../assets/icons/Delete";
import { FieldArray, Formik } from "formik";
import { CampFields } from "../../../constants";
import {
  getDistrictsWithBeneficiariesCount,
  getDistrictsWithBeneficiariesCountForDistribution,
  getStatesWithBeneficiariesCount,
  getStatesWithBeneficiariesCountForDistribution,
  selectDistrictWithBeneficiaryCounts,
  selectStateWithBeneficiaryCounts,
} from "../../common";
import { useDispatch, useSelector } from "react-redux";
import DatePickerComponent from "../../../components/datePicker";
import SingleDatePickerComponent from "../../../components/SingleDatePicker";
import MultipleTagsInput from "../../../components/multipleTagInput";
import { formatDate, parseFullDate } from "../../../utils/utils";
import Multiselect from "multiselect-react-dropdown";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { ListCampBeneficiary } from "./campBeneficiaryListScreen";
import {
  assignBeneficiariestoCamp,
  deleteBeneficiaryQueryById,
  getAllBeneficiaryQueryDataByScheduleCampId,
  getAllCampsForSelectBox,
  getBeneficiariesQueryDataOfCamp,
  getBeneficiaryCountAndIdAssignedWithoutQuery,
  getBeneficiaryQueryDataById,
  setbeneficiaryQuerySearch,
  updateBeneficiaryQueryDataById,
} from "../slice";
import {
  selectAllCampListForSelectBox,
  selectBeneficiariesQueryList,
  selectBeneficiariesWithoutAssignFormQueryList,
  selectScheduleCampCurrentPage,
  selectScheduleCampFilter,
  selectScheduleCampRespPerPage,
} from "../selector";
import { toast } from "react-toastify";
import SelectDropDown from "../../../components/selectDropDown";
import DeleteConfirm from "../../../components/deleteConfirm";
import { Loader } from "../../../components";
import CustomButton from "../../../components/customButtion";

const AssignBeneficiariesForCampModel = ({ toggleModal, rowData, getData }) => {
  const dispatch = useDispatch();
  const { confirm, ModalContent } = DeleteConfirm();
  const campData = rowData;
  const [isRotated, setIsRotated] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState("");
  const [selectedCampId, setSelectedCampId] = useState("");
  const [queryText, setQueryText] = useState("");
  const [beneficiariesCountList, setBeneficiariesCountList] = useState([]);
  const [queryId, setQueryId] = useState("");
  const [
    isListCampBeneficiariesModalOpen,
    setIsListCampBeneficiariesModalOpen,
  ] = useState(false);

  const initialValues = {
    query: [
      {
        [CampFields.FIELD_2]: "",
        [CampFields.OPERATION]: "",
      },
    ],
  };

  const [initialObject, setInitialObject] = useState(initialValues);

  const columnStyle = {
    fontSize: "14px",
    fontWeight: "normal",
  };

  const columns = [
    {
      name: "Selection Criteria",
      selector: (row) => row?.queryText,
      style: { ...columnStyle, paddingLeft: "10px" },
      minWidth: "70%",
    },
    {
      name: "Beneficiaries",
      selector: (row) => row?.beneficiary_count,
      style: columnStyle,
      maxWidth: "200px",
      cell: (row) => (
        <>
          <div className="flex flex-row items-center justify-center space-x-8">
            <div className="pl-1">{row?.beneficiary_count}</div>
            <div>
              {/* {row.id !== "total" ? ( */}
              <button
                className="text-white bg-[#4AB8FF] text-xs px-[4px] py-[2px] rounded-[5px] cursor-pointer"
                type="button"
                onClick={() => {
                  toggleListCampBeneficiariesModal(row);
                }}
              >
                View list
              </button>
              {/* ) : null} */}
            </div>
          </div>
        </>
      ),
    },
    {
      name: "",
      selector: (row) => row?._id,
      style: columnStyle,
      maxWidth: "100px",
      cell: (row) => (
        <>
          {row?.id !== "total" && row?.id !== "noQuery" ? (
            <div className="flex flex-row items-center justify-center cursor-pointer space-x-2">
              <div
                className=""
                onClick={() => {
                  getBeneficiayQueryDataById(row?._id);
                }}
              >
                <Edit width={12} height={12} />
              </div>
              <div
                onClick={async () => {
                  const isConfirmed = await confirm({
                    title: "Delete",
                    message: `${row?.beneficiary_count} Beneficiries will be removed from this Camp. Are you sure?`,
                    confirmText: "Yes, I'm sure",
                    cancelText: "No, cancel",
                    icon: <Delete height={20} width={20} />,
                  });
                  if (isConfirmed) {
                    deleteBeneficiaryQuery(row?._id);
                  }
                }}
              >
                <Delete width={12} height={12} />
              </div>
            </div>
          ) : null}
        </>
      ),
    },
  ];

  // Apis Data
  const scheduleCampCurrentPage = useSelector(selectScheduleCampCurrentPage);
  const scheduleCampPerPage = useSelector(selectScheduleCampRespPerPage);
  const scheduleCampFilter = useSelector(selectScheduleCampFilter);
  let { data: queryList = [] } =
    useSelector(selectBeneficiariesQueryList) || {};
  const { data: beneficiariesCountWithoutQuery = {} } =
    useSelector(selectBeneficiariesWithoutAssignFormQueryList) || {};

  const { data: state = [] } =
    useSelector(selectStateWithBeneficiaryCounts) || {};
  const stateOptions = state.map((item) => ({
    group: "India",
    label: `${item?.stateName} ${" "} (${item?.beneficiaryCount})`,
    value: item?.stateId,
  }));

  const { data: district = [] } =
    useSelector(selectDistrictWithBeneficiaryCounts) || {};
  const districtOptions = district?.map((item) => ({
    group: item?.stateName,
    label: `${item?.districtName} (${item?.beneficiaryCount})`,
    value: item?.districtId,
  }));

  const { data: campsOptionData = [] } =
    useSelector(selectAllCampListForSelectBox) || {};

  const campsOptions = campsOptionData?.map((item) => ({
    label: (
      <div className="w-full flex flex-col justify-center items-start space-y-1">
        <div className="!font-medium text-sm text-black">
          Camp Code: <span className="font-bold">{item?.camp_code}</span>
        </div>
        <div className="font-bold text-sm">
          {item?.camp_type_id?.camp_type_display_name}
        </div>
        <div className="font-medium text-sm">
          Camp Centre:{item?.camp_location_id?.camp_location_code}-{" "}
          {item?.camp_location_id?.camp_location_name}
        </div>
      </div>
    ),
    value: item?._id,
    campCode: item?.camp_code,
  }));

  const assessmentStatusOptions = [
    {
      label: "Belonging to",
      value: "belonging_to",
    },
    {
      label: "Registered",
      value: "registered",
    },
    {
      label: "Verified",
      value: "verified",
    },
  ];
  const distributionStatusOptions = [
    {
      label: "Belonging to",
      value: "belonging_to",
    },
    {
      label: "Registered",
      value: "registered",
    },
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Of Assessment Camp",
      value: "of_assessment_Camp",
    },
  ];

  const operationOptions = [
    { label: "DONE", value: "DONE" },
    { label: "AND", value: "AND" },
    { label: "OR", value: "OR" },
  ];

  const middleSelectOptionsForAccessment = [
    { label: "State", value: "state" },
    { label: "District", value: "district" },
    { label: "Pincode", value: "pinCode" },
  ];
  const middleSelectOptionsForDistributionCamp = [
    { label: "Held between", value: "held_between" },
    { label: "Held on", value: "held_on" },
    { label: "Held at", value: "held_at" },
    { label: "Camp Code - Name", value: "camp_code_name" },
  ];
  const middleSelectOptionsForRV = [
    { label: "Between", value: "between" },
    { label: "Before", value: "before" },
    { label: "After", value: "after" },
  ];

  //   Functions
  const getState = () => {
    dispatch(
      getStatesWithBeneficiariesCount({
        onSuccess: (props) => {},
      })
    );
  };

  const getDistrict = () => {
    dispatch(
      getDistrictsWithBeneficiariesCount({
        onSuccess: (props) => {},
      })
    );
  };

  const getStateForDistribution = () => {
    dispatch(getStatesWithBeneficiariesCountForDistribution());
  };

  const getDistrictForDistribution = () => {
    dispatch(getDistrictsWithBeneficiariesCountForDistribution());
  };

  const getallCamps = () => {
    dispatch(
      getAllCampsForSelectBox({
        search: "",
      })
    );
  };

  const getallBeneficiayQuery = (id) => {
    setDataTableLoading(true);
    dispatch(
      getAllBeneficiaryQueryDataByScheduleCampId({
        scheduled_camp_id: id,
        onSuccess: (props) => {
          setDataTableLoading(false);
        },
        onFailure: (props) => {
          setDataTableLoading(false);
        },
      })
    );
    dispatch(
      getBeneficiaryCountAndIdAssignedWithoutQuery({
        scheduled_camp_id: id,
        onSuccess: (props) => {
          setDataTableLoading(false);
        },
        onFailure: (props) => {
          setDataTableLoading(false);
        },
      })
    );
  };

  const getBeneficiayQueryDataById = (id) => {
    dispatch(
      getBeneficiaryQueryDataById({
        id,
        onSuccess: (props) => {
          const { data } = props;
          setInitialObject({ query: data?.condition });
          setQueryId(id);
        },
      })
    );
  };

  const deleteBeneficiaryQuery = (id) => {
    dispatch(
      deleteBeneficiaryQueryById({
        id,
        onSuccess: (props) => {
          getallBeneficiayQuery(campData?._id);
          // toast.success(props?.message);
        },
      })
    );
  };

  const processedData = Array.isArray(beneficiariesCountList)
    ? beneficiariesCountList.reduce(
        (acc, item) => {
          // Check if totalBeneficiary is a valid number
          acc.totalBeneficiary += item.beneficiary_count;

          // Ensure beneficiary_id is an array before concatenation
          const beneficiaryIds = Array.isArray(item.beneficiary_id)
            ? item.beneficiary_id
            : [];

          acc.beneficiary_id = acc.beneficiary_id.concat(beneficiaryIds);
          return acc;
        },
        { totalBeneficiary: 0, beneficiary_id: [] }
      )
    : { totalBeneficiary: 0, beneficiary_id: [] };

  // Add a "Total" row at the end of the data array
  const dataWithTotal = [
    ...beneficiariesCountList,
    {
      id: "total",
      queryText: "Total:",
      beneficiary_count: processedData?.totalBeneficiary || 0,
      beneficiary_id: processedData?.beneficiary_id,
    },
  ];

  const allOptions = {
    assessmentStatus: assessmentStatusOptions,
    distributionStatus: distributionStatusOptions,
    state: stateOptions,
    district: districtOptions,
    RelatedRVOptions: middleSelectOptionsForRV,
    RelatedBelongOptions: middleSelectOptionsForAccessment,
    RelatedOptionsForDistributionCamp: middleSelectOptionsForDistributionCamp,
  };

  const getOptions = (name) => {
    if (name === "belonging_to") return allOptions.RelatedBelongOptions || [];
    if (name === "registered" || name === "verified")
      return allOptions.RelatedRVOptions || [];
    if (name === "district") return allOptions.district || [];
    if (name === "assessmentCamp") return allOptions.assessmentStatus || [];
    if (name === "distributionCamp") return allOptions.distributionStatus || [];
    if (name === "state") return allOptions.state || [];
    if (name === "of_assessment_Camp")
      return allOptions.RelatedOptionsForDistributionCamp || [];
    return [];
  };

  useEffect(() => {
    if (campData?.camp_type_id?.camp_type_name === "distribution") {
      getStateForDistribution();
      getDistrictForDistribution();
    } else if (campData?.camp_type_id?.camp_type_name === "assessment") {
      getState();
      getDistrict();
    }
    getallCamps();
    if (campData._id) {
      getallBeneficiayQuery(campData?._id);
    }
  }, [campData]);

  useEffect(() => {
    if (beneficiariesCountWithoutQuery && queryList) {
      // Ensure a new array is created with the added object
      const updatedQueryList = [...queryList, beneficiariesCountWithoutQuery];
      setBeneficiariesCountList(updatedQueryList);
    }
  }, [beneficiariesCountWithoutQuery, queryList]);

  const toggleListCampBeneficiariesModal = (row) => {
    dispatch(setbeneficiaryQuerySearch(""));
    setIsListCampBeneficiariesModalOpen(!isListCampBeneficiariesModalOpen);
    if (row?.id !== "total") {
      setSelectedRowId(row);
      setQueryText(row?.queryText);
      setSelectedCampId(campData?._id);
    } else if (row?.id === "total") {
      setSelectedRowId("");
      setQueryText("All Beneficiaries of Camp");
      setSelectedCampId(campData?._id);
    }
  };

  const areFieldsFilled = (values) => {
    let allFieldsFilled = true;
    let lastOperation = "";

    for (const obj of values) {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          if (!obj[key].length || obj[key].some((item) => item === "")) {
            allFieldsFilled = false;
          }
        } else if (obj[key] === "" || obj[key] == null) {
          allFieldsFilled = false;
        }
      }
      lastOperation = obj.operation;
    }

    // Return true if all fields are filled, and the last operation is not "DONE".
    return !(allFieldsFilled && lastOperation === "DONE");
  };

  return (
    <>
      <div className="fixed h-full inset-0 z-50 flex justify-end bg-[#07284b80]">
        <div
          data-aos-easing="linear"
          data-aos="fade-left"
          className="flex flex-col relative w-[80%] h-full bg-white shadow-lg overflow-y-auto scrollbar-hide"
        >
          <div className="flex justify-between items-center bg-customBlue-mainBackground w-full h-[11%] p-5">
            <h2 className="text-xl font-bold">Assign Beneficiaries</h2>
            <button onClick={toggleModal} className="text-xl font-semibold">
              <Cross width={32} height={32} />
            </button>
          </div>
          {/* Modal content */}
          <div className="w-full flex flex-col flex-auto space-y-4 py-6 px-6">
            <div className="text-black bg-[#F5F5F5]  border border-[#0E87D5] rounded-xl flex flex-col gap-2">
              <div className="bg-[#CCE6FE] text-base px-4 py-3 rounded-t-xl flex justify-between items-center">
                <div className="font-bold mb-1">
                  {" "}
                  {campData?.camp_type_id?.camp_type_display_name} Camp{" "}
                </div>
                <div className="font-extrabold ">
                  Total Beneficiaries Count :{" "}
                  {processedData?.totalBeneficiary || 0}/{campData?.max_numbers}
                </div>
              </div>
              <div className="px-4 py-3 ">
                <div className="flex justify-between items-center mb-3 flex-wrap">
                  <div className="font-bold mb-1">
                    Camp Name - {campData?.camp_location_id?.camp_location_name}
                  </div>
                  <div className="font-bold ">
                    Camp Code - {campData?.camp_code}
                  </div>
                  <div className="font-semibold  flex items-center gap-1.5">
                    <Location width={14} height={16} fillColor={"#7B7B7B"} />
                    <div className=" text-[#626262]">
                      {campData?.camp_location_id?.address_1}{" "}
                      {campData?.camp_location_id?.address_2}, Dist-{" "}
                      {campData?.camp_location_id?.district?.district_name}
                    </div>
                  </div>
                </div>
                <div className="font-semibold  flex items-center gap-[2rem]">
                  <div className="font-semibold  flex items-center gap-1.5">
                    <CalendarNew />
                    <div className=" ">{formatDate(campData?.from_date)}</div>
                  </div>
                  <div className="font-semibold  flex items-center gap-1.5">
                    <Clock width={16} height={16} />
                    <div className="">
                      {campData?.from_time}-{campData?.to_time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Formik
              initialValues={initialObject}
              // validationSchema={}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                if (values.submitType === "apply") {
                  // Handle Apply button logic
                  dispatch(
                    getBeneficiariesQueryDataOfCamp({
                      scheduled_camp_id: campData?._id,
                      conditions: values.query,
                      onSuccess: (props) => {
                        getallBeneficiayQuery(campData?._id);
                        actions.resetForm();
                      },
                    })
                  );
                } else if (values.submitType === "update") {
                  // Handle Update button logic
                  dispatch(
                    updateBeneficiaryQueryDataById({
                      id: queryId,
                      conditions: values.query,
                      onSuccess: (props) => {
                        getallBeneficiayQuery(campData?._id);
                        getBeneficiayQueryDataById(props?.data?._id);
                        // actions.resetForm();
                      },
                    })
                  );
                } else if (values.submitType === "create") {
                  // Handle Create button logic
                  dispatch(
                    assignBeneficiariestoCamp({
                      scheduled_camp_id: campData?._id,
                      beneficiary_id: processedData?.beneficiary_id,
                      onSuccess: (props) => {
                        toast.success(props?.message);
                        getData(
                          "",
                          scheduleCampFilter,
                          scheduleCampCurrentPage,
                          scheduleCampPerPage
                        );
                        toggleModal();
                      },
                    })
                  );
                }
              }}
            >
              {({
                values,
                resetForm,
                handleChange,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                setFieldValue,
              }) => (
                <>
                  <form
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                  >
                    <div className="h-full">
                      <div className="space-y-6">
                        {/* Specify Selection Criteria */}
                        <div className="px-4 py-3 text-black bg-[#EFF9FF]  border border-[#C0E6FF] rounded-xl flex flex-col gap-2">
                          <div className="text-base  rounded-t-xl flex justify-between items-center">
                            <div className="font-bold mb-1">
                              {" "}
                              Specify Selection Criteria{" "}
                            </div>
                            <div className="p-0 min-w-[36px] w-[36px] h-[36px] min-h-[36px] flex items-center justify-center">
                              <div
                                className={`w-full h-full bg-customBlue-list rounded-full cursor-pointer flex items-center justify-center ${
                                  isRotated ? "rotate-180" : "rotate-90"
                                }`}
                                onClick={() => setIsRotated(!isRotated)}
                              >
                                <ArrowIcon
                                  width={8}
                                  height={12}
                                  isRotated={isRotated}
                                  fillColor={"#ffffff"}
                                />
                              </div>
                            </div>
                          </div>
                          {isRotated && (
                            <>
                              <FieldArray
                                name="query"
                                render={(arrayHelpers) => (
                                  <>
                                    {values.query?.map((row, index) => (
                                      <div
                                        className="flex flex-col gap-2"
                                        key={row.id}
                                      >
                                        <div className="flex gap-[2.5rem] items-center mt-3 ">
                                          <div className="font-bold mb-1 w-[100px] col-span-2">
                                            Beneficiaries
                                          </div>
                                          <div className="gap-[1.5rem] items-center grid grid-cols-12 w-full">
                                            <div className="col-span-4">
                                              <SelectDropDown
                                                options={getOptions(
                                                  campData?.camp_type_id
                                                    ?.camp_type_display_name ===
                                                    "Assessment"
                                                    ? "assessmentCamp"
                                                    : campData?.camp_type_id
                                                        ?.camp_type_display_name ===
                                                      "Distribution"
                                                    ? "distributionCamp"
                                                    : []
                                                )}
                                                name={`query[${index}].status`}
                                                placeholder="select"
                                                onChange={(selectedOption) => {
                                                  setFieldValue(
                                                    `query[${index}].status`,
                                                    selectedOption?.value || ""
                                                  );
                                                }}
                                                value={
                                                  getOptions(
                                                    campData?.camp_type_id
                                                      ?.camp_type_display_name ===
                                                      "Assessment"
                                                      ? "assessmentCamp"
                                                      : campData?.camp_type_id
                                                          ?.camp_type_display_name ===
                                                        "Distribution"
                                                      ? "distributionCamp"
                                                      : []
                                                  )?.find(
                                                    (item) =>
                                                      item.value ===
                                                      values.query[index]
                                                        ?.status
                                                  ) || null
                                                }
                                                touched={touched}
                                                errors={errors}
                                                isSearchable={true}
                                                isClearable={false}
                                              />
                                            </div>

                                            <div className="col-span-4">
                                              <SelectDropDown
                                                options={getOptions(
                                                  values.query[index]?.status
                                                )}
                                                name={`query[${index}].field_2`}
                                                placeholder="select"
                                                hideSelectedOptions={true}
                                                onChange={(selectedOption) => {
                                                  // Delete the relevant keys from the object
                                                  delete values.query[index]
                                                    ?.stateName;
                                                  delete values.query[index]
                                                    ?.state;
                                                  delete values.query[index]
                                                    ?.districtName;
                                                  delete values.query[index]
                                                    ?.district;
                                                  delete values.query[index]
                                                    ?.date;
                                                  delete values.query[index]
                                                    ?.startDate;
                                                  delete values.query[index]
                                                    ?.endDate;
                                                  delete values.query[index]
                                                    ?.pinCode;
                                                  delete values.query[index]
                                                    ?.camp_code_name;
                                                  delete values.query[index]
                                                    ?.field_2;
                                                  setFieldValue(
                                                    `query[${index}].field_2`,
                                                    selectedOption?.value || ""
                                                  );
                                                }}
                                                value={
                                                  getOptions(
                                                    values.query[index]?.status
                                                  ).find(
                                                    (item) =>
                                                      item.value ===
                                                      values.query[index]
                                                        ?.field_2
                                                  ) || null
                                                }
                                                touched={touched}
                                                errors={errors}
                                                isSearchable={true}
                                                isClearable={false}
                                              />
                                            </div>
                                            {values.query[index]?.field_2 ===
                                            "state" ? (
                                              <div className="col-span-4">
                                                <Multiselect
                                                  groupBy="group"
                                                  options={getOptions("state")}
                                                  displayValue="label"
                                                  onSelect={(selectedList) => {
                                                    setFieldValue(
                                                      `query[${index}].state`,
                                                      selectedList.map(
                                                        (item) => item.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      `query[${index}].stateName`,
                                                      selectedList.map(
                                                        (item) =>
                                                          item.label.split(
                                                            " ("
                                                          )[0]
                                                      )
                                                    );
                                                  }}
                                                  onRemove={(selectedList) => {
                                                    setFieldValue(
                                                      `query[${index}].state`,
                                                      selectedList.map(
                                                        (item) => item.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      `query[${index}].stateName`,
                                                      selectedList.map(
                                                        (item) =>
                                                          item.label.split(
                                                            " ("
                                                          )[0]
                                                      ) || ""
                                                    );
                                                  }}
                                                  selectedValues={getOptions(
                                                    "state"
                                                  ).filter(
                                                    (option) =>
                                                      Array.isArray(
                                                        row.state
                                                      ) &&
                                                      row.state.includes(
                                                        option.value
                                                      )
                                                  )}
                                                  showCheckbox
                                                  style={{
                                                    multiselectContainer: {
                                                      padding: "0px",
                                                      border:
                                                        "2px solid #B0E0FF",
                                                      borderRadius: "12px",
                                                    },
                                                    searchBox: {
                                                      border: "none",
                                                      overflowY: "scroll",
                                                      scrollbarWidth: "none",
                                                      msOverflowStyle: "none",
                                                    },
                                                    chips: {
                                                      backgroundColor:
                                                        "#B0E0FF",
                                                      color: "black",
                                                    },
                                                  }}
                                                />
                                              </div>
                                            ) : values.query[index]?.field_2 ===
                                              "district" ? (
                                              <div className="col-span-4">
                                                <Multiselect
                                                  groupBy="group"
                                                  options={getOptions(
                                                    "district"
                                                  )}
                                                  displayValue="label"
                                                  onSelect={(selectedList) => {
                                                    setFieldValue(
                                                      `query[${index}].district`,
                                                      selectedList.map(
                                                        (item) => item.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      `query[${index}].districtName`,
                                                      selectedList.map(
                                                        (item) =>
                                                          item.label.split(
                                                            " ("
                                                          )[0]
                                                      )
                                                    );
                                                  }}
                                                  onRemove={(selectedList) => {
                                                    setFieldValue(
                                                      `query[${index}].district`,
                                                      selectedList.map(
                                                        (item) => item.value
                                                      )
                                                    );
                                                    setFieldValue(
                                                      `query[${index}].districtName`,
                                                      selectedList.map(
                                                        (item) =>
                                                          item.label.split(
                                                            " ("
                                                          )[0]
                                                      ) || ""
                                                    );
                                                  }}
                                                  selectedValues={getOptions(
                                                    "district"
                                                  ).filter((option) => {
                                                    Array.isArray(
                                                      row.district
                                                    ) &&
                                                      row.district.includes(
                                                        option.value
                                                      );
                                                  })}
                                                  showCheckbox
                                                  style={{
                                                    multiselectContainer: {
                                                      padding: "0px",
                                                      border:
                                                        "2px solid #B0E0FF",
                                                      borderRadius: "12px",
                                                    },
                                                    searchBox: {
                                                      border: "none",
                                                      overflowY: "scroll",
                                                      scrollbarWidth: "none",
                                                      msOverflowStyle: "none",
                                                    },
                                                    chips: {
                                                      backgroundColor:
                                                        "#B0E0FF",
                                                      color: "black",
                                                    },
                                                  }}
                                                />
                                              </div>
                                            ) : values.query[index]?.field_2 ===
                                              "pinCode" ? (
                                              <div className="col-span-4">
                                                <MultipleTagsInput
                                                  name={`query[${index}].pinCode`}
                                                  placeHolder="Enter Here"
                                                  value={
                                                    values.query[index]
                                                      ?.pinCode || []
                                                  }
                                                  onChange={(pin) =>
                                                    setFieldValue(
                                                      `query[${index}].pinCode`,
                                                      pin
                                                    )
                                                  }
                                                />
                                              </div>
                                            ) : values.query[index]?.field_2 ===
                                              "camp_code_name" ? (
                                              <div className="col-span-4">
                                                <SelectDropDown
                                                  options={campsOptions}
                                                  name={`query[${index}].camp_code_name`}
                                                  placeholder="select"
                                                  hideSelectedOptions={true}
                                                  onChange={(
                                                    selectedOption
                                                  ) => {
                                                    setFieldValue(
                                                      `query[${index}].campCode`,
                                                      selectedOption?.campCode ||
                                                        ""
                                                    );
                                                    setFieldValue(
                                                      `query[${index}].camp_code_name`,
                                                      selectedOption?.value ||
                                                        ""
                                                    );
                                                  }}
                                                  value={
                                                    campsOptions.find(
                                                      (item) =>
                                                        item.value ===
                                                        values.query[index]
                                                          ?.camp_code_name
                                                    ) || null
                                                  }
                                                  touched={touched}
                                                  errors={errors}
                                                  isSearchable={true}
                                                  isClearable={false}
                                                />
                                              </div>
                                            ) : [
                                                "held_between",
                                                "between",
                                              ].includes(
                                                values.query[index]?.field_2
                                              ) ? (
                                              <div className="col-span-4">
                                                <DatePickerComponent
                                                  startDate={
                                                    values.query[index]
                                                      ?.startDate
                                                  }
                                                  endDate={
                                                    values.query[index]?.endDate
                                                  }
                                                  setStartDate={(date) => {
                                                    setFieldValue(
                                                      `query[${index}].startDate`,
                                                      date
                                                    );
                                                  }}
                                                  setEndDate={(date) => {
                                                    setFieldValue(
                                                      `query[${index}].endDate`,
                                                      date
                                                    );
                                                  }}
                                                  placeholderText="DD/MM/YYYY-DD/MM/YYYY"
                                                  isClearable={true}
                                                  dateFormat="dd MMM yy"
                                                />
                                              </div>
                                            ) : [
                                                "after",
                                                "before",
                                                "held_at",
                                                "held_on",
                                              ].includes(
                                                values.query[index]?.field_2
                                              ) ? (
                                              <div className="col-span-4">
                                                <SingleDatePickerComponent
                                                  name={`query[${index}].date`}
                                                  value={
                                                    values.query[index]?.date
                                                  }
                                                  onChange={(date) => {
                                                    setFieldValue(
                                                      `query[${index}].date`,
                                                      parseFullDate(date)
                                                    );
                                                  }}
                                                  placeholderText="DD/MM/YYYY"
                                                  isClearable={true}
                                                  dateFormat="dd MMM yyyy"
                                                />
                                              </div>
                                            ) : (
                                              <>
                                                <div className="col-span-4">
                                                  {" "}
                                                  <SelectDropDown
                                                    options={[]}
                                                    placeholder="Select"
                                                    touched={touched}
                                                    errors={errors}
                                                    isSearchable={true}
                                                    isClearable={false}
                                                  />
                                                </div>
                                              </>
                                            )}
                                          </div>
                                          {values.query.length > 0 && (
                                            <div
                                              className="flex justify-start items-center cursor-pointer h-[44px]"
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <Delete width={24} height={24} />
                                            </div>
                                          )}
                                        </div>

                                        <div className="mt-5">
                                          <hr
                                            style={{ accentColor: "#DDE3EC" }}
                                          ></hr>
                                          <div className="-mt-[1.2rem] max-w-[100px] mx-auto">
                                            <SelectDropDown
                                              options={operationOptions}
                                              name={`query[${index}].operation`}
                                              placeholder="Select"
                                              onChange={(selectedOption) => {
                                                setFieldValue(
                                                  `query[${index}].operation`,
                                                  selectedOption?.value || ""
                                                );
                                              }}
                                              value={
                                                operationOptions.find(
                                                  (item) =>
                                                    item.value ===
                                                    values.query[index]
                                                      ?.operation
                                                ) || null
                                              }
                                              touched={touched}
                                              errors={errors}
                                              isSearchable={true}
                                              isClearable={false}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                    {/* Add more button */}
                                    <button
                                      className="text-[#33AFFF] px-4 py-2 rounded-xl bg-[#F5FBFF] w-[calc(100%-0px)] h-full font-medium border-[2px] border-[#B0E0FF]"
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          operation: "",
                                        })
                                      }
                                      disabled={
                                        values.query[values.query.length - 1]
                                          ?.operation === "DONE"
                                          ? true
                                          : false
                                      }
                                    >
                                      <div className="flex items-center gap-1 justify-center">
                                        Add more
                                        <AddIcon
                                          width={12}
                                          height={12}
                                          iconColor={"#33AFFF"}
                                        />
                                      </div>
                                    </button>
                                  </>
                                )}
                              />

                              {queryId ? (
                                <div className="w-full flex justify-end space-x-4 mt-4">
                                  <CustomButton
                                    name="Cancel"
                                    type="button"
                                    loading={false}
                                    disabled={false}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setInitialObject(initialValues);
                                      setQueryId("");
                                      resetForm();
                                    }}
                                    className="bg-[#C1C1C1] text-white min-w-[150px] h-full"
                                  />
                                  <CustomButton
                                    name="Update"
                                    type="submit"
                                    loading={false}
                                    onClick={() =>
                                      setFieldValue("submitType", "update")
                                    }
                                    disabled={areFieldsFilled(values.query)}
                                    className={`${
                                      areFieldsFilled(values.query)
                                        ? "bg-[#C1C1C1]"
                                        : "bg-WildWatermelon-button"
                                    } min-w-[150px] h-full font-bold`}
                                  />
                                </div>
                              ) : (
                                <div className="w-full flex justify-end space-x-4 mt-4">
                                  <CustomButton
                                    name="Reset"
                                    type="button"
                                    loading={false}
                                    disabled={false}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setInitialObject(initialValues);
                                      resetForm();
                                    }}
                                    className="bg-[#C1C1C1] text-white min-w-[150px] h-full"
                                  />
                                  <CustomButton
                                    name="Apply"
                                    type="submit"
                                    loading={false}
                                    onClick={() =>
                                      setFieldValue("submitType", "apply")
                                    }
                                    disabled={areFieldsFilled(values.query)}
                                    className={`${
                                      areFieldsFilled(values.query)
                                        ? "bg-[#C1C1C1]"
                                        : "bg-WildWatermelon-button"
                                    } min-w-[150px] h-full font-bold`}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        {/* Selection Criteria */}
                        <div className="w-full">
                          <div className=" overflow-y-auto scrollbar-hide theme-table">
                            <DataTable
                              columns={columns}
                              data={dataWithTotal}
                              progressPending={dataTableLoading}
                              progressComponent={
                                <div className="h-[400px] w-full flex justify-center items-center bg-customBlue-mainBackground">
                                  <Loader />
                                </div>
                              }
                              customStyles={{
                                headCells: {
                                  style: {
                                    backgroundColor: "#DDF1FE",
                                    color: "Black",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                  },
                                },
                                cells: {
                                  style: (row) => ({
                                    justifyContent:
                                      row?.id === "cell-1-total"
                                        ? "flex-end"
                                        : "flex-start",
                                    fontWeight:
                                      row?.id === "cell-1-total"
                                        ? "bold"
                                        : "normal",
                                  }),
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex justify-end space-x-4 mt-4">
                        <CustomButton
                          name="Cancel"
                          type="submit"
                          loading={false}
                          disabled={false}
                          onClick={(e) => {
                            e.preventDefault();
                            toggleModal();
                          }}
                          className={"min-w-[150px] bg-[#000000]"}
                        />
                        <CustomButton
                          name="Create"
                          type="submit"
                          loading={false}
                          disabled={false}
                          onClick={() => setFieldValue("submitType", "create")}
                          className={"min-w-[150px]"}
                        />
                      </div>
                    </div>
                  </form>
                </>
              )}
            </Formik>
          </div>
        </div>

        {/* Modals */}
        {isListCampBeneficiariesModalOpen && (
          <ListCampBeneficiary
            toggleModal={toggleListCampBeneficiariesModal}
            selectedCampQueryId={selectedRowId}
            selectedCampId={selectedCampId}
            getData={getallBeneficiayQuery}
            queryText={queryText}
            scheduleCampId={campData?._id}
            beneficiaryIds={selectedRowId?.beneficiary_id}
            name={"assignBeneficiary"}
            showDeleteBeneficiaryButton={true}
          />
        )}
        {ModalContent}
      </div>
    </>
  );
};

export { AssignBeneficiariesForCampModel };
