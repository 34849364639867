/**
 * Utils
 * @format
 */

import moment from "moment";
import TextArea from "../components/textArea";
import Input from "../components/inputBox";
import MultiSelectDropDown from "../components/multiSelectDropDown";
import SelectDropDown from "../components/selectDropDown";
import CheckBox from "../components/checkBox";
import CustomFormUploadInput from "../components/formUploadInput";

export function manipulateDateAndTime(date) {
  const new_date = new Date(date);

  // Format the date as "DD-MM-YYYY"
  const formattedDate = new_date
    .toLocaleDateString("en-GB")
    .replace(/\//g, "-");

  // Get the time in "H.MM AM/PM" format
  const formattedTime = new_date
    .toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
    .replace(":", ".");

  return {
    date: formattedDate,
    time: formattedTime,
  };
}

export function todayDate() {
  const today = new Date();
  return today;
}

export function getInitialsName(name) {
  // Split the name by space
  const nameParts = name.split(" ");

  // Map through the name parts and get the first letter of each part
  const initials = nameParts
    .map((part) => part.charAt(0).toUpperCase())
    .join("");

  return initials;
}

export function convertTo12HourFormat(time) {
  if (!time) return "00:00";
  const [hour, minute] = time.split(":");
  const period = hour >= 12 ? "PM" : "AM";
  const hour12 = hour % 12 || 12;
  return `${String(hour12).padStart(2, "0")}:${minute} ${period}`;
}

export function convertTo24HourFormat(time12Hour) {
  if (!time12Hour) return "00:00";
  const [time, period] = time12Hour.split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  let hours24 = hours;
  if (period === "PM" && hours !== 12) {
    hours24 += 12;
  } else if (period === "AM" && hours === 12) {
    hours24 = 0;
  }

  const formattedHours = String(hours24).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export function formatCampDate(fromDate, toDate) {
  const date1 = moment(fromDate);
  const date2 = moment(toDate);

  return `${date1.format("MMMM D")} - ${date2.format("D")}, ${date2.format(
    "YYYY"
  )}`;
}

export function formatDate(date) {
  return moment(date).format("DD MMM YYYY");
}

export function formatDateYearMonthDay(date) {
  return moment(date).format("YYYY-MM-DD");
}

// parse Full Date into year month day format
export function parseFullDate(date) {
  if (!date) return null;

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export function formatQuery(query) {
  return query
    ?.map((condition, index) => {
      let formattedCondition = "";
      let Status = condition?.status;

      if (Status === "belonging_to") {
        Status = "belonging to";
      } else if (Status === "of_assessment_Camp") {
        Status = "of assessment Camp";
      }

      switch (condition.field_2) {
        case "state":
          formattedCondition = ` ${Status} State of ${condition?.stateName.join(
            ", "
          )}`;
          break;
        case "district":
          formattedCondition = ` ${Status} District of ${condition?.districtName.join(
            ", "
          )}`;
          break;
        case "pinCode":
          formattedCondition = ` ${Status} Pin Codes ${condition.pinCode.join(
            ", "
          )}`;
          break;
        case "between":
          formattedCondition = `${capitalize(Status)} between ${
            condition?.startDate
          } to ${condition?.endDate}`;
          break;
        case "before":
          formattedCondition = `${capitalize(Status)} before ${
            condition?.date
          }`;
          break;
        case "after":
          formattedCondition = `${capitalize(Status)} after ${condition?.date}`;
          break;

        case "camp_code_name":
          formattedCondition = ` ${Status} with camp code ${condition.campCode}`;
          break;
        case "held_between":
          formattedCondition = `${capitalize(Status)} held between ${
            condition?.startDate
          } to ${condition?.endDate}`;
          break;
        case "held_on":
          formattedCondition = `${capitalize(Status)} held on ${
            condition?.date
          }`;
          break;
        case "held_at":
          formattedCondition = `${capitalize(Status)} held at ${
            condition?.date
          }`;
          break;
      }

      // Add operation, except for the last condition
      if (index < query.length - 1) {
        formattedCondition += ` ${condition.operation} `;
      }

      return formattedCondition;
    })
    .join("");
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getFieldBox(fieldBox, props) {
  const {
    name,
    id,
    onChange,
    disabled,
    value,
    placeholder,
    className,
    isChecked,
    type,
    options,
    errors,
    touched,
    isClearable,
    isSearchable,
    setFieldValue,
    setSelected,
    selected,
    isVerified,
  } = props;
  switch (fieldBox) {
    case "textArea":
      return (
        <TextArea
          name={name}
          id={id}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          className={className}
        />
      );
    case "file":
      return <CustomFormUploadInput {...props} />;
    case "inputText":
      return (
        <Input
          name={name}
          id={id}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          value={value}
          className={className}
        />
      );
    case "multiSelect":
      return <MultiSelectDropDown {...props} />;
    case "singleSelect":
      return (
        <SelectDropDown
          name={name}
          options={options}
          value={value}
          touched={touched}
          errors={errors}
          placeholder={placeholder}
          isSearchable={isSearchable}
          isClearable={isClearable}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case "checkBox":
      return (
        <CheckBox
          name={name}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          isChecked={isChecked}
          disabled={disabled}
        />
      );
    case "radio":
      return (
        <div className="mt-2">
          <div className="flex space-x-3">
            <label
              className={`flex items-center space-x-2 cursor-pointer ${
                isVerified ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <input
                type="radio"
                name={name}
                value={true}
                checked={selected === true}
                onChange={() => {
                  if (!isVerified) {
                    setSelected(true);
                    setFieldValue(name, true);
                  }
                }}
                className={`w-4 h-4 ${
                  selected === true ? "accent-WildWatermelon-button" : ""
                }`}
                disabled={disabled}
              />
              <span className="font-bold text-sm">Yes</span>
            </label>

            <label
              className={`flex items-center space-x-2 cursor-pointer ${
                isVerified ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <input
                type="radio"
                name={name}
                value={false}
                checked={selected === false}
                onChange={() => {
                  if (!isVerified) {
                    setSelected(false);
                    setFieldValue(name, false);
                  }
                }}
                className={`w-4 h-4 ${
                  selected === false ? "accent-WildWatermelon-button" : ""
                }`}
                disabled={disabled}
              />
              <span className="font-bold text-sm">No</span>
            </label>
          </div>
        </div>
      );
    default:
      return "";
  }
}

export function isLoginButtonVisible(camp) {
  if (!camp?.scheduled_camp_id?.from_time) return false;

  // Get current date and time
  const campDate = new Date(camp?.scheduled_camp_id?.from_date);
  const [hour, minute] = camp?.scheduled_camp_id?.from_time
    .split(" ")[0]
    .split(":");
  const isPM = camp?.scheduled_camp_id?.from_time.includes("PM");

  // Combine camp date with from_time
  const campStartDate = new Date(campDate);
  campStartDate.setHours(isPM ? parseInt(hour, 10) + 12 : parseInt(hour, 10));
  campStartDate.setMinutes(parseInt(minute, 10));

  // Subtract 30 minutes to calculate login window start
  let currentTime = new Date();
  const loginWindowStart = new Date(campStartDate);
  loginWindowStart.setMinutes(campStartDate.getMinutes() - 30);

  // Logic for button visibility
  return currentTime >= loginWindowStart;
}

export function isPastCamp(camp) {
  if (!camp || !camp?.from_date || !camp?.from_time) {
    return false;
  }

  // Parse the `from_date`
  const fromDate = new Date(camp?.from_date);

  // Convert `from_time` from 12-hour format to 24-hour format
  const [time, meridian] = camp?.from_time.split(" ");
  const [hours, minutes] = time.split(":").map(Number);
  const adjustedHours =
    meridian === "PM" && hours !== 12
      ? hours + 12
      : hours === 12 && meridian === "AM"
      ? 0
      : hours;

  // Set the time to the `fromDate`
  fromDate.setHours(adjustedHours, minutes, 0, 0);

  // Get the current date and time
  const currentDateTime = new Date();

  // Check if the current date and time are less than the camp's date and time
  return currentDateTime > fromDate;
}
