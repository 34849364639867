import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Delete } from "../assets/icons/Delete";

const ConfirmModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resolve, setResolve] = useState(null);
  const [modalProps, setModalProps] = useState({
    title: "Confirm",
    message: "Are you sure?",
    confirmText: "Yes",
    cancelText: "No",
    icon: null,
  });

  // Function to open the modal and return a promise
  const confirm = ({
    title = "Confirm",
    message = "Are you sure?",
    confirmText = "Yes",
    cancelText = "No",
    icon = null,
  } = {}) => {
    setModalProps({ title, message, confirmText, cancelText, icon });
    setIsOpen(true);
    return new Promise((resolve) => {
      setResolve(() => resolve);
    });
  };

  // Function to handle confirmation result (true or false)
  const handleConfirm = (result) => {
    setIsOpen(false);
    resolve(result);
  };

  // Modal content
  const ModalContent = isOpen && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <div className="flex flex-row items-center justify-start w-full space-x-2 text-center">
          {modalProps.icon && <div>{modalProps.icon}</div>}
          <span className="text-lg font-semibold text-center">
            {modalProps.title}
          </span>
        </div>
        <div className="my-6">
          <Stack sx={{ width: "100%" }} spacing={1}>
            <Alert severity="error">{modalProps.message}</Alert>
          </Stack>
        </div>
        <div className="mt-8 flex justify-end space-x-2 font-semibold">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-600 rounded-lg hover:bg-gray-400"
            onClick={() => handleConfirm(false)}
          >
            {modalProps.cancelText}
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
            onClick={() => handleConfirm(true)}
          >
            {modalProps.confirmText}
          </button>
        </div>
      </div>
    </div>
  );

  return { confirm, ModalContent };
};

export default ConfirmModal;
