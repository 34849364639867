/**
 * All Socket name declear
 * @format
 */

export const SocketName = {
    // user Management
    BENEFICIARY_VERIFICATION_LIST_REFRESH: "BENEFICIARY_VERIFICATION_LIST_REFRESH",

  // Camp Management
  COUNTER_REFRESH: "COUNTER_REFRESH",
  CAMP_CLOSE: "CAMP_CLOSE",

  // fabrication Management
  FABRICATION_LIST_REFRESH: "FABRICATION_LIST_REFRESH",
  FABRICATION_COUNTER_REFRESH: "FABRICATION_COUNTER_REFRESH",
};
