/**
 * User Management sagas
 * @format
 */

import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import axios from "axios";
import { endPoints, httpRequest } from "../../services";
import {
  allUsersList,
  createUser,
  deleteUser,
  getAllStateWithUserCount,
  getUserById,
  getUserFromSSSApi,
  setAllStateWithUserCount,
  setSingleUserData,
  setUserDataList,
  updateProfile,
  updateUserById,
  updateUserStatusById,
} from "./slice";

/**
 * Get all Users list
 * @param {*} Search
 */
function* usersListSaga(action) {
  const { search, page, perPage, onSuccess, stateId, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(endPoints.allUsersList, {
      search,
      page,
      responsePerPage: perPage,
      state_id: stateId,
    });
    yield put(setUserDataList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get Users data sss api
 * @param {*} Search
 */
function* UserDataSSSApiSaga(action) {
  const { number, onSuccess, onFailure } = action.payload;
  try {
    const token =
      "xZsgnmC2ljZJQVSbQqpktpNUhuT8E2WOc9UhgCdpU6EmFaAZSEMqrT1Qhq0GRRuSAkl6SWpAjfxNRL26jYjsYw==";
    // Set up request configuration with Bearer token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Make the API call with the custom config
    const response = yield call(
      axios.post,
      `${"https://www.saiconnect.app/api/memberdetails"}/${number}`,
      null,
      config
    );
    // const response = yield call(
    //   axios.post,
    //   `${"https://staging.sssso.dev/api/memberdetails"}/${number}`,
    //   null,
    //   config
    // );
    yield onSuccess({ response, message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Create User with fields
 * @param {*} *fields
 */
function* createUserSaga(action) {
  try {
    const { values, onSuccess } = action.payload;
    const {
      access_level,
      designation_name,
      is_active,
      email,
      gender,
      member_id,
      mobile_number,
      name,
      pin_code,
      political_state_name,
      political_state_id,
      political_district_name,
      political_district_id,
      role,
      sss_state_name,
      sss_state_id,
      sss_district_name,
      sss_district_id,
      samithi_name,
      samithi_id,
      user_type_id,
    } = values;
    const response = yield httpRequest.post(endPoints.registerUser, {
      access_level,
      designation_name,
      is_active,
      email,
      gender,
      member_id,
      mobile_number,
      name,
      pin_code,
      political_state_name,
      political_state_id,
      political_district_name,
      political_district_id,
      role,
      sss_state_name,
      sss_state_id,
      sss_district_name,
      sss_district_id,
      samithi_name,
      samithi_id,
      user_type_id,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update Profile
 * @param {*} *fields
 */
function* updateProfileSaga(action) {
  try {
    const { search, onSuccess } = action.payload;
    const response = yield httpRequest.put(endPoints.updateUserProfile, {
      search,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update User By Id
 * @param {*} *fields
 */
function* updateUserByIdSaga(action) {
  try {
    const { updatedValues, onSuccess } = action.payload;
    const {
      id,
      access_level,
      designation_name,
      is_active,
      email,
      gender,
      member_id,
      mobile_number,
      name,
      pin_code,
      political_state_name,
      political_state_id,
      political_district_name,
      political_district_id,
      role,
      sss_state_name,
      sss_state_id,
      sss_district_name,
      sss_district_id,
      samithi_name,
      samithi_id,
      user_type_id,
    } = updatedValues;
    const response = yield httpRequest.put(endPoints.updateUserById, {
      id,
      access_level,
      designation_name,
      is_active,
      email,
      gender,
      member_id,
      mobile_number,
      name,
      pin_code,
      political_state_name,
      political_state_id,
      political_district_name,
      political_district_id,
      role,
      sss_state_name,
      sss_state_id,
      sss_district_name,
      sss_district_id,
      samithi_name,
      samithi_id,
      user_type_id,
    });

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Update User Status By Id
 * @param {*} *fields
 */
function* updateUserStatusByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield httpRequest.put(
      `${endPoints.updateUserStatus}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

// /**
//  * Get User Profile
//  *
//  */
// function* getUserProfileSaga(action) {
//   try {
//     const { onSuccess } = action.payload;
//     const response = yield httpRequest.get(endPoints.userProfile);

//     yield onSuccess({ message: response?.message });
//   } catch (error) {
//     toast.error(error?.response?.data?.message);
//   } finally {
//   }
// }

/**
 * Get User By Id
 * @param {*} Id
 */
function* getUserByIdSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.get,
      `${endPoints.getUserById}/${id}`
    );

    yield onSuccess({ message: response?.message });
    yield put(setSingleUserData(response?.data));
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Delete USer
 * @param {*} Id
 */
function* deleteUserSaga(action) {
  try {
    const { id, onSuccess } = action.payload;
    const response = yield call(
      httpRequest.delete,
      `${endPoints.deleteUser}/${id}`
    );

    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all State With User Count
 * @param {*}
 */
function* allStateWithUserCountListSaga(action) {
  try {
    const { onSuccess } = action.payload;
    const response = yield httpRequest.get(endPoints.stateWithUserCount);
    yield put(setAllStateWithUserCount(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* userManagementSaga() {
  yield takeLatest(getUserFromSSSApi, UserDataSSSApiSaga);
  yield takeLatest(allUsersList, usersListSaga);
  yield takeLatest(createUser, createUserSaga);
  yield takeLatest(updateProfile, updateProfileSaga);
  yield takeLatest(updateUserById, updateUserByIdSaga);
  yield takeLatest(updateUserStatusById, updateUserStatusByIdSaga);
  // yield takeLatest(getUserProfile, getUserProfileSaga);
  yield takeLatest(getUserById, getUserByIdSaga);
  yield takeLatest(deleteUser, deleteUserSaga);
  yield takeLatest(getAllStateWithUserCount, allStateWithUserCountListSaga);
}
