/**
 * Time Picker with period (AM/PM) Component
 * @format
 */

import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

export default function SingleTimePicker({
  name,
  views,
  setFieldValue,
  values,
  disabled,
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{
        fieldHoursPlaceholder: () => "--",
        fieldMinutesPlaceholder: () => "--",
        fieldMeridiemPlaceholder: () => "--",
      }}
    >
      <TimePicker
        name={name}
        views={views}
        value={values[name] ? dayjs(values[name], "HH:mm") : null}
        disabled={disabled || false}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            height: "44px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "2px solid var(--custom-blue-border)",
            borderRadius: "12px",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: "2px solid var(--custom-blue-border)",
          },
        }}
        onChange={(newValue) => {
          const formattedValue = newValue ? newValue.format("HH:mm") : "";
          setFieldValue(name, formattedValue);
        }}
      />
    </LocalizationProvider>
  );
}
