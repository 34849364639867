/**
 * Text Area With Editor Component
 * @format
 */

import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const formats = ["bold", "italic", "strike", "list", "bullet", "blockquote"];

const modules = {
  toolbar: [
    ["bold", "italic", "strike"],
    [{ list: "bullet" }, { list: "ordered" }],
    ["blockquote"],
  ],
};
const TextAreaWithEditorComponent = ({
  name,
  values,
  setFieldValue,
  disabled,
}) => {
  const handleChange = (value) => {
    setFieldValue(name, value);
  };

  return (
    <div className="editor-container ">
      <ReactQuill
        value={values[name]}
        onChange={handleChange}
        formats={formats}
        modules={modules}
        style={{ minHeight: "100px" }}
        readOnly={disabled || false}
      />
    </div>
  );
};

export default TextAreaWithEditorComponent;
