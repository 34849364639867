/**
 * Fabrication Management Slice
 * @format
 */

import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  allFabricationCampsDataList: [],
  fabricationCurrentPage: 1,
  fabricationDataPerPage: 10,
  fabricationSearchQuery: "",
  fabricationNavStatus: "completed",

  //   beneficiary list
  allFabricationCampsBeneficiaryDataList: {},
  fabricationBeneficiaryListCurrentPage: 1,
  fabricationBeneficiaryListDataPerPage: 10,
  fabricationBeneficiaryListSearchQuery: "",
  fabricationBeneficiaryListNavStatus: "fabrication_in_progress",
  fabricationBeneficiaryListDistrictId: "",
  fabricationBeneficiaryListStateId: "",
  fabricationBeneficiaryListFilter: {
    pinCode: [],
  },
};

const fabricationManagementSlice = createSlice({
  name: "fabricationManagement",
  initialState,
  reducers: {
    setAllFabricationCampsDataList(state, action) {
      state.allFabricationCampsDataList = action.payload;
    },
    setFabricationCurrentPage(state, action) {
      state.fabricationCurrentPage = action.payload;
    },
    setFabricationDataPerPage(state, action) {
      state.fabricationDataPerPage = action.payload;
    },
    setFabricationSearchQuery(state, action) {
      state.fabricationSearchQuery = action.payload;
    },
    setFabricationNavStatus(state, action) {
      state.fabricationNavStatus = action.payload;
    },

    // Beneficiary List
    setAllFabricationCampsBeneficiaryDataList(state, action) {
      state.allFabricationCampsBeneficiaryDataList = action.payload;
    },
    setFabricationBeneficiaryListCurrentPage(state, action) {
      state.fabricationBeneficiaryListCurrentPage = action.payload;
    },
    setFabricationBeneficiaryListDataPerPage(state, action) {
      state.fabricationBeneficiaryListDataPerPage = action.payload;
    },
    setFabricationBeneficiaryListSearchQuery(state, action) {
      state.fabricationBeneficiaryListSearchQuery = action.payload;
    },
    setFabricationBeneficiaryListNavStatus(state, action) {
      state.fabricationBeneficiaryListNavStatus = action.payload;
    },
    setFabricationBeneficiaryListDistrictId(state, action) {
      state.fabricationBeneficiaryListDistrictId = action.payload;
    },
    setFabricationBeneficiaryListStateId(state, action) {
      state.fabricationBeneficiaryListStateId = action.payload;
    },
    setFabricationBeneficiaryListFilter(state, action) {
      state.fabricationBeneficiaryListFilter = {
        ...state.fabricationBeneficiaryListFilter,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetFabricationState, () => {
      return initialState;
    });
  },
});

// Reducer
export const fabricationManagementReducer = fabricationManagementSlice.reducer;

// Actions
export const {
  setAllFabricationCampsDataList,
  setFabricationCurrentPage,
  setFabricationDataPerPage,
  setFabricationSearchQuery,
  setFabricationNavStatus,
  //Beneficiary List
  setAllFabricationCampsBeneficiaryDataList,
  setFabricationBeneficiaryListCurrentPage,
  setFabricationBeneficiaryListDataPerPage,
  setFabricationBeneficiaryListSearchQuery,
  setFabricationBeneficiaryListNavStatus,
} = fabricationManagementSlice.actions;

export const resetFabricationState = createAction("RESETFABRICATIONSTATE");
export const allFabricationCampsList = createAction("ALLFABRICATIONCAMPSLIST");
export const allBeneficiaryListOfFabricationCamp = createAction(
  "ALLBENEFICIARYLISTOFFABRICATIONCAMP"
);
