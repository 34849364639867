/**
 * Beneficiary List of fabrication camps Screen
 * @format
 */

import React, { useEffect, useRef, useState } from "react";
import { HeaderScreen, Loader, Sidebar } from "../../../components";
import NavigationBar from "../../beneficiary_management/components/navigationBar";
import { useDispatch, useSelector } from "react-redux";
import {
  selectallFabricationCampsBeneficiaryDataList,
  selectFabricationBeneficiaryListCampDistrictId,
  selectFabricationBeneficiaryListCampFilter,
  selectFabricationBeneficiaryListCampStateId,
  selectFabricationBeneficiaryListCurrentPage,
  selectFabricationBeneficiaryListDataPerPage,
  selectFabricationBeneficiaryListNavStatus,
  selectFabricationBeneficiaryListSearchQuery,
} from "../selector";
import { formatDate, manipulateDateAndTime } from "../../../utils";
import Pagination from "../../../components/pagination";
import BeneficiaryJourney from "../../beneficiary_management/components/beneficiaryJourney";
import PersonalDetails from "../../beneficiary_management/components/personalDetails";
import BeneficiaryDetail from "../../beneficiary_management/components/beneficiaryDetail";
import BeneficiaryList from "../../beneficiary_management/components/beneficiaryList";
import { FabricationComponent } from "../../beneficiary_management/components/fabricationCard";
import { AssessmentReadOnlyComponent } from "../../beneficiary_management/components/assessmentCard";
import BeneficiaryVerificationPart from "../../beneficiary_management/components/beneficiaryVerificationForm";
import {
  selectActiveUser,
  selectBeneficiaryDetails,
  selectBeneficiaryProcessDetail,
  selectFilter,
  selectStateId,
} from "../../beneficiary_management/selector";
import { selectScheduleCamp } from "../../camp_management/selector";
import {
  getBeneficiaryDetailById,
  getBeneficiaryProcessDetails,
  setActiveUser,
  setBeneficiaryDetails,
} from "../../beneficiary_management/slice";
import {
  getScheduleCampById,
  getScheduleCampStepById,
  getStepAndCounterOfUser,
} from "../../camp_management/slice";
import {
  getAllStages,
  getAllStatus,
  selectFullScreenLoading,
  selectStages,
  selectStatus,
  setFullScreenLoading,
} from "../../common";
import { useLocation, useParams } from "react-router-dom";
import {
  allBeneficiaryListOfFabricationCamp,
  resetFabricationState,
  setAllFabricationCampsBeneficiaryDataList,
  setFabricationBeneficiaryListSearchQuery,
} from "../slice";
import { CalendarNew } from "../../../assets/icons/Calender";
import { Clock } from "../../../assets/icons/Clock";
import { LocationMap } from "../../../assets/icons/LocationMap";
import { NoRecord } from "../../../components/noRecords";
import ViewMapLink from "../../../components/viewMapLink";
import { socket } from "../../../socket/socket";
import { SocketName } from "../../../socket/socket_constant";
import { RefreshIcon } from "../../../assets/icons/refresh";

function FabricationCampBeneficiayListScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const [beneficiaryDetailToggle, setBeneficiaryDetailToggle] = useState(false);
  const [singleDetailLoading, setSingleDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [dateTime, setDateTime] = useState({});
  const scrollableRef = useRef(null);

  // handel journey stpes
  const [expandedSteps, setExpandedSteps] = useState({
    registration: false,
    verification: false,
    assessment: false,
    fabrication: false,
    distribution: false,
  });
  const toggleStep = (step) => {
    setExpandedSteps({
      ...expandedSteps,
      [step]: !expandedSteps[step],
    });
  };

  const {
    data: beneficiaries = [],
    totalCounts,
    responseCount,
    fabrication_completed,
    fabrication_in_progress,
    fabrication_not_yet_started,
    not_eligible_for_fabrication,
    totalCampBeneficiary,
    totalPages,
    currentPage: currentPageApi,
  } = useSelector(selectallFabricationCampsBeneficiaryDataList) || {};
  const fullScreenLoading = useSelector(selectFullScreenLoading);
  const activeUserId = useSelector(selectActiveUser);
  const { beneficiaryDetails, campData, beneficiaryJourney } =
    useSelector(selectBeneficiaryProcessDetail) || {};
  const stageIds =
    useSelector(selectStages).reduce((acc, stage) => {
      acc[stage.beneficiary_stage_name] = stage._id;
      return acc;
    }, {}) || {};
  //   const userCountersData = useSelector(selectCampUserCounterData) || {};
  const singleBeneficiaryData = useSelector(selectBeneficiaryDetails) || {};
  const scheduleCampData = useSelector(selectScheduleCamp) || {};

  const singleBeneficiary = beneficiaryDetails || {};
  const search = useSelector(selectFabricationBeneficiaryListSearchQuery);
  const currentPage = useSelector(selectFabricationBeneficiaryListCurrentPage);
  const limit = useSelector(selectFabricationBeneficiaryListDataPerPage);
  const districtId = useSelector(
    selectFabricationBeneficiaryListCampDistrictId
  );
  // const stateId = useSelector(selectFabricationBeneficiaryListCampStateId);
  // const filter = useSelector(selectFabricationBeneficiaryListCampFilter);
  const stateId = useSelector(selectStateId);
  const filter = useSelector(selectFilter);
  const navStatus = useSelector(selectFabricationBeneficiaryListNavStatus);

  // format the verification data and status
  const verification_data =
    beneficiaryJourney?.stages?.verification?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.verification?.data[0] // Correct reference here
    );

  const latest_data = {
    status: verification_data?.status_id?.status_name,
    created_by_name: verification_data?.created_by?.name,
    member_id: verification_data?.created_by?.member_id,
    call_status: verification_data?.data[0]?.call_status,
    notes: verification_data?.data[0]?.notes,
    createdAt: manipulateDateAndTime(verification_data?.createdAt),
  };

  // assessment data status managae
  const assessment_last_Status =
    beneficiaryJourney?.stages?.assessments?.data?.reduce((latest, current) => {
      return new Date(latest.createdAt) > new Date(current.createdAt)
        ? latest
        : current;
    }, beneficiaryJourney?.stages?.assessments?.data[0]);

  // Distribution data status manage
  const distribution_last_Status =
    beneficiaryJourney?.stages?.distribution?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.distribution?.data[0] // Set the first item as the initial value
    ); // Access status_name after reduce

  // Fabrication data status manage
  const fabrication_data =
    beneficiaryJourney?.stages?.fabrication?.data?.reduce(
      (latest, current) => {
        return new Date(latest.createdAt) > new Date(current.createdAt)
          ? latest
          : current;
      },
      beneficiaryJourney?.stages?.fabrication?.data[0] // Correct reference here
    );

  const fabrication_Started_data =
    beneficiaryJourney?.stages?.fabrication?.data?.find(
      (item) => item.status_id.status_name === "fabrication_in_progress"
    );

  const activeCampFlow = allData?.camp_flow?.find(
    (item) => item.is_active === true
  );

  const allStatus =
    useSelector(selectStatus).reduce((acc, status) => {
      acc[status.status_name] = status._id;
      return acc;
    }, {}) || {};

  // Api calling functions
  const getBeneficiaryDataList = (
    searchData,
    stateId,
    districtId,
    status,
    page,
    perPage,
    filter
  ) => {
    dispatch(setBeneficiaryDetails({}));
    setLoading(true);
    dispatch(setAllFabricationCampsBeneficiaryDataList({}));
    dispatch(
      allBeneficiaryListOfFabricationCamp({
        scheduled_camp_id: id,
        search: searchData,
        stateId,
        districtId,
        status,
        page: page,
        perPage: perPage,
        filter,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(false);
          setLoading(false);
        },
        onFailure: (props) => {
          // setBeneficiaryDetailToggle(false);
          setLoading(false);
        },
      })
    );
  };

  const getBeneficiaryDetailByUserId = (userId) => {
    dispatch(setBeneficiaryDetails({}));
    setSingleDetailLoading(true);
    dispatch(setFullScreenLoading(true));
    dispatch(
      getBeneficiaryDetailById({
        id: userId,
        onSuccess: (props) => {
          setBeneficiaryDetailToggle(true);
          setSingleDetailLoading(false);
          dispatch(setFullScreenLoading(false));
        },
        onFailure: (props) => {
          // setBeneficiaryDetailToggle(true);
          setSingleDetailLoading(false);
          dispatch(setFullScreenLoading(false));
        },
      })
    );
  };

  const getScheduleCampCampFlowData = (id) => {
    dispatch(
      getScheduleCampStepById({
        id,
        onSuccess: (props) => {
          const { data } = props;
          setAllData(data);
        },
      })
    );
  };

  const getScheduleCampDetails = (id) => {
    dispatch(
      getScheduleCampById({
        id,
        onSuccess: (props) => {
          //   const { data } = props;
        },
      })
    );
  };

  const getBeneficiaryProcessDetailByBeneficiaryId = (id) => {
    dispatch(
      getBeneficiaryProcessDetails({
        id,
        onSuccess: (props) => {},
      })
    );
  };

  const getStatusData = () => {
    dispatch(
      getAllStatus({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  const getUserCounterData = (id) => {
    dispatch(
      getStepAndCounterOfUser({
        scheduled_camp_id: id,
        onSuccess: (props) => {},
      })
    );
  };

  const getStagesData = () => {
    dispatch(
      getAllStages({
        search: "",
        onSuccess: () => {},
      })
    );
  };

  //  journey Steps
  const steps = [
    {
      name: "Registration",
      campScheduled: "",
      campComplete: `Completed on ${dateTime.date}; ${dateTime.time}`,
      campNotSchedled: "",
      lastStatus: "complete",
      descriptions: `Completed on ${dateTime.date}; ${dateTime.time}`,
    },
    {
      name: "Verification",
      component: (
        <BeneficiaryVerificationPart
          userData={singleBeneficiaryData}
          userVerificationData={latest_data}
        />
      ),
      campScheduled: "",
      campNotSchedled: "",
      campComplete: `Completed on ${latest_data?.createdAt?.date}; ${
        latest_data?.createdAt?.time
      } By ${latest_data?.created_by_name} (Sai Connect ID ${
        latest_data?.member_id || ""
      })`,
      lastStatus:
        latest_data?.status === "verified"
          ? "complete"
          : latest_data?.status === "rejected"
          ? "in_progress"
          : "",
    },
    {
      name: "Assessment",
      component: (
        <AssessmentReadOnlyComponent
          campSteps={activeCampFlow?.camp_flow_steps}
          campData={campData?.assessment}
          beneficiary_id={singleBeneficiary?._id}
          componentName={"assessment"}
        />
      ),
      campScheduled:
        assessment_last_Status?.status_id?.status_name ===
        "assessment_in_progress"
          ? campData?.assessment?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.assessment[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.assessment[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } (${
                campData?.assessment?.[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp Code : ${
                campData?.assessment?.[0]?.scheduled_camp_id?.camp_code
              })`
            : ""
          : "",
      ReSchedledButton: false,
      schedledButton: false,
      campComplete:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? `Completed on ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(assessment_last_Status?.createdAt)?.time
            } at ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } (${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp Code : ${
              campData?.assessment?.[0]?.scheduled_camp_id?.camp_code
            })`
          : "",
      campNotSchedled: !campData?.assessment ? `Not yet scheduled` : "",
      disabled:
        allData?.scheduled_camp_id?.camp_type_id?.camp_type_name ===
        "assessment"
          ? false
          : true,
      lastStatus:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? "complete"
          : assessment_last_Status?.status_id?.status_name ===
            "assessment_in_progress"
          ? "in_progress"
          : "",
    },
    {
      name: "Fabrication",
      component: (
        <FabricationComponent
          beneficiary_id={singleBeneficiary?._id}
          status={allStatus}
          stageIds={stageIds}
          fabrication_data={fabrication_data || {}}
          getDataCallback={getBeneficiaryProcessDetailByBeneficiaryId}
          getBeneficaryListCallback={getBeneficiaryDataList}
        />
      ),
      campScheduled:
        fabrication_data?.status_id?.status_name === "fabrication_in_progress"
          ? `Started on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time}`
          : "",
      campComplete:
        fabrication_data?.status_id?.status_name === "fabrication_completed" ? (
          <div>
            {`Started on ${
              manipulateDateAndTime(fabrication_Started_data?.createdAt)?.date
            }; ${
              manipulateDateAndTime(fabrication_Started_data?.createdAt)?.time
            } By ${fabrication_data?.created_by?.name}`}
            <br />
            {`Completed on ${
              manipulateDateAndTime(fabrication_data?.createdAt)?.date
            }; ${manipulateDateAndTime(fabrication_data?.createdAt)?.time} By ${
              fabrication_data?.created_by?.name
            }`}
          </div>
        ) : (
          ""
        ),
      campNotSchedled: fabrication_data ? "" : "Not yet scheduled",
      disabled:
        assessment_last_Status?.status_id?.status_name ===
        "assessement_completed"
          ? false
          : true,
      lastStatus:
        fabrication_data?.status_id?.status_name === "fabrication_completed"
          ? "complete"
          : fabrication_data?.status_id?.status_name ===
            "fabrication_in_progress"
          ? "in_progress"
          : "",
    },
    {
      name: "Distribution",
      component: (
        <AssessmentReadOnlyComponent
          campSteps={
            campData?.distribution?.[0]?.step?.find(
              (item) => item.is_active === true
            )?.camp_flow_steps || []
          }
          campData={campData?.distribution}
          beneficiary_id={singleBeneficiary?._id}
          componentName={"distribution"}
        />
      ),
      campScheduled:
        distribution_last_Status?.status_id?.status_name ===
          "distribution_in_progress" ||
        distribution_last_Status?.status_id?.status_name ===
          "distribution_scheduled"
          ? campData?.distribution?.length > 0
            ? `Scheduled on ${formatDate(
                campData?.distribution[0]?.scheduled_camp_id?.from_date
              )} at ${
                campData?.distribution[0]?.scheduled_camp_id?.camp_location_id
                  ?.camp_location_name
              } (${
                campData?.distribution?.[0]?.scheduled_camp_id?.camp_type_id
                  ?.camp_type_display_name
              } Camp Code : ${
                campData?.distribution?.[0]?.scheduled_camp_id?.camp_code
              })`
            : ""
          : "",
      campComplete:
        distribution_last_Status?.status_id?.status_name ===
        "distribution_completed"
          ? `Completed on ${
              manipulateDateAndTime(distribution_last_Status?.createdAt)?.date
            }; ${
              manipulateDateAndTime(distribution_last_Status?.createdAt)?.time
            } at ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.address_1
            }  ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.state?.state_name
            } - ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_location_id
                ?.pin_code
            } (${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_type_id
                ?.camp_type_display_name
            } Camp Code : ${
              campData?.distribution?.[0]?.scheduled_camp_id?.camp_code
            })`
          : "",
      campNotSchedled: !campData?.distribution ? `Not yet scheduled` : "",
      ReSchedledButton: false,
      schedledButton: false,
      disabled: campData?.distribution ? false : true,
      lastStatus:
        distribution_last_Status?.status_id?.status_name ===
        "distribution_completed"
          ? "complete"
          : distribution_last_Status?.status_id?.status_name ===
            "distribution_in_progress"
          ? "in_progress"
          : "",
    },
  ];

  useEffect(() => {
    if (
      scrollableRef.current &&
      typeof scrollableRef.current.scrollTo === "function"
    ) {
      scrollableRef.current.scrollTo(0, 0);
    }
    if (singleBeneficiaryData?._id) {
      const result = manipulateDateAndTime(singleBeneficiaryData?.createdAt);
      setDateTime(result);
      getBeneficiaryProcessDetailByBeneficiaryId(singleBeneficiaryData?._id);
      getStatusData();
      getStagesData();
    }
  }, [singleBeneficiaryData]);

  useEffect(() => {
    if (id) {
      getScheduleCampCampFlowData(id);
      getUserCounterData(id);
      getScheduleCampDetails(id);
    }
    getBeneficiaryDataList(
      search,
      stateId,
      districtId,
      navStatus,
      currentPage,
      limit,
      filter
    );
  }, [search, currentPage, stateId, districtId, limit, filter]);

  useEffect(() => {
    socket.on(SocketName.FABRICATION_COUNTER_REFRESH, (data) => {
      dispatch(
        getStepAndCounterOfUser({
          scheduled_camp_id: id,
          onSuccess: (props) => {},
        })
      );
      dispatch(
        allBeneficiaryListOfFabricationCamp({
          scheduled_camp_id: id,
          search,
          stateId,
          districtId,
          status: navStatus,
          page: currentPage,
          perPage: limit,
          filter,
          onSuccess: (props) => {
            if (navStatus == "assessement_completed") {
              if (props?.data?.fabrication_not_yet_started === 0) {
                setBeneficiaryDetailToggle(false);
                dispatch(setBeneficiaryDetails({}));
              }
            } else if (navStatus == "fabrication_in_progress") {
              if (props?.data?.fabrication_in_progress === 0) {
                setBeneficiaryDetailToggle(false);
                dispatch(setBeneficiaryDetails({}));
              }
            }
          },
          onFailure: (props) => {},
        })
      );
      dispatch(
        getScheduleCampById({
          id,
          onSuccess: (props) => {},
        })
      );
      if (activeUserId) {
        dispatch(
          getBeneficiaryDetailById({
            id: activeUserId,
            onSuccess: (props) => {},
            onFailure: (props) => {},
          })
        );
        dispatch(
          getBeneficiaryProcessDetails({
            id: activeUserId,
            onSuccess: (props) => {},
          })
        );
      }
    });
  }, [id, activeUserId]);

  // useEffect(() => {
  //   if (location.pathname.startsWith("/beneficiaryListFabrication")) {
  //     return () => {
  //       // dispatch(resetFabricationState());
  //       dispatch(setActiveUser(null));
  //     };
  //   } else {
  //     return;
  //   }
  // }, [location]);

  return (
    <>
      <div className="h-full w-screen">
        <HeaderScreen />
        <div className="flex h-[calc(100%-80px)] w-[100%]">
          <Sidebar />
          <div className="container p-0 flex flex-col justify-between h-full bg-customBlue-mainBackground max-w-[calc(100%-87px)] w-[100%] overflow-auto">
            {/* Camp Details  */}
            <div className="w-full p-4 pb-0">
              <div className="flex gap-3 justify-start items-center flex-wrap p-4 bg-[#E5F4FE] rounded-xl border-[#54BBFF] border">
                <div className="flex gap-3 flex-col w-[40%]">
                  <div className="font-bold text-xl title">
                    Camp Code:{" "}
                    <span className="text-[#FF5976]">
                      {scheduleCampData?.camp_code}
                    </span>
                  </div>
                  <div className="font-bold text-xl title">
                    Camp Name:{" "}
                    <span className="font-medium text-sm">
                      {scheduleCampData?.camp_location_id?.camp_location_name}
                    </span>
                  </div>
                </div>
                <div className="flex gap-3 flex-col">
                  <div className="font-bold text-xl title flex gap-2">
                    Camp Location:
                    <span class="font-medium flex gap-2 items-center text-sm">
                      <LocationMap
                        width={16}
                        height={18}
                        fillColor={"#555555"}
                      />
                      {`${scheduleCampData?.camp_location_id?.address_1}, Dist - ${scheduleCampData?.camp_location_id?.district?.district_name}`}

                      <div>
                        <ViewMapLink
                          geo_coordinates={
                            scheduleCampData?.camp_location_id?.geo_coordinates
                          }
                        />
                      </div>
                    </span>
                  </div>
                  <div className="font-bold text-xl title flex lg:flex-wrap gap-2 items-center">
                    <span>Held on:</span>

                    <div class="flex lg:flex-wrap justify-between gap-2 items-center text-sm">
                      <span class="font-semibold flex gap-2 items-center">
                        <CalendarNew />
                        <span>{formatDate(scheduleCampData?.from_date)}</span>
                      </span>
                      <span class="font-semibold flex gap-2 items-center">
                        <Clock width={16} height={16} />
                        <span>
                          {scheduleCampData?.from_time}-
                          {scheduleCampData?.to_time}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Beneficiary Data  */}
            <div className="container  w-full max-w-full px-4 py-2 mt-0 flex flex-row justify-between items-center ">
              <div className="flex flex-row items-center justify-between font-bold text-2xl title max-w-[42%] min-w-[42%]">
                <div className="">Beneficiaries Screened</div>
              </div>
            </div>
            <div className=" p-0 flex flex-row w-full items-center justify-between">
              <div className="container p-0 flex w-full px-4  max-w-[42%] min-w-[42%]  ">
                <NavigationBar
                  statuses={[
                    `In Progress(${fabrication_in_progress || 0})`,
                    `Not yet started(${fabrication_not_yet_started || 0})`,
                    `Not Eligible(${not_eligible_for_fabrication || 0})`,
                    `Completed(${fabrication_completed || 0})`,
                  ]}
                  getData={getBeneficiaryDataList}
                  beneficiaryDetailToggle={setBeneficiaryDetailToggle}
                  barName="fabricationCampBeneficiaryListScreen"
                />
                {/* <div className="col-span-6"></div> */}
              </div>
              <div className="h-full pr-8">
                {" "}
                <button
                  className="inline-flex flex-row px-2 justify-around text-[18px] rounded-lg items-center gap-2 bg-[#4AB8FF] text-white font-semibold"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <span className="flex-shrink-0">
                    <RefreshIcon width={18} height={18} />
                  </span>
                  <span className="font-semibold truncate">Refresh</span>
                </button>
              </div>
            </div>

            <div className="container  min-h-[50%] flex-auto  flex flex-row justify-between gap-6 w-full p-4  pb-0 max-w-full">
              {/* User List with Search  and Filter */}
              <BeneficiaryList
                users={beneficiaries}
                setSearchQuery={(value) => {
                  dispatch(setFabricationBeneficiaryListSearchQuery(value));
                }}
                getBeneficiaryDetailByUserId={getBeneficiaryDetailByUserId}
                beneficiaryStatus={false}
                completionTime={true}
                dateRange={false}
                currentStages={true}
                loading={loading}
                checkBox={false}
              />
              {/* User Details*/}
              {beneficiaryDetailToggle &&
              Object.keys(singleBeneficiaryData).length > 0 ? (
                singleBeneficiaryData?.status === "Verified" ? (
                  singleDetailLoading && !singleBeneficiaryData ? (
                    <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                      <Loader />
                    </div>
                  ) : (
                    <div className="container p-0 h-full self-end w-[58%] pr-4">
                      <div
                        className="container h-full overflow-y-scroll scrollbar-hide space-y-6 flex flex-col bg-white px-6 py-4 max-w-full w-full rounded-xl"
                        ref={scrollableRef}
                      >
                        {/* Beneficiary Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full border-b-[2px] border-[#c1c1c1] border-dashed ">
                          <BeneficiaryDetail userData={singleBeneficiary} />
                        </div>

                        {/* Personal Detail */}
                        <div className="container p-0 flex flex-col justify-between max-w-full w-full  ">
                          <PersonalDetails userData={singleBeneficiary} />
                        </div>
                        {/* Beneficiary Journey */}
                        <div className="container p-0 flex flex-col justify-between items-center max-w-full w-full  ">
                          <BeneficiaryJourney
                            beneficiary={singleBeneficiary}
                            steps={steps}
                            beneficiaryJourney={beneficiaryJourney}
                            campData={campData}
                            allData={allData}
                            setExpandedSteps={setExpandedSteps}
                            expandedSteps={expandedSteps}
                            toggleStep={toggleStep}
                          />
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="container h-full self-end pr-4 w-[58%]">
                    This Beneficiary is not Verifyed
                  </div>
                )
              ) : singleDetailLoading ? (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <Loader />
                </div>
              ) : (
                <div className="flex justify-center items-center container pr-4 pt-2 h-full self-end w-[58%] bg-white rounded-lg">
                  <NoRecord
                    title_1={"Select a Beneficiary to view"}
                    title_2={"Click on the Beneficiaries to view details"}
                  />
                </div>
              )}
            </div>

            <div className=" w-full flex justify-start items-center">
              <Pagination
                name={"fabricationCampBeneficiaryListScreen"}
                totalNumber={totalCounts}
                dataPerPage={limit}
                data={getBeneficiaryDataList}
                currentPageApi={currentPage}
                perPageOptions={[2, 5, 10, 30, 50, 100]}
                showFirstButton={true}
                showLastButton={true}
              />
            </div>
          </div>
        </div>

        {fullScreenLoading && (
          <div className="fixed h-full inset-0 z-50 flex justify-end">
            <div className="flex items-center justify-center fixed inset-0 bg-[#fafbfc] opacity-80">
              <Loader />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export { FabricationCampBeneficiayListScreen };
