/**
 * Camp OverView Saga
 * @format
 */
import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import { endPoints, httpRequest } from "../../services";
import {
  allBeneficiaryDetailOfCampByCampId,
  allCurrentCamps,
  countStepDataOfCurrentCamp,
  endStartCamp,
  getBeneficiaryOfCounterOfStepOfCurrentCamp,
  getCounterAndBeneficiaryOfStepOfCurrentCamp,
  setAllBeneficiaryListOfCamp,
  setAllBeneficiaryListOfCounterOfStep,
  setAllBeneficiaryListOfStepOfCamp,
  setAllCountStepDataList,
  setAllCurrentCampsList,
} from "./slice";

/**
 * Get all Current Camps
 * @param {*}
 */
function* allCurrentCampDataListSaga(action) {
  const { search, page, perPage, filter, onSuccess, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(endPoints.allCurrentCampsOverview, {
      search,
      filter,
      page,
      responsePerPage: perPage,
    });
    yield put(setAllCurrentCampsList(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Steps of current camp By Camp Id
 * @param {*}
 */
function* countStepDataOfCurrentCampSaga(action) {
  try {
    const { id, search, page, perPage, onSuccess } = action.payload;
    const response = yield httpRequest.post(endPoints.allStepsCampsOverview, {
      id,
      // search,
      // page,
      // responsePerPage: perPage,
    });
    yield put(setAllCountStepDataList(response?.data?.stepCounts));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

/**
 * Get all Beneficay details of current camp
 * @param {*}
 */
function* allBeneficiaryDetailOfCampSaga(action) {
  const { id, search, page, perPage, onSuccess, onFailure } = action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.allBeneficaryDetailCount,
      {
        id,
        search,
        page,
        responsePerPage: perPage,
      }
    );
    yield put(setAllBeneficiaryListOfCamp(response?.data[0]));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Beneficay and Counter of Step of current Camp
 * @param {*}
 */
function* getCounterAndBeneficiaryOfStepOfCurrentCampSaga(action) {
  const { scheduledCampId, search, campStepId, onSuccess, onFailure } =
    action.payload;
  try {
    const response = yield httpRequest.post(
      endPoints.counterAndBeneficiayOfStep,
      {
        scheduledCampId,
        campStepId,
        search,
      }
    );
    yield put(setAllBeneficiaryListOfStepOfCamp(response?.data?.stepDetails));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Get all Beneficay OF Counter of Step of current Camp
 * @param {*}
 */
function* getBeneficiaryOfCounterOfStepOfCurrentCampSaga(action) {
  const { counterId, search, onSuccess, onFailure } = action.payload;
  try {
    const response = yield httpRequest.post(endPoints.beneficiayOfCounter, {
      counterId,
      search,
    });
    yield put(setAllBeneficiaryListOfCounterOfStep(response?.data));
    yield onSuccess({ message: response?.message });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "An error occurred.";
    toast.error(errorMessage);
    yield onFailure({ message: errorMessage });
  } finally {
  }
}

/**
 * Close the Start CampSaga
 * @param {*}
 */
function* endStartCampSaga(action) {
  try {
    const { campId, onSuccess } = action.payload;

    const response = yield call(
      httpRequest.get,
      `${endPoints.endCamp}/${campId}`
    );
    yield onSuccess({ message: response?.message });
  } catch (error) {
    toast.error(error?.response?.data?.message);
  } finally {
  }
}

export function* campOverviewManagementSaga() {
  yield takeLatest(allCurrentCamps, allCurrentCampDataListSaga);
  yield takeLatest(countStepDataOfCurrentCamp, countStepDataOfCurrentCampSaga);
  yield takeLatest(
    allBeneficiaryDetailOfCampByCampId,
    allBeneficiaryDetailOfCampSaga
  );
  yield takeLatest(
    getCounterAndBeneficiaryOfStepOfCurrentCamp,
    getCounterAndBeneficiaryOfStepOfCurrentCampSaga
  );
  yield takeLatest(
    getBeneficiaryOfCounterOfStepOfCurrentCamp,
    getBeneficiaryOfCounterOfStepOfCurrentCampSaga
  );
  yield takeLatest(endStartCamp, endStartCampSaga);
}
